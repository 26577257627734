import { useMemo } from "react";
import { components } from "@/openapi-bindings/v2";
import { useGetPeopleQuery } from "@/hooks/useGetPeopleQuery";
import { useGetTagsQuery } from "@/hooks/useGetTagsQuery";

interface UseGetEntitiesResult {
  getPersonsByIds: (personIds: string[]) => components["schemas"]["Person"][];
  getTagsByIds: (tagIds: string[]) => components["schemas"]["Tag"][];
  isLoading: boolean;
  error: unknown;
}

export const useGetEntities = (): UseGetEntitiesResult => {
  const {
    data: storedPeople,
    isLoading: isPeopleLoading,
    error: peopleError,
  } = useGetPeopleQuery();

  const {
    data: storedTags,
    isLoading: isTagsLoading,
    error: tagsError,
  } = useGetTagsQuery();

  const getPersonsByIds = useMemo(() => {
    return (personIds: string[]) => {
      if (!storedPeople || !personIds.length) return [];

      return personIds
        .map((id) => storedPeople.find((person) => person.id === id))
        .filter(
          (person): person is components["schemas"]["Person"] =>
            person !== undefined && person !== null,
        );
    };
  }, [storedPeople]);

  const getTagsByIds = useMemo(() => {
    return (tagIds: string[]) => {
      if (!storedTags || !tagIds.length) return [];

      return tagIds
        .map((id) => storedTags.find((tag) => tag.id === id))
        .filter(
          (tag): tag is components["schemas"]["Tag"] =>
            tag !== undefined && tag !== null,
        );
    };
  }, [storedTags]);

  return {
    getPersonsByIds,
    getTagsByIds,
    isLoading: isPeopleLoading || isTagsLoading,
    error: peopleError || tagsError,
  };
};
