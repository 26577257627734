import { createContext, useContext } from "react";

export type MediaItemId = string;

export const MediaItemContext = createContext<MediaItemId | null>(null);

export const useMediaItemId = () => {
    const mediaItemId = useContext(MediaItemContext);

    if (!mediaItemId) {
        throw new Error("useMediaItemId needs to be wrapped in a MediaItemContext Provider");
    }

    return mediaItemId;
}
