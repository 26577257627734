import {
  Button,
  cn,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Switch,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@kino/ui";
import { ViewTypeToggles } from "./ViewTypeToggles";
import { GroupBySelect as GroupBySelectGeneral } from "./GroupBySelect";
import { GroupBySelect as GroupBySelectSearch } from "@/components/omniSearch/display/GroupBySelect";
import { MixerHorizontalIcon } from "@radix-ui/react-icons";
import IconWithIndicator from "@/components/shared/IconWithIndicator";
import {
  useDisplayStore,
  getEffectiveMode,
} from "@/store/display/displayStore";
import { useSearchDisplayStore } from "@/store/search/searchDisplayStore";
import { DisplayVariant } from "@/types/display";
import { match } from "ts-pattern";
import useGetSearchMode from "@/hooks/useGetSearchMode";

interface SettingProps extends React.HTMLAttributes<HTMLDivElement> {
  label?: string;
}

const Setting = ({ className, children, label, ...props }: SettingProps) => {
  return (
    <div
      className={cn("flex items-center justify-between gap-1", className)}
      {...props}
    >
      {label && <span className="w-24 text-xs font-normal">{label}</span>}
      {children}
    </div>
  );
};

interface SettingSectionProps extends React.HTMLAttributes<HTMLDivElement> {}

const SettingSection = ({
  children,
  className,
  ...props
}: SettingSectionProps) => {
  return (
    <div className={cn("flex flex-col gap-3", className)} {...props}>
      {children}
    </div>
  );
};

const MediaSettings = () => {
  const { setMode, modeOverride } = useDisplayStore();
  const effectiveMode = useDisplayStore(getEffectiveMode);

  const formatMode = (mode: string): string => {
    if (mode === "s48") return "S48";
    return mode.charAt(0).toUpperCase() + mode.slice(1);
  };

  return (
    <SettingSection>
      <Setting label="Display mode">
        <div className="flex flex-col gap-1">
          <Select value={effectiveMode} onValueChange={setMode}>
            <SelectTrigger
              className={cn(
                "h-7 w-[130px]",
                modeOverride && "text-neutral-400 line-through",
              )}
            >
              <SelectValue placeholder="Select mode" />
            </SelectTrigger>
            <SelectContent>
              {["default", "news", "unscripted", "scripted", "s48"].map(
                (mode) => (
                  <SelectItem key={mode} value={mode}>
                    {formatMode(mode)}
                  </SelectItem>
                ),
              )}
            </SelectContent>
          </Select>
          {modeOverride && (
            <div className="text-[10px] text-yellow-500">
              Mode overridden in debugger: {formatMode(modeOverride)}
            </div>
          )}
        </div>
      </Setting>
    </SettingSection>
  );
};

const SearchSettings = () => {
  const {
    propertyVisibility,
    togglePropertyVisibility,
    advancedSettings,
    toggleLoop,
  } = useSearchDisplayStore();
  const { setMode, modeOverride } = useDisplayStore();
  const effectiveMode = useDisplayStore(getEffectiveMode);
  const searchMode = useGetSearchMode();

  return (
    <>
      <SettingSection>
        <Setting label="View">
          <ViewTypeToggles />
        </Setting>
      </SettingSection>
      {searchMode !== "omni" && (
        <SettingSection>
          <Setting label="Group by">
            <GroupBySelectSearch />
          </Setting>
        </SettingSection>
      )}
      <SettingSection>
        {Object.keys(propertyVisibility).map((label) => (
          <Setting key={label} label={"Show " + label}>
            <Switch
              checked={propertyVisibility[label]}
              onCheckedChange={() => togglePropertyVisibility(label)}
            />
          </Setting>
        ))}
      </SettingSection>
      <SettingSection>
        <Setting label="Loop results">
          <Switch
            checked={advancedSettings.loop}
            onCheckedChange={toggleLoop}
          />
        </Setting>
      </SettingSection>
      <SettingSection>
        <Setting label="Display mode">
          <div className="flex flex-col gap-1">
            <Select value={effectiveMode} onValueChange={setMode}>
              <SelectTrigger
                className={cn(
                  "h-7 w-[130px]",
                  modeOverride && "text-neutral-400 line-through",
                )}
              >
                <SelectValue placeholder="Select mode" />
              </SelectTrigger>
              <SelectContent>
                {["default", "news", "unscripted", "scripted", "s48"].map(
                  (mode) => (
                    <SelectItem key={mode} value={mode}>
                      {mode === "s48"
                        ? "S48"
                        : mode.charAt(0).toUpperCase() + mode.slice(1)}
                    </SelectItem>
                  ),
                )}
              </SelectContent>
            </Select>
            {modeOverride && (
              <div className="text-[10px] text-yellow-500">
                Mode overridden in debugger:{" "}
                {modeOverride === "s48"
                  ? "S48"
                  : modeOverride.charAt(0).toUpperCase() +
                    modeOverride.slice(1)}
              </div>
            )}
          </div>
        </Setting>
      </SettingSection>
    </>
  );
};

const PlaylistSettings = () => {
  const { propertyVisibility, togglePropertyVisibility } = useDisplayStore();

  return (
    <>
      <SettingSection>
        <Setting label="View">
          <ViewTypeToggles />
        </Setting>
      </SettingSection>
      <SettingSection>
        <Setting label="Group by">
          <GroupBySelectGeneral />
        </Setting>
      </SettingSection>
      <SettingSection>
        {Object.keys(propertyVisibility).map((label) => (
          <Setting key={label} label={"Show " + label}>
            <Switch
              checked={propertyVisibility[label]}
              onCheckedChange={() => togglePropertyVisibility(label)}
            />
          </Setting>
        ))}
      </SettingSection>
    </>
  );
};

interface DisplayPopoverProps {
  variant: DisplayVariant;
}

export const DisplayPopover = ({ variant }: DisplayPopoverProps) => {
  return (
    <Popover modal={true}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          size="xsm"
          className="flex h-7 min-w-[80px] items-center gap-1 border-neutral-800 text-xs font-normal text-neutral-300 hover:text-neutral-300"
        >
          <IconWithIndicator icon={MixerHorizontalIcon} className="h-4 w-4" />
          Display
        </Button>
      </PopoverTrigger>
      <PopoverContent className="flex w-72 flex-col divide-y p-2 text-xs *:py-2 first:*:pt-0 last:*:pb-0">
        {match(variant)
          .with("media", () => <MediaSettings />)
          .with("search", () => <SearchSettings />)
          .with("playlist", () => <PlaylistSettings />)
          .with("overview", () => <MediaSettings />)
          .exhaustive()}
      </PopoverContent>
    </Popover>
  );
};
