import React, { useMemo } from "react";
import TagsList from "./TagsList";
import { Button, CommandGroup } from "@kino/ui";
import { useSecondaryFilterPanelStore } from "@/store/filter/secondaryFilterPanelStore";
import { components } from "@/openapi-bindings/v2";
import { formatTagTypeLabel } from "@/utils/constants/tags";
import { useFilterState } from "@/hooks/useFilterState";

interface TagsFilterSectionProps {
  tags: components["schemas"]["Tag"][];
  tagType: string;
  search: string;
  label: string;
}

const TagsFilterSection: React.FC<TagsFilterSectionProps> = ({
  tags,
  tagType,
  search,
}) => {
  const { openPanel } = useSecondaryFilterPanelStore();
  const { searchState } = useFilterState();
  const filterState = searchState.filterState;

  const filteredTags = useMemo(() => {
    if (!search) {
      const first3Tags = tags.slice(0, 3);
      const first3TagIds = new Set(first3Tags.map((t) => t.id));

      const checkedTags = tags.filter(
        (tag) =>
          filterState.tag_ids?.includes(tag.id) && !first3TagIds.has(tag.id),
      );

      return [...first3Tags, ...checkedTags];
    }

    return tags.filter((tag) =>
      tag.value.toLowerCase().includes(search.toLowerCase()),
    );
  }, [tags, search, filterState.tag_ids]);

  const handleOpenPanel = () => {
    openPanel({ key: "tag_ids", tagType: tagType });
  };

  return (
    <CommandGroup>
      <div className="flex items-center justify-between">
        <div className="py-2 text-xs text-neutral-500">
          {formatTagTypeLabel(tagType)}
        </div>
        <div className="flex items-center gap-2">
          <Button
            variant="link"
            size="sm"
            className={`text-xs font-normal text-indigo-500`}
            onClick={handleOpenPanel}
          >
            All ({tags.length})
          </Button>
        </div>
      </div>
      <TagsList tags={filteredTags} />
    </CommandGroup>
  );
};

export default TagsFilterSection;
