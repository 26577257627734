import { useState, useEffect } from "react";
import { useActiveServerStore } from "@/store/activeServerStore";

export const usePythonServerStatus = () => {
  const [isPythonServerOnline, setIsPythonServerOnline] = useState<
    boolean | null
  >(null);

  // Subscribe to the effective URL directly - this will cause the effect to re-run
  // whenever either baseUrl or overrideUrl changes
  const effectiveUrl = useActiveServerStore((state) => state.getEffectiveUrl());

  useEffect(() => {
    const checkPythonServer = async () => {
      if (!effectiveUrl) {
        setIsPythonServerOnline(null);
        return;
      }

      try {
        const response = await fetch(`${effectiveUrl}ping`, {
          method: "GET",
        });
        if (response.ok) {
          const data = await response.json();
          setIsPythonServerOnline(data.message === "pong");
        } else {
          setIsPythonServerOnline(false);
        }
      } catch (error) {
        console.error("Error checking Python server:", error);
        setIsPythonServerOnline(false);
      }
    };

    // Check immediately when URL changes
    checkPythonServer();

    // Also set up polling
    const interval = setInterval(checkPythonServer, 30000);
    return () => clearInterval(interval);
  }, [effectiveUrl]); // Re-run when effectiveUrl changes

  return isPythonServerOnline;
};
