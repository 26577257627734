import React, { useMemo } from "react";
import { cn } from "@/utils/tailwind";

interface PanelHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

// Memoize the header content to prevent re-renders
const HeaderContent = React.memo(
  ({ children }: { children: React.ReactNode }) => <>{children}</>,
);

const PanelHeader = React.memo<PanelHeaderProps>(
  ({ children, className, ...props }) => {
    const computedClassName = useMemo(
      () =>
        cn(
          "flex h-9 max-h-9 min-h-9 w-full items-center border-b p-2 text-xs text-white",
          className,
        ),
      [className],
    );

    return (
      <div className={computedClassName} {...props}>
        <HeaderContent>{children}</HeaderContent>
      </div>
    );
  },
);

PanelHeader.displayName = "PanelHeader";
HeaderContent.displayName = "HeaderContent";

export default PanelHeader;
