import { useNavigate, useSearchParams } from "react-router-dom";
import { useSignIn, useAuth } from "@clerk/clerk-react";
import { useEffect } from "react";
import { toast } from "sonner";
import Loading from "@/layouts/Loading";

export default function DesktopLoginSuccess() {
  console.log("DesktopLoginSuccess");
  const [searchParams] = useSearchParams();
  const ticketToken = searchParams.get("ticket");

  const { signOut } = useAuth();
  const { signIn, isLoaded, setActive } = useSignIn();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoaded || !setActive) {
      return;
    }

    if (!ticketToken) {
      return;
    }

    const loadSession = async () => {
      try {
        const signInAttempt = await signIn.create({
          strategy: "ticket",
          ticket: ticketToken,
        });

        if (signInAttempt.status !== "complete") {
          throw signInAttempt;
        } else {
          await setActive({ session: signInAttempt.createdSessionId });
        }
      } catch (error) {
        console.error(error);
        toast.error("There was an error logging into your account");

        signOut();
      } finally {
        navigate("/");
      }
    };

    loadSession();
  }, [isLoaded, ticketToken, setActive]);

  return (
    <div className="flex min-h-screen items-center justify-center">
      <Loading />
    </div>
  );
}
