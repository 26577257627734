import * as React from "react";
import { useMemo, useState } from "react";
import { ChevronDownIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import {
  cn,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@kino/ui";

// Memoize the badge component
const NumBadge = React.memo(({ value }: { value: number }) => (
  <div className="flex h-3 w-3 items-center justify-center rounded-full bg-indigo-600 text-[8px] font-medium text-white">
    {value}
  </div>
));

// Memoize the chevron icon component
const ChevronIcon = React.memo(({ isOpen }: { isOpen: boolean }) => (
  <div className="h-4 w-4 text-neutral-400">
    {!isOpen && <ChevronRightIcon />}
    {isOpen && <ChevronDownIcon />}
  </div>
));

// Memoize the trigger content
const TriggerContent = React.memo(
  ({
    icon,
    label,
    triggerRightContent,
    numBadge,
    isExpandable,
    isOpen,
    className,
  }: {
    icon?: React.ReactNode;
    label: string;
    triggerRightContent?: React.ReactNode;
    numBadge?: number;
    isExpandable: boolean;
    isOpen: boolean;
    className?: string;
  }) => (
    <div
      className={cn(
        `flex w-full cursor-pointer items-center justify-between text-neutral-200`,
        className,
      )}
    >
      <div className="flex items-center gap-2">
        {icon && <div className="h-3.5 w-3.5 text-neutral-500">{icon}</div>}
        <span className="truncate text-ellipsis text-xs font-medium">
          {label}
        </span>
      </div>
      <div className="flex items-center gap-1">
        {triggerRightContent}
        {!!numBadge && <NumBadge value={numBadge} />}
        {isExpandable && <ChevronIcon isOpen={isOpen} />}
      </div>
    </div>
  ),
);

export interface InspectorCollapsiblePanelSectionProps
  extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  label: string;
  name: string;
  icon?: React.ReactNode;
  defaultOpen?: boolean;
  contentClassName?: string;
  /**
   * A number in a badge to display on the right side of the section.
   * For filter sections, this shows the number of filters that are currently applied.
   */
  numBadge?: number;
  /**
   * Optional content to display when the section is collapsed.
   * For filter sections, this shows the filters that are currently applied.
   */
  previewContent?: React.ReactNode;
  triggerRightContent?: React.ReactNode;
  isExpandable?: boolean;
}

const InspectorCollapsiblePanelSection =
  React.memo<InspectorCollapsiblePanelSectionProps>(
    ({
      children,
      label,
      name,
      icon,
      className,
      defaultOpen,
      contentClassName,
      numBadge,
      previewContent,
      triggerRightContent,
      isExpandable = true,
      ...props
    }) => {
      const [open, setOpen] = useState(defaultOpen);
      // TODO: The below stores the expanded state but it's unclear whether users want this behavior. Keeping for now. Jan 14 2025

      // const isOpen = useInspectorStore(
      //   (state) => state.expandedSections[name] || false,
      // );
      // const setSectionExpanded = useInspectorStore(
      //   (state) => state.setSectionExpanded,
      // );

      // useEffect(() => {
      //   if (defaultOpen) {
      //     setSectionExpanded(name, defaultOpen);
      //   }
      // }, [defaultOpen, name, setSectionExpanded]);

      // useEffect(() => {
      //   if (!isExpandable) {
      //     setSectionExpanded(name, false);
      //   }
      // }, [isExpandable, name, setSectionExpanded]);

      const triggerClassName = useMemo(
        () =>
          cn(
            "flex w-full p-2",
            previewContent
              ? "flex-col items-start gap-2"
              : "h-11 items-center justify-between",
          ),
        [previewContent],
      );

      const collapsibleContentClassName = useMemo(
        () => cn("flex w-full", contentClassName),
        [contentClassName],
      );

      return (
        <Collapsible
          defaultOpen={defaultOpen}
          open={open}
          onOpenChange={setOpen}
        >
          <CollapsibleTrigger asChild>
            <div className={triggerClassName}>
              <TriggerContent
                icon={icon}
                label={label}
                triggerRightContent={triggerRightContent}
                numBadge={numBadge}
                isExpandable={isExpandable}
                isOpen={!!open}
                className={className}
              />
              {previewContent && <div>{previewContent}</div>}
            </div>
          </CollapsibleTrigger>
          {isExpandable && (
            <CollapsibleContent className={collapsibleContentClassName}>
              {children}
            </CollapsibleContent>
          )}
        </Collapsible>
      );
    },
  );

InspectorCollapsiblePanelSection.displayName =
  "InspectorCollapsiblePanelSection";

export { InspectorCollapsiblePanelSection };
