import { getKinoMetadata } from "@/hooks/ag-grid/useAGMediaGrid";
import { useSelectedMediaStore } from "@/store/selectedMediaStore";
import { constructMomentsFromHighlightString } from "@/utils/highlights";
import { ScrollArea } from "@kino/ui";
import { HighlightItem } from "../highlights/HighlightItem";
import { useGetTranscriptQuery } from "@/hooks/useGetTranscriptQuery";

export const InspectorHighlightsTab = () => {
  const selectedMedia = useSelectedMediaStore(
    (state) => state.currentSelection,
  );
  const numSelectedMedia = selectedMedia?.length ?? 0;
  const singleSelection =
    numSelectedMedia === 1 ? selectedMedia?.[0] : undefined;

  if (!singleSelection) {
    return null;
  }

  const { data: transcript } = useGetTranscriptQuery(
    singleSelection.mediaItem ?? null,
  );

  const kinoMetadata = getKinoMetadata(singleSelection.mediaItem);
  const highlights = constructMomentsFromHighlightString(
    kinoMetadata?.moments ?? "",
    singleSelection.mediaItem,
  );

  return (
    <div className="flex h-full w-full overflow-hidden">
      <ScrollArea className="w-full">
        <div className="flex flex-col gap-2 p-3">
          {highlights.map((highlight) => (
            <HighlightItem
              highlight={highlight}
              mediaItem={{
                ...singleSelection.mediaItem,
                transcript: singleSelection.mediaItem.transcript ?? transcript,
              }}
            />
          ))}
        </div>
      </ScrollArea>
    </div>
  );
};
