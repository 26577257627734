import { scan } from "react-scan"; // import this BEFORE react
import React from "react";
import { useDebuggerStore } from "./store/debuggerStore";

if (typeof window !== "undefined") {
  scan({
    enabled: useDebuggerStore.getState().isReactScanEnabled,
    log: false,
  });
}

import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "@kino/ui/dist/output.css";
import "@kino/player/main.css";
import "@glideapps/glide-data-grid/dist/index.css";
import "@/index.css";

import KinoWrapper from "@/layouts/KinoWrapper";
import RootLayout from "@/layouts/Root";

import MediaPage from "@/routes/media";
import OmnisearchPage from "@/routes/omnisearch";
import SignInPage from "@/routes/sign-in";
import IndexPage from "@/routes/welcome";

import RouterErrorBoundary from "@components/ErrorBoundary";
import { NoOrganizationPage } from "./routes/no-organization";
import LoginSuccess from "./routes/login/success";
import DesktopLoginSuccess from "./routes/login/desktop-success";
import FaceUploaderPage from "./routes/face-uploader";

import { ModuleRegistry, ClientSideRowModelModule } from "ag-grid-community";
import { LicenseManager } from "ag-grid-enterprise";
import { AG_GRID_TRIAL_LICENSE_KEY } from "./utils/ag-grid-config";
import Playlists from "./routes/playlists";
import Playlist from "./routes/playlists/playlist";
import OverviewPage from "./routes/overview";

ModuleRegistry.registerModules([ClientSideRowModelModule]);

LicenseManager.setLicenseKey(AG_GRID_TRIAL_LICENSE_KEY);

const router = createBrowserRouter([
  {
    element: <RootLayout />,
    errorElement: <RouterErrorBoundary />,
    children: [
      // Public routes
      { path: "/", element: <IndexPage /> },
      { path: "/sign-in/*", element: <SignInPage /> },
      { path: "/login/success", element: <LoginSuccess /> },
      { path: "/login/desktop-success", element: <DesktopLoginSuccess /> },
      { path: "/no-access", element: <NoOrganizationPage /> },

      // Protected routes under KinoWrapper
      {
        element: <KinoWrapper />,
        children: [
          {
            path: "media",
            element: <MediaPage />,
          },
          {
            path: "overview",
            element: <OverviewPage />,
          },
          {
            path: "search",
            element: <OmnisearchPage />,
          },
          {
            path: "face-uploader",
            element: <FaceUploaderPage />,
          },
          {
            path: "playlists",
            children: [
              { path: "", element: <Playlists /> },
              { path: ":playlistId", element: <Playlist /> },
            ],
          },
        ],
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
