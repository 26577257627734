import { formatTime as vidstackFormatTime } from "@vidstack/react";
import React, { createContext, PropsWithChildren, useContext } from "react";

type FormatTimeFunction = (time: number) => string;
const FormatTimeContext = createContext<FormatTimeFunction>(vidstackFormatTime);

export const FormatTimeProvider: React.FC<
  PropsWithChildren<{ formatTime?: FormatTimeFunction }>
> = ({ formatTime = vidstackFormatTime, children }) => {
  return (
    <FormatTimeContext.Provider value={formatTime}>
      {children}
    </FormatTimeContext.Provider>
  );
};

export const useFormatTime = () => {
  const formatTime = useContext(FormatTimeContext);

  if (!formatTime) {
    throw new Error("useFormatTime must be used within a FormatTimeProvider");
  }

  return formatTime;
};

export const useFormattedTime = (time: number): string => {
  const formatTime = useContext(FormatTimeContext);

  if (!formatTime) {
    throw new Error(
      "useFormattedTime must be used within a FormatTimeProvider",
    );
  }

  return formatTime(time);
};
