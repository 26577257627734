import React, { createContext, useContext, useRef } from "react";

export interface ICommentInputContext {
  commentTextAreaRef: React.RefObject<HTMLTextAreaElement>;
}

const CommentInputContext = createContext<ICommentInputContext | undefined>(
  undefined,
);

export const CommentInputProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const commentTextAreaRef = useRef<HTMLTextAreaElement>(null);

  return (
    <CommentInputContext.Provider value={{ commentTextAreaRef }}>
      {children}
    </CommentInputContext.Provider>
  );
};

export const useCommentInputContext = (): ICommentInputContext => {
  const context = useContext(CommentInputContext);
  if (!context) {
    throw new Error(
      "useCommentInputContext must be used within a CommentInputProvider",
    );
  }
  return context;
};
