import React, { useEffect, useRef, useCallback, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useSearch } from "@/hooks/useSearch";
import { stringifyParsedQuery } from "@utils/search";
import {
  useCommandMenuStore,
  useCommandMenuFocus,
  CommandMenuFocusType,
} from "@/store/commandMenuStore";
import { useSearchRequestStore } from "@/store/search/searchRequestStore";
import { useOmniSearch } from "@/hooks/useOmniSearch";
import { useFilterState } from "@/hooks/useFilterState";
import { Search } from "lucide-react";

export const COMMAND_ITEM_VALUE_DELIMITER = "_";

export enum SearchInputVariant {
  default = "default",
  outline = "outline",
}

interface SearchInputProps {
  isDialog?: boolean;
  focused: boolean;
  setFocused: (focused: boolean) => void;
  variant?: SearchInputVariant;
  maxListWidth?: number;
  focusByDefault?: boolean;
}

export const SearchInput = React.memo(
  ({ focused, setFocused, focusByDefault }: SearchInputProps) => {
    const { parsedSearchQuery } = useSearchRequestStore((state) => ({
      parsedSearchQuery: state.parsedSearchQuery,
    }));
    const { searchState } = useFilterState();
    const filterState = searchState.filterState;
    const { searchRequest } = useOmniSearch();

    const { activeSearchEntity, setActiveSearchEntity } = useCommandMenuStore(
      useCallback(
        (state) => ({
          activeSearchEntity: state.activeSearchEntity,
          setActiveSearchEntity: state.setActiveSearchEntity,
        }),
        [],
      ),
    );

    const inputRef = useRef<HTMLInputElement>(null);
    const { handleSearch } = useSearch();
    const [kw, setKw] = useState<string>(searchRequest.text ?? "");

    useEffect(() => {
      focusByDefault && setFocused(true);
    }, [focusByDefault, setFocused]);

    useEffect(() => {
      if (focused) {
        inputRef.current?.focus();
      } else {
        setActiveSearchEntity(null);
        inputRef.current?.blur();
      }
    }, [focused, setActiveSearchEntity]);

    const onFocusShortcut = useCallback(() => {
      setFocused(true);
      inputRef.current?.select();
    }, [setFocused]);

    useHotkeys("escape", () => setFocused(false));
    useHotkeys("meta+l", onFocusShortcut);

    const handleBlur = useCallback(() => {
      setFocused(false);
    }, [setFocused]);

    const handleFocus = useCallback(() => {
      setFocused(true);
    }, [setFocused]);

    const handleInput = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setKw(value);
        const cursorPos = e.target.selectionStart;

        if (value[cursorPos! - 1] === "@") {
          setActiveSearchEntity({
            atSymbolCursorPosition: cursorPos || 0,
            text: "",
          });
        } else if (activeSearchEntity) {
          const start = activeSearchEntity.atSymbolCursorPosition;
          const end = cursorPos || 0;
          setActiveSearchEntity({
            ...activeSearchEntity,
            text: value.slice(start, end),
          });
        }
      },
      [activeSearchEntity, setActiveSearchEntity],
    );

    const handleKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
          const newKw = stringifyParsedQuery({
            ...parsedSearchQuery,
            queryString: kw,
            frames: parsedSearchQuery?.frames || [],
          });
          handleSearch(newKw, filterState);
          setFocused(false);
        }
      },
      [kw, parsedSearchQuery, filterState, handleSearch, setFocused],
    );

    return (
      <div className="relative flex w-full items-center">
        <Search className="absolute left-2 h-3.5 w-3.5 text-neutral-400" />
        <input
          ref={inputRef}
          type="text"
          placeholder="Search..."
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={handleInput}
          onKeyDown={handleKeyDown}
          value={kw}
          autoComplete="off"
          spellCheck="false"
          className="h-7 w-full rounded-md border border-neutral-800 bg-neutral-900 pl-7 pr-3 text-xs text-white placeholder:text-neutral-400 focus:outline-none focus:ring-1 focus:ring-neutral-400/30"
        />
      </div>
    );
  },
);

SearchInput.displayName = "SearchInput";

// Optional: Keep the dialog functionality if needed
export const SearchDialog = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [dialogFocused, setDialogFocused] = useCommandMenuFocus(
    CommandMenuFocusType.Dialog,
  );

  useEffect(() => {
    !dialogFocused && setOpen(false);
  }, [dialogFocused]);

  // Cmd + K
  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen((open) => !open);
      }
    };
    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, [setDialogFocused]);

  return open ? (
    <SearchInput
      isDialog={true}
      focused={dialogFocused}
      setFocused={(focused) => setDialogFocused(focused)}
    />
  ) : null;
};
