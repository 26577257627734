import {
  MediaPlayer,
  MediaProvider,
  MEDIA_KEY_SHORTCUTS,
  MediaPlayerInstance,
  useStore,
  type MediaPlayerProps,
  Poster,
} from "@vidstack/react";

import * as Buttons from "./ui/buttons";
import * as Sliders from "./ui/sliders";
import { DefaultGestures } from "./ui/gestures";
import TimeGroup from "./ui/TimeGroup";
import { TooltipProvider } from "@kino/ui";
import { Controls } from "@vidstack/react";

import React, { useRef } from "react";

import "@vidstack/react/player/styles/base.css";
import "@vidstack/react/player/styles/default/theme.css";
import "@vidstack/react/player/styles/default/layouts/video.css";

import { cn } from "../utils/cn";
import { ClipMomentProvider } from "../context/ClipMomentsContext";
import {
  PlayerZoom,
  PlayerZoomControllerProvider,
} from "../context/ZoomControllerContext";
import { PlayerMomentProvider } from "../context/PlayerMomentsContext";
import { FrameRate, FrameRateProvider } from "../context/FramerateContext";
import { FormatTimeProvider } from "../context/FormatTimeContext";

const COARSE_SEEK_PERCENTAGE = 0.5;
const DEFAULT_POSTER_ALT = "Thumbnail for video";

export const Player = React.forwardRef<MediaPlayerInstance, PlayerProps>(
  (
    {
      src,
      posterSrc,
      posterAlt,
      thumbnails,
      className,
      useDefaultGestures = true,
      customGestures = null,
      useDefaultControls = true,
      customControls = null,
      hideFullscreenButton = false,
      hidePiPButton = false,
      hideVolumeSlider = false,
      hideTimeNumbers = false,
      keyShortcuts,
      storage,
      zoom,
      frameRate,
      shouldZoomTrackCurrentTime,
      clipInTime,
      clipOutTime,
      loopClip,
      formatTime,
      ...props
    },
    forwardedRef,
  ) => {
    const internalRef = useRef<MediaPlayerInstance>(null);
    const playerRef = (forwardedRef ||
      internalRef) as React.RefObject<MediaPlayerInstance>;
    const { error } = useStore(MediaPlayerInstance, playerRef);

    const showDefaultGestures = useDefaultGestures && customGestures === null;
    const showDefaultControls = useDefaultControls && customControls === null;

    return (
      <MediaPlayer
        ref={playerRef}
        className={cn(
          "aspect-video h-auto max-h-full w-full max-w-full overflow-hidden bg-black object-contain text-white",
          className,
        )}
        src={src}
        autoPlay
        aspectRatio="16/9"
        storage={storage || "kino-player-key"}
        keyShortcuts={{
          ...MEDIA_KEY_SHORTCUTS,
          coarseSeekBackward: {
            keys: "shift+ArrowLeft",
            onKeyDown: ({ player, remote }) => {
              remote.seek(-COARSE_SEEK_PERCENTAGE * player.state.duration);
            },
          },
          coarseSeekForward: {
            keys: "shift+ArrowRight",
            onKeyDown({ player, remote }) {
              remote.seek(COARSE_SEEK_PERCENTAGE * player.state.duration);
            },
          },
          ...keyShortcuts,
        }}
        onError={(e) => console.error(e)}
        {...props}
      >
        <FormatTimeProvider formatTime={formatTime}>
          <PlayerMomentProvider>
            <PlayerZoomControllerProvider
              initialZoom={zoom}
              trackCurrentTime={shouldZoomTrackCurrentTime}
            >
              <ClipMomentProvider
                clipInTime={clipInTime}
                clipOutTime={clipOutTime}
                loopClip={loopClip}
              >
                <FrameRateProvider frameRate={frameRate}>
                  <MediaProvider className="w-full [&>video]:aspect-video [&>video]:h-auto [&>video]:max-h-full [&>video]:w-full [&>video]:max-w-full" />
                  <>
                    {posterSrc && (
                      <Poster
                        src={posterSrc}
                        alt={posterAlt || DEFAULT_POSTER_ALT}
                        className="absolute inset-0 block h-full w-full bg-black opacity-0 transition-opacity data-[visible]:opacity-100 [&>img]:h-full [&>img]:w-full [&>img]:object-cover"
                      />
                    )}
                    {showDefaultGestures && <DefaultGestures />}
                    {customGestures}
                    {showDefaultControls && (
                      <Controls.Root className="media-controls:opacity-100 absolute inset-0 z-10 flex h-full w-full flex-col bg-gradient-to-t from-black/10 to-transparent transition-opacity">
                        <TooltipProvider>
                          <div className="flex-1" />
                          <Controls.Group className="flex w-full items-center px-2">
                            <Sliders.Time thumbnails={thumbnails} />
                          </Controls.Group>
                          <Controls.Group className="-mt-0.5 flex w-full items-center px-2 pb-2">
                            <Buttons.Play />
                            <Buttons.Mute />
                            {!hideVolumeSlider && <Sliders.Volume />}
                            {!hideTimeNumbers && <TimeGroup />}
                            <div className="flex-1" />
                            {!hidePiPButton && <Buttons.PIP />}
                            {!hideFullscreenButton && <Buttons.Fullscreen />}
                          </Controls.Group>
                        </TooltipProvider>
                      </Controls.Root>
                    )}
                    {customControls}
                  </>
                </FrameRateProvider>
              </ClipMomentProvider>
            </PlayerZoomControllerProvider>
          </PlayerMomentProvider>
        </FormatTimeProvider>
        {error && (
          <div className="bg-muted absolute inset-0 flex flex-col items-center justify-center gap-1 p-8 text-xs">
            <h1 className="text-neutral-500">
              There was an error loading or playing your video.
            </h1>
            <p className="rounded-md bg-neutral-900 p-2 text-neutral-500">
              {error.message}
            </p>
          </div>
        )}
      </MediaPlayer>
    );
  },
);

interface PlayerProps extends Omit<MediaPlayerProps, "children" | "src"> {
  src: string;
  className?: string;
  posterSrc?: string;
  posterAlt?: string;
  useDefaultGestures?: boolean;
  customGestures?: React.ReactNode;
  useDefaultControls?: boolean;
  customControls?: React.ReactNode;
  hideFullscreenButton?: boolean;
  hidePiPButton?: boolean;
  hideVolumeSlider?: boolean;
  hideTimeNumbers?: boolean;
  thumbnails?: string;
  zoom?: PlayerZoom;
  frameRate?: FrameRate;
  shouldZoomTrackCurrentTime?: boolean;
  clipInTime?: number;
  clipOutTime?: number;
  loopClip?: boolean;
  formatTime?: (time: number) => string;
}
