import { createClearable } from "@/utils/zustand/createClearable";

export type SummaryStatus = "generating" | "completed" | "failed";

interface SummaryItem {
  mediaId: string;
  status: SummaryStatus;
  startTime: string; // ISO string
  error?: string;
}

interface SummaryGenerationStore {
  summaries: Record<string, SummaryItem>;

  // Start generation for multiple media items
  startGeneration: (mediaIds: string[]) => void;

  // Update status for a specific media item
  updateStatus: (
    mediaId: string,
    status: SummaryStatus,
    error?: string,
  ) => void;

  // Clear completed or failed items
  clearFinished: () => void;

  // Get status for a specific media item
  getStatus: (mediaId: string) => SummaryStatus | null;

  // Check if any summaries are currently generating
  hasActiveGenerations: () => boolean;
}

export const useSummaryGenerationStore =
  createClearable()<SummaryGenerationStore>()((set, get) => ({
    summaries: {},

    startGeneration: (mediaIds) => {
      const newSummaries = mediaIds.reduce(
        (acc, mediaId) => {
          acc[mediaId] = {
            mediaId,
            status: "generating",
            startTime: new Date().toISOString(),
          };
          return acc;
        },
        {} as Record<string, SummaryItem>,
      );

      set((state) => ({
        summaries: {
          ...state.summaries,
          ...newSummaries,
        },
      }));
    },

    updateStatus: (mediaId, status, error) => {
      set((state) => ({
        summaries: {
          ...state.summaries,
          [mediaId]: {
            ...state.summaries[mediaId],
            mediaId, // Ensure mediaId is preserved
            startTime:
              state.summaries[mediaId]?.startTime || new Date().toISOString(), // Preserve or create startTime
            status,
            error,
          },
        },
      }));
    },

    clearFinished: () => {
      set((state) => ({
        summaries: Object.entries(state.summaries).reduce(
          (acc, [id, item]) => {
            if (item.status === "generating") {
              acc[id] = item;
            }
            return acc;
          },
          {} as Record<string, SummaryItem>,
        ),
      }));
    },

    getStatus: (mediaId) => {
      return get().summaries[mediaId]?.status ?? null;
    },

    hasActiveGenerations: () => {
      return Object.values(get().summaries).some(
        (item) => item.status === "generating",
      );
    },
  }));
