import { MediaItemContext } from "./MediaItemContext";
import { PropsWithChildren } from "react";

export const MediaItem = ({
  mediaItemId,
  children,
}: PropsWithChildren<{ mediaItemId: string }>) => {
  return (
    <MediaItemContext.Provider value={mediaItemId}>
      {children}
    </MediaItemContext.Provider>
  );
};
