import { CopyButton } from "@/components/CopyButton";
import { useEffect, useState } from "react";
import { useRouteError, useNavigate } from "react-router-dom";
import Loading from "../layouts/Loading";
import { cn, ScrollArea, Button } from "@kino/ui";
import Prism from "prismjs";
import "prismjs/themes/prism-tomorrow.min.css";
import "prismjs/components/prism-typescript";
import "prismjs/components/prism-json";
import { EnvelopeClosedIcon } from "@radix-ui/react-icons";

interface GradientOverlayProps extends React.HTMLAttributes<HTMLDivElement> {
  direction: "top" | "bottom";
}

export const GradientOverlay = ({
  className,
  direction,
  ...props
}: GradientOverlayProps) => {
  return (
    <div
      className={cn(
        "pointer-events-none absolute left-0 right-0 z-[1] h-[35%] opacity-30",
        direction === "top" &&
          "top-0 rounded-t-lg bg-gradient-to-b from-black to-transparent",
        direction === "bottom" &&
          "bottom-0 rounded-b-lg bg-gradient-to-t from-black to-transparent",
        className,
      )}
      {...props}
    />
  );
};

const constructSupportEmail = (errorDetails: string) => {
  const subject = encodeURIComponent("I received an error using Kino for Web");
  const body = encodeURIComponent(
    `Hi Kino Support,\n\n` +
      `I encountered the following error while using Kino:\n\n` +
      `Error Details:\n${errorDetails}\n\n` +
      `Best regards`,
  );

  return `mailto:support@kino.ai?subject=${subject}&body=${body}`;
};

const RouterErrorBoundary = () => {
  const error = useRouteError();
  const navigate = useNavigate();
  const [errorDetails, setErrorDetails] = useState<{
    raw: string;
    highlighted: string;
  } | null>(null);

  useEffect(() => {
    const processError = async () => {
      if (error instanceof Error) {
        const rawStack = error.stack || error.message;
        const highlightedStack = Prism.highlight(
          rawStack,
          Prism.languages.typescript as Prism.Grammar,
          "typescript",
        );
        setErrorDetails({
          raw: rawStack,
          highlighted: highlightedStack,
        });
      } else {
        try {
          const formattedJson = JSON.stringify(error, null, 2);
          const highlightedJson = Prism.highlight(
            formattedJson,
            Prism.languages.json as Prism.Grammar,
            "json",
          );
          setErrorDetails({
            raw: formattedJson,
            highlighted: highlightedJson,
          });
        } catch {
          const errorString = String(error);
          setErrorDetails({
            raw: errorString,
            highlighted: errorString,
          });
        }
      }
    };
    processError();
  }, [error]);

  if (!errorDetails) {
    return <Loading className="h-screen" />;
  }

  return (
    <div className="flex h-screen flex-col items-center justify-center gap-4 bg-black text-neutral-100">
      <div className="flex flex-col items-center">
        <h1 className="text-lg font-normal">Oops! Something Went Wrong</h1>
        <p className="text-neutral-500">
          See the error below. For help,{" "}
          <a
            href={errorDetails ? constructSupportEmail(errorDetails.raw) : "#"}
            className="text-indigo-400 transition-colors hover:text-indigo-300"
          >
            send this error to support
          </a>
        </p>
      </div>

      <div className="relative w-3/4 max-w-3xl rounded-lg border border-dashed border-neutral-800 text-sm shadow-lg">
        <div className="relative m-2 rounded-lg border border-neutral-800 bg-neutral-900">
          <div className="absolute right-2 top-2 z-10 mb-4 flex items-end justify-end gap-2">
            <a
              href={
                errorDetails ? constructSupportEmail(errorDetails.raw) : "#"
              }
              className="flex h-5 w-5 items-center justify-center rounded-sm text-neutral-400 transition-colors hover:text-white"
            >
              <EnvelopeClosedIcon className="h-3 w-3" />
            </a>
            <CopyButton
              content={errorDetails.raw}
              toastMessage="Error details copied to clipboard"
              tooltipText="Copy Error"
            />
          </div>

          <GradientOverlay direction="top" />

          <ScrollArea className="relative max-h-[70vh] overflow-auto whitespace-pre-wrap bg-transparent p-5 text-left text-neutral-300">
            <pre
              className="langauge-typescript whitespace-pre-wrap break-words rounded p-4"
              dangerouslySetInnerHTML={{ __html: errorDetails.highlighted }}
            />
          </ScrollArea>

          <GradientOverlay direction="bottom" />
        </div>
      </div>

      <Button
        variant="kino"
        size="lg"
        className="mt-2"
        onClick={() => navigate("/")}
      >
        Try going home
      </Button>
    </div>
  );
};

export default RouterErrorBoundary;
