import React, { useEffect, useCallback, useRef, useState } from "react";
import Loading from "@/layouts/Loading";
import { formatStringPlurality } from "@/utils/stringUtils";
import PanelHeader from "@/layouts/panels/PanelHeader";
import { Input, Separator } from "@kino/ui";
import { FilterButton } from "@/components/filter/FilterButton";
import FilterPanel from "@/components/filter/filterPanel/FilterPanel";
import { useLayoutStore } from "@/store/layoutStore";
import MediaGrid from "@/components/ag-grid/MediaGrid";
import { AgGridReact } from "ag-grid-react";
import { components } from "@/openapi-bindings/v2";
import { GridReadyEvent, SelectionChangedEvent } from "ag-grid-community";
import { useDisplayStore } from "@/store/display/displayStore";
import { useClerkServerInfo } from "@/hooks/useClerkMetadata";
import { DisplayMode } from "@/types/display";
import { DisplayPopover } from "@/components/display/DisplayPopover";

const MediaPage: React.FC = () => {
  const { isFilterPanelOpen, set } = useLayoutStore();
  const { config: serverConfig } = useClerkServerInfo();
  const { setMode, modeOverride } = useDisplayStore();
  const gridRef = useRef<AgGridReact<components["schemas"]["MediaItem"]>>(null);

  const [gridMetrics, setGridMetrics] = useState({
    selectedRows: 0,
    filteredRows: 0,
  });

  // Set initial display mode from workspace
  useEffect(() => {
    if (!modeOverride && serverConfig?.defaultDisplayMode) {
      setMode(serverConfig.defaultDisplayMode as DisplayMode);
    }
  }, [serverConfig, setMode, modeOverride]);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    const updateMetrics = () => {
      setGridMetrics((current) => ({
        ...current,
        filteredRows: params.api.getDisplayedRowCount(),
      }));
    };

    params.api.addEventListener("modelUpdated", updateMetrics);
    updateMetrics();
  }, []);

  const onSelectionChanged = useCallback((event: SelectionChangedEvent) => {
    setGridMetrics((current) => ({
      ...current,
      selectedRows: event.api.getSelectedRows().length,
    }));
  }, []);

  useEffect(() => {
    if (gridRef.current?.api) {
      setGridMetrics((current) => ({
        ...current,
        selectedRows: gridRef.current!.api.getSelectedRows().length,
      }));
    }
  }, [gridRef.current]);

  const getItemCountDisplay = () => {
    const totalRows = gridRef.current?.api?.getDisplayedRowCount() ?? 0;
    const itemText = formatStringPlurality(totalRows, "item", "items");

    if (gridMetrics.filteredRows == totalRows) {
      return `${totalRows} ${itemText}`;
    }
    return `${gridMetrics.filteredRows} of ${totalRows} ${itemText}`;
  };

  return (
    <div className="flex h-full w-full overflow-hidden">
      <FilterPanel />
      <Separator orientation="vertical" />
      <div className="flex h-full w-full flex-col divide-y">
        <PanelHeader className="flex h-11 max-h-11 min-h-11 w-full justify-between gap-2 border-none">
          <div className="flex items-center gap-2">
            {!isFilterPanelOpen && (
              <FilterButton onClick={() => set("isFilterPanelOpen", true)} />
            )}
            {/* <Input
              id="filter-text-box"
              placeholder="Search..."
              value={quickFilterText}
              onInput={onFilterTextBoxChanged}
              className="h-7 w-[300px] rounded border border-neutral-400/20 text-white placeholder:text-neutral-400"
            /> */}
            {gridMetrics.selectedRows > 0 && (
              <span className="text-xs text-neutral-400">
                {gridMetrics.selectedRows} selected
              </span>
            )}
          </div>
          <div className="flex items-center gap-2">
            <span className="text-xs text-neutral-400">
              {getItemCountDisplay()}
            </span>
            <DisplayPopover variant="media" />
          </div>
        </PanelHeader>
        <MediaGrid
          media={undefined}
          gridRef={gridRef}
          onGridReady={onGridReady}
          onSelectionChanged={onSelectionChanged}
        />
      </div>
    </div>
  );
};

export default MediaPage;
