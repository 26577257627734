import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useController } from "react-hook-form";
import { z } from "zod";
import {
  Form,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Switch,
} from "@kino/ui";
import {
  forwardRef,
  useImperativeHandle,
  memo,
  useCallback,
  useMemo,
} from "react";
import { getServerUrl } from "@/utils/stringUtils";
import { useOverrideServerUrl } from "@/providers/ServerProvider";

export const RemoteCacheSchema = z.object({
  serverIp: z.string().min(1, {
    message: "Server address is required",
  }),
  serverPort: z
    .string()
    .min(1, {
      message: "Port is required",
    })
    .refine(
      (value) => {
        const numberValue = parseInt(value);
        return !isNaN(numberValue) && numberValue >= 1 && numberValue <= 65535;
      },
      {
        message: "Port is out of range",
      },
    ),
  useHttps: z.boolean().default(true),
  id: z.string().optional(),
  signInOnly: z.boolean().default(false),
});

export type TServerConnectionForm = z.infer<typeof RemoteCacheSchema>;

interface FormMethods {
  submit: () => void;
}

export type ServerConnectionFormRef = TServerConnectionForm & FormMethods;

interface ServerConnectionFormProps {
  defaultValues?: {
    ip: string;
    port: string;
    useHttps: boolean;
  };
  readOnly?: boolean;
}

const FormLabel_Memo = memo(FormLabel);
const FormItem_Memo = memo(FormItem);
const FormMessage_Memo = memo(FormMessage);

const IpFormFieldContent = memo(({ field }: { field: any }) => (
  <FormItem_Memo className="flex flex-grow flex-col items-start">
    <FormLabel_Memo className="text-xs font-normal text-gray-400">
      IP or Domain
    </FormLabel_Memo>
    <Input
      {...field}
      placeholder="IP or Domain"
      className="h-8 max-h-8 w-full flex-grow shadow-none read-only:bg-gray-100"
    />
    <FormMessage_Memo className="text-xs font-normal" />
  </FormItem_Memo>
));

const IpFormField = memo(() => {
  const { field } = useController({ name: "serverIp" });
  return <IpFormFieldContent field={field} />;
});

const PortFormFieldContent = memo(
  ({ field, error }: { field: any; error?: string }) => (
    <FormItem_Memo className="flex h-full flex-grow flex-col items-start">
      <FormLabel_Memo className="text-xs font-normal text-gray-400">
        Port
      </FormLabel_Memo>
      <Input
        {...field}
        placeholder="Port"
        className="h-8 max-h-8 w-full max-w-[90px] flex-grow shadow-none read-only:bg-gray-100"
      />
      {error && (
        <FormMessage_Memo className="text-xs font-normal">
          {error}
        </FormMessage_Memo>
      )}
    </FormItem_Memo>
  ),
);

const PortFormField = memo(() => {
  const { field, fieldState } = useController({
    name: "serverPort",
    rules: {
      validate: (value) => {
        const numberValue = parseInt(value);
        return !isNaN(numberValue) && numberValue >= 1 && numberValue <= 65535
          ? true
          : "Port is out of range";
      },
    },
  });

  return (
    <PortFormFieldContent field={field} error={fieldState.error?.message} />
  );
});

const HttpsFormFieldContent = memo(({ field }: { field: any }) => (
  <FormItem_Memo className="flex items-center gap-2">
    <Switch checked={field.value} onCheckedChange={field.onChange} />
    <FormLabel_Memo className="text-xs font-normal text-gray-400">
      Use HTTPS
    </FormLabel_Memo>
  </FormItem_Memo>
));

const HttpsFormField = memo(() => {
  const { field } = useController({ name: "useHttps" });
  return <HttpsFormFieldContent field={field} />;
});

const FormContent = memo(
  ({ onSubmit }: { onSubmit: (e: React.FormEvent) => void }) => {
    return (
      <form onSubmit={onSubmit} className="flex w-full flex-col gap-2">
        <div className="flex h-full w-full items-start gap-2 text-xs text-gray-500">
          <IpFormField />
          <PortFormField />
        </div>
        <HttpsFormField />
      </form>
    );
  },
);

const ServerConnectionForm = memo(
  forwardRef<ServerConnectionFormRef, ServerConnectionFormProps>(
    ({ defaultValues, readOnly }, ref) => {
      const { setOverride } = useOverrideServerUrl();

      const defaultFormValues = useMemo(
        () => ({
          serverIp: "",
          serverPort: "1923",
          useHttps: true,
          id: "",
          signInOnly: false,
          ...defaultValues,
        }),
        [defaultValues],
      );

      const form = useForm<TServerConnectionForm>({
        resolver: zodResolver(RemoteCacheSchema),
        defaultValues: defaultFormValues,
        mode: "onChange",
      });

      const handleSubmit = useCallback(
        (data: TServerConnectionForm) => {
          setOverride(
            getServerUrl(data.serverIp, data.serverPort, data.useHttps),
          );
        },
        [setOverride],
      );

      const onSubmit = useCallback(
        (e: React.FormEvent) => {
          e.preventDefault();
          form.handleSubmit(handleSubmit)(e);
        },
        [form, handleSubmit],
      );

      useImperativeHandle(
        ref,
        () => ({
          ...form.getValues(),
          submit: () => form.handleSubmit(handleSubmit)(),
        }),
        [form, handleSubmit],
      );

      return (
        <Form {...form}>
          <FormContent onSubmit={onSubmit} />
        </Form>
      );
    },
  ),
);

ServerConnectionForm.displayName = "ServerConnectionForm";

export default ServerConnectionForm;
