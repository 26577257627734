import Timecode from "smpte-timecode";
import { useMediaItemQuery } from "./useAllMediaQuery";
import { useMediaItemId } from "@/context/MediaItemContext/MediaItemContext";
import { useCallback } from "react";

export const useFormatTimecodeForMediaItem = (
  mediaItemId: string | undefined,
) => {
  const mediaItemQuery = useMediaItemQuery(mediaItemId);

  const formatTime = useCallback(
    (time: number) => {
      if (!mediaItemId || mediaItemQuery.isLoading || mediaItemQuery.isError) {
        return new Timecode(time);
      }

      const mediaItem = mediaItemQuery.data?.[mediaItemId];

      if (!mediaItem) {
        return new Timecode(time);
      }

      const frameRate =
        mediaItem.ffprobe_data.framerate_numerator &&
        mediaItem.ffprobe_data.framerate_denominator
          ? ([
              mediaItem.ffprobe_data.framerate_numerator,
              mediaItem.ffprobe_data.framerate_denominator,
            ] as [number, number])
          : undefined;

      const startTimecode = new Timecode(
        mediaItem.start_timecode || 0,
        frameRate,
      );

      return new Timecode(startTimecode).add(startTimecode.frameRate * time);
    },
    [mediaItemId, mediaItemQuery.data],
  );

  return formatTime;
};

export const useMediaItemTimecode = (time: number) => {
  const mediaItemId = useMediaItemId();

  const formatTimecode = useFormatTimecodeForMediaItem(mediaItemId);

  return formatTimecode(time);
};
