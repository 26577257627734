import React, { useState } from "react";
import { TagIcon } from "@heroicons/react/24/outline";
import { InspectorCollapsiblePanelSection } from "@/components/inspector/collapsibleSection/InspectorCollapsiblePanelSection";
import { CommandList, Command, CommandInput } from "@kino/ui";
import TagsFilterSection from "./TagsFilterSection";
import { useFilterOptionsStore } from "@/store/filter/filterOptionsStore";
import { useFilterState } from "@/hooks/useFilterState";

const TagsFilterCollapsible: React.FC = () => {
  const [kw, setKw] = useState("");
  const { filterOptions } = useFilterOptionsStore();
  const { searchState } = useFilterState();
  const filterState = searchState.filterState;

  return (
    <InspectorCollapsiblePanelSection
      label="Tags"
      name="tags"
      icon={<TagIcon />}
      numBadge={filterState.tag_ids?.length || 0}
      defaultOpen
    >
      <div className="flex w-full flex-col px-2 pb-2">
        <Command className="bg-none">
          <CommandInput
            placeholder="Find a tag"
            searchLoading={false}
            className="h-7 rounded-md border border-neutral-800 px-1"
            value={kw}
            onValueChange={setKw}
          />
          <CommandList>
            {Object.entries(filterOptions.tag_ids.criteria || {}).map(
              ([tagType, tagData]) => (
                <TagsFilterSection
                  key={tagType}
                  tags={tagData.tags}
                  tagType={tagType}
                  search={kw}
                  label={tagData.label || ""}
                />
              ),
            )}
          </CommandList>
        </Command>
      </div>
    </InspectorCollapsiblePanelSection>
  );
};

export default TagsFilterCollapsible;
