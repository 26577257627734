import { Button, cn } from "@kino/ui";
import { TextWithTooltip } from "@kino/ui";
import { toast } from "sonner";
import { CopyIcon, CheckIcon } from "@radix-ui/react-icons";
import { useState } from "react";

interface CopyButtonProps {
  className?: string;
  content: string;
  toastMessage: string;
  tooltipText: string;
}

const CopyButton: React.FC<CopyButtonProps> = ({
  className,
  content,
  toastMessage,
  tooltipText,
}) => {
  const [showCheck, setShowCheck] = useState(false);

  const handleCopy = async (e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      await navigator.clipboard.writeText(content);
      toast.dismiss();
      toast.success(toastMessage, { duration: 1000 });
      setShowCheck(true);
      setTimeout(() => setShowCheck(false), 1000);
    } catch (err) {
      console.error("Failed to copy text: ", err);
      toast.error("Failed to copy text");
    }
  };

  return (
    <div className={cn(className)}>
      <TextWithTooltip size="xs" position={"top"} tooltipText={tooltipText}>
        <Button
          variant="ghost"
          size="sm"
          className="flex h-5 w-5 p-0 text-neutral-400 transition-colors hover:text-white dark:hover:text-white"
          onClick={handleCopy}
        >
          {showCheck ? (
            <CheckIcon className="h-3 w-3" />
          ) : (
            <CopyIcon className="h-3 w-3" />
          )}
        </Button>
      </TextWithTooltip>
    </div>
  );
};

export { CopyButton };
