import { createClearable, StoreCollectionIdentifier } from "@/utils/zustand/createClearable";
import { create } from "zustand";

export enum CommandMenuFocusType {
  Dialog = "dialog",
  Page = "page",
  Navbar = "navbar",
}

export interface ActiveSearchEntityProperties {
  atSymbolCursorPosition: number;
  text: string;
}

type CommandMenuStoreState = {
  focus: {
    dialog: boolean;
    page: boolean;
    navbar: boolean;
  };
  setFocus: (
    commandMenuFocusType: CommandMenuFocusType,
    value: boolean,
  ) => void;
  activeSearchEntity: ActiveSearchEntityProperties | null;
  setActiveSearchEntity: (value: ActiveSearchEntityProperties | null) => void;
  reset: () => void;
};

export const useCommandMenuStore = createClearable([
  StoreCollectionIdentifier.LAYOUT,
])<CommandMenuStoreState>()((set) => ({
  focus: {
    dialog: false,
    page: false,
    navbar: false,
  },
  setFocus: (type, value) =>
    set((state) => ({
      ...state,
      focus: {
        ...state.focus,
        [type]: value,
      },
    })),
  activeSearchEntity: null,
  setActiveSearchEntity: (value) =>
    set((state) => ({
      ...state,
      activeSearchEntity: value,
    })),
  reset: () => set({ focus: { dialog: false, page: false, navbar: false } }),
}));

export const useCommandMenuFocus = (type: CommandMenuFocusType) => {
  const focused = useCommandMenuStore((state) => state.focus[type]);
  const setFocus = useCommandMenuStore((state) => state.setFocus);

  const setTypedFocus = (value: boolean) => setFocus(type, value);

  return [focused, setTypedFocus] as const;
};
