import { Separator } from "@kino/ui";
import ResultsGrid from "@/components/omniSearch/ResultsGrid";
import { PlaylistsSidebar } from "@/components/playlists/PlaylistsSidebar";
import PlaylistCard from "@/components/playlists/PlaylistCard";
import { usePlaylists } from "@/hooks/usePlaylists";

const Playlists = () => {
  const { data: playlistResponse } = usePlaylists();

  if (!playlistResponse) return null;

  return (
    <div className="flex h-full w-full overflow-hidden">
      <PlaylistsSidebar />
      <Separator orientation="vertical" />
      <div className="flex h-full w-full flex-col gap-3 overflow-hidden overflow-y-scroll p-3">
        <h3 className="text-lg font-bold">Playlists</h3>
        <ResultsGrid>
          {playlistResponse?.playlists.map((playlist) => (
            <PlaylistCard playlist={playlist} />
          ))}
        </ResultsGrid>
      </div>
    </div>
  );
};

export default Playlists;
