import * as React from "react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";

import { cn } from "../utils/tailwind";
import { KeyboardShortcut } from "./keyboard-key";

const TooltipProvider = TooltipPrimitive.Provider;

const Tooltip = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipArrow = TooltipPrimitive.Arrow;

const TooltipPortal = TooltipPrimitive.Portal;

/**
 * Props for the TooltipContent component.
 * @extends React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
 * @interface TooltipContentProps
 *
 * @property {string} [shortcutKeys] - Keyboard shortcut string in react-hotkeys-hook format (e.g. "mod+s", "shift+a")
 */
export interface TooltipContentProps
  extends React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> {
  shortcutKeys?: string;
}

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  TooltipContentProps
>(({ className, sideOffset = 4, shortcutKeys, children, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      "forefront bg-popover text-popover-foreground animate-in fade-in-20 max-w-[500px] flex-wrap overflow-hidden whitespace-pre-wrap break-words rounded-md border px-3 py-1.5 text-xs shadow-md",
      "flex items-center space-x-2",
      className,
    )}
    {...props}
  >
    <span>{children}</span>
    {shortcutKeys && <KeyboardShortcut shortcut={shortcutKeys} />}
  </TooltipPrimitive.Content>
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

export {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  TooltipArrow,
  TooltipPortal,
  TooltipProvider,
};
