import { components } from "@/openapi-bindings/v2";
import { useClient } from "./useClient";
import { useSyncOrganization } from "@/store/organizationStore";
import { useDebuggerStore } from "@/store/debuggerStore";

type UseGetTranscriptResult = {
  status: "error" | "pending" | "success";
  isPending: boolean;
  isFetching: boolean;
  isLoading: boolean;
  isFetched: boolean;
  isError: boolean;
  isSuccess: boolean;
  data: components["schemas"]["Transcript"];
};

const EMPTY_TRANSCRIPT: components["schemas"]["Transcript"] = {
  id: "",
  segments: [],
};

/**
 * It would be best to use the `select` functionality from React Query,
 * but Open API React Query has a Typescript bug that makes this
 * very ugly, hence the strange return type
 *
 * [Github issue](https://github.com/openapi-ts/openapi-typescript/issues/1845)
 */
export const useGetTranscriptQuery = (
  mediaItem: components["schemas"]["MediaItem"] | null,
): UseGetTranscriptResult => {
  const isMockMediaEndpoint = useDebuggerStore(
    (state) => state.isMockMediaEndpoint,
  );
  const { apiClient } = useClient();
  const { getEffectiveOrganizationId } = useSyncOrganization();

  const transcriptsQueryResult = apiClient.useQuery(
    "get",
    "/media/transcripts",
    {
      params: {
        query: {
          media_ids: mediaItem ? [mediaItem.id] : [],
          organization_id: getEffectiveOrganizationId(),
        },
      },
    },
    {
      initialData: mediaItem?.transcript
        ? { [mediaItem.id]: mediaItem.transcript }
        : undefined,
      enabled: !isMockMediaEndpoint && !!mediaItem,
    },
  );

  return {
    status: transcriptsQueryResult.status,
    isPending: transcriptsQueryResult.isPending,
    isFetching: transcriptsQueryResult.isFetching,
    isLoading: transcriptsQueryResult.isLoading,
    isFetched: transcriptsQueryResult.isFetched,
    isSuccess: transcriptsQueryResult.isSuccess,
    isError: transcriptsQueryResult.isError,
    data: mediaItem
      ? transcriptsQueryResult.data?.[mediaItem.id] || EMPTY_TRANSCRIPT
      : EMPTY_TRANSCRIPT,
  };
};
