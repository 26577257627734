import {
  useDisplayStore,
  getEffectiveMode,
} from "@/store/display/displayStore";
import { match } from "ts-pattern";

interface SecondsToTimestampOptions {
  padZero?: boolean;
  separator?: string;
  offset?: number;
}

export const secondsToTimestamp = (
  duration: number,
  options?: SecondsToTimestampOptions,
) => {
  const { padZero = true, separator = ":", offset = 0 } = options || {};
  const state = useDisplayStore.getState();
  const mode = getEffectiveMode(state);

  // Apply offset only when in s48 mode and offset is provided
  const actualDuration = match(mode)
    .with("s48", () => (offset ? duration + offset : duration))
    .otherwise(() => duration);

  const hours = Math.floor(actualDuration / 3600);
  const minutes = Math.floor((actualDuration % 3600) / 60);
  const seconds = Math.floor(actualDuration % 60);

  const pad = (num: number, size: number) => {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  };

  let formattedTime = pad(minutes, 2) + separator + pad(seconds, 2);
  if (hours > 0 || padZero) {
    formattedTime = pad(hours, 2) + separator + formattedTime;
  }

  return formattedTime;
};

export const getTimeAgo = (pastDate: Date, abbreviated = false) => {
  const currentDate = new Date();
  const difference = Math.floor(
    (currentDate.getTime() - pastDate.getTime()) / 1000,
  );

  let output = ``;
  if (difference < 60) {
    output = abbreviated ? "<1m" : "Just now";
  } else if (difference < 3600) {
    const minutes = Math.floor(difference / 60);
    output = abbreviated
      ? `${minutes}m`
      : minutes === 1
        ? "1 minute ago"
        : `${minutes} minutes ago`;
  } else if (difference < 86400) {
    const hours = Math.floor(difference / 3600);
    output = abbreviated
      ? `${hours}h`
      : hours === 1
        ? "1 hour ago"
        : `${hours} hours ago`;
  } else if (difference < 2620800) {
    const days = Math.floor(difference / 86400);
    output = abbreviated
      ? `${days}d`
      : days === 1
        ? "1 day ago"
        : `${days} days ago`;
  } else if (difference < 31449600) {
    const months = Math.floor(difference / 2620800);
    output = abbreviated
      ? `${months}mo`
      : months === 1
        ? "1 month ago"
        : `${months} months ago`;
  } else {
    const years = Math.floor(difference / 31449600);
    output = abbreviated
      ? `${years}y`
      : years === 1
        ? "1 year ago"
        : `${years} years ago`;
  }
  return output;
};

export const secondsToHumanDuration = (seconds: number) => {
  if (seconds < 60) {
    return "< 1 min";
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  if (hours > 0) {
    return `${hours} hr ${minutes} min`;
  }

  return `${minutes} min`;
};
