import React, { forwardRef, useImperativeHandle, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  Button,
  Input,
  Label,
} from "@kino/ui";
import { Plus, X } from "lucide-react";
import { useSyncOrganization } from "@/store/organizationStore";
import { toast } from "sonner";
import { queryClient, useClient } from "@/hooks/useClient";
import { useHotkeys } from "react-hotkeys-hook";
import { components } from "@/openapi-bindings/v2";

type CreateTag = components["schemas"]["CreateTag"];
type CreateTagsResponse = components["schemas"]["CreateTagsResponse"];

interface TagEntry extends CreateTag {
  value: string;
  tag_type: string;
  color?: string;
  user_id?: string | null;
}

interface AddTagsDialogProps {
  onAddTags?: (response: CreateTagsResponse) => void;
}

export const AddTagsDialog = forwardRef<
  { setOpen: (open: boolean) => void },
  AddTagsDialogProps
>(({ onAddTags }, ref) => {
  const orgStore = useSyncOrganization();
  const { apiClient } = useClient();
  const [open, setOpen] = useState(false);
  const [tagEntries, setTagEntries] = useState<TagEntry[]>([
    {
      value: "",
      tag_type: "",
      color: "",
    },
  ]);

  useImperativeHandle(ref, () => ({
    setOpen,
  }));

  const createTags = apiClient.useMutation(
    "post",
    "/tags",
    {
      onSuccess: (response: CreateTagsResponse) => {
        queryClient.invalidateQueries({
          queryKey: [
            "get",
            "/tags",
            {
              params: {
                query: {
                  organization_id: orgStore.getEffectiveOrganizationId(),
                },
              },
            },
          ],
        });

        const successCount = response.successful_tags?.length ?? 0;
        const failureCount = response.failed_tags?.length ?? 0;

        if (successCount > 0 && failureCount === 0) {
          toast.success(`Successfully created ${successCount} tags`);
        } else if (successCount > 0 && failureCount > 0) {
          toast.warning(
            `Created ${successCount} tags, but ${failureCount} failed`,
          );
        } else {
          toast.error("Failed to create any tags");
        }

        onAddTags?.(response);
      },
      onError: (error) => {
        console.error("Error creating tags:", error);
        toast.error("Failed to create tags");
      },
    },
    queryClient,
  );

  const handleSubmit = async (e?: React.FormEvent) => {
    e?.preventDefault();

    const validTags = tagEntries.filter(
      (tag) => tag.value.trim() && tag.tag_type.trim(),
    );

    if (validTags.length === 0) {
      toast.error("Please fill in at least one tag");
      return;
    }

    try {
      await createTags.mutateAsync({
        body: {
          organization_id: orgStore.getEffectiveOrganizationId(),
          tags: validTags.map((tag) => ({
            value: tag.value.trim(),
            tag_type: tag.tag_type.trim(),
            color: tag.color?.trim() || undefined,
          })),
        },
      });

      setTagEntries([{ value: "", tag_type: "", color: "" }]);
      setOpen(false);
    } catch (error) {
      // Error handling is done in mutation callbacks
    }
  };

  const addNewTagEntry = () => {
    setTagEntries([
      ...tagEntries,
      {
        value: "",
        tag_type: "",
        color: "",
      },
    ]);
  };

  const removeTagEntry = (index: number) => {
    if (tagEntries.length > 1) {
      setTagEntries(tagEntries.filter((_, i) => i !== index));
    }
  };

  const updateTagEntry = (
    index: number,
    field: keyof TagEntry,
    value: string,
  ) => {
    const newEntries = [...tagEntries];
    const updatedEntry = { ...newEntries[index] };
    updatedEntry[field] = value;
    newEntries[index] = updatedEntry as TagEntry;
    setTagEntries(newEntries);
  };

  useHotkeys("c", () => setOpen(true), { preventDefault: true });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Create New Tags</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-6 pt-4">
          {tagEntries.map((tag, index) => (
            <div
              key={index}
              className="relative flex items-start gap-4 rounded-lg border border-neutral-800 p-4"
            >
              <div className="flex-1 space-y-4">
                <div className="space-y-2">
                  <Label htmlFor={`type-${index}`}>Type</Label>
                  <Input
                    id={`type-${index}`}
                    value={tag.tag_type}
                    onChange={(e) =>
                      updateTagEntry(index, "tag_type", e.target.value)
                    }
                    placeholder="Enter tag type (ex: 'Shot', 'Camera ID', 'Location')"
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor={`value-${index}`}>Value</Label>
                  <Input
                    id={`value-${index}`}
                    value={tag.value}
                    onChange={(e) =>
                      updateTagEntry(index, "value", e.target.value)
                    }
                    placeholder="Enter tag value (ex: 'A', 'Nighttime', '3', 'NIKON')"
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor={`color-${index}`}>
                    Color{" "}
                    <span className="text-muted-foreground">(optional)</span>
                  </Label>
                  <Input
                    id={`color-${index}`}
                    value={tag.color || ""}
                    onChange={(e) =>
                      updateTagEntry(index, "color", e.target.value)
                    }
                    placeholder="Enter color (e.g., red, #FF0000)"
                  />
                </div>
              </div>
              {tagEntries.length > 1 && (
                <Button
                  type="button"
                  variant="ghost"
                  size="icon"
                  className="absolute right-2 top-2"
                  onClick={() => removeTagEntry(index)}
                >
                  <X className="h-4 w-4" />
                </Button>
              )}
            </div>
          ))}

          <div className="flex justify-between">
            <Button
              type="button"
              variant="outline"
              onClick={addNewTagEntry}
              className="gap-2"
            >
              <Plus className="h-4 w-4" /> Add Another Tag
            </Button>
            <Button type="submit" disabled={!tagEntries.some((t) => t.value)}>
              Create Tags
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
});

AddTagsDialog.displayName = "AddTagsDialog";
