import {
  COMMON_TAG_DEFINITIONS,
  DEFAULT_TAG_DEFINITION,
  S48_TAG_DEFINITIONS,
  TagTypeDefinition,
  TMZ_TAG_DEFINITIONS,
  TAG_DEFINITIONS,
} from "@/utils/constants/tags";
import {
  useDisplayStore,
  getEffectiveMode,
} from "@/store/display/displayStore";
import { match } from "ts-pattern";
import { components } from "@/openapi-bindings/v2";

export const useDisplayTags = () => {
  const mode = useDisplayStore(getEffectiveMode);

  const getTagTypeDefinition = (tagType: string): TagTypeDefinition => {
    const upperTagType = tagType.toUpperCase();

    return match(mode)
      .with("s48", () => {
        return TAG_DEFINITIONS.S48[upperTagType] || DEFAULT_TAG_DEFINITION;
      })
      .with("news", () => {
        return TAG_DEFINITIONS.TMZ[upperTagType] || DEFAULT_TAG_DEFINITION;
      })
      .otherwise(() => {
        return TAG_DEFINITIONS.COMMON[upperTagType] || DEFAULT_TAG_DEFINITION;
      });
  };

  const orderTags = (
    tags: components["schemas"]["Tag"][],
    includeHidden: boolean = false,
  ): components["schemas"]["Tag"][] => {
    const tagDefinitions = match(mode)
      .with("s48", () => S48_TAG_DEFINITIONS)
      .with("news", () => TMZ_TAG_DEFINITIONS)
      .otherwise(() => COMMON_TAG_DEFINITIONS);

    const visibleTags = includeHidden
      ? tags
      : tags.filter((tag) => {
          const def = tagDefinitions[tag.tag_type.toUpperCase()];
          return !def?.hidden;
        });

    const tagOrder = new Map(
      Object.keys(tagDefinitions)
        .filter((key) => includeHidden || !tagDefinitions[key]?.hidden)
        .map((key, index) => [key, index]),
    );

    return [...visibleTags].sort((a, b) => {
      const aType = a.tag_type.toUpperCase();
      const bType = b.tag_type.toUpperCase();

      const aIndex = tagOrder.get(aType) ?? Number.MAX_SAFE_INTEGER;
      const bIndex = tagOrder.get(bType) ?? Number.MAX_SAFE_INTEGER;

      return aIndex - bIndex;
    });
  };

  return { orderTags, getTagTypeDefinition };
};
