import {
  GetContextMenuItems,
  GetContextMenuItemsParams,
  MenuItemDef,
} from "ag-grid-community";
import { useClient, queryClient } from "@/hooks/useClient";
import { toast } from "sonner";
import { formatStringPlurality } from "@/utils/stringUtils";
import { useSyncOrganization } from "@/store/organizationStore";
import { useRouteParams } from "../useURLParams";
import { components } from "@/openapi-bindings/v2";
import { useSummaryGenerationStore } from "@/store/processing/summaryGenerationStore";
import { useDebuggerStore } from "@/store/debuggerStore";
import { useSelectedMediaStore } from "@/store/selectedMediaStore";
import { useCanAccessDevFeatures } from "../useCanAccessDevFeatures";
import { Skeleton } from "@kino/ui";
import { useGetTagsQuery, groupTagsByType } from "@/hooks/useGetTagsQuery";
import { usePlaylistMoments, usePlaylists } from "../usePlaylists";

const GenerationLoadingToastContents = ({
  fileCount,
  summarizationPrompt,
}: {
  fileCount: number;
  summarizationPrompt: string;
}) => {
  const canAccessDevFeatures = useCanAccessDevFeatures();
  return (
    <div className="flex flex-col items-start gap-4">
      <div className="flex items-center gap-2">
        <Skeleton className="loading-dot" />
        <div className="font-bold">
          {`Processing summaries for ${fileCount} ${formatStringPlurality(
            fileCount,
            "file",
            "files",
          )}...`}
        </div>
      </div>
      {canAccessDevFeatures && (
        <div className="flex flex-col items-start gap-2">
          <div className="font-bold text-neutral-500">Prompt:</div>
          <div className="rounded border bg-neutral-800 p-2 font-mono text-neutral-400">
            {summarizationPrompt}
          </div>
        </div>
      )}
    </div>
  );
};

interface UseGetContextMenuItemsProps {
  onCreateTagClick: () => void;
  onCreatePlaylistClick: () => void;
}

export const useGetContextMenuItems = ({
  onCreateTagClick,
  onCreatePlaylistClick,
}: UseGetContextMenuItemsProps) => {
  const orgStore = useSyncOrganization();
  const { apiClient } = useClient();
  const routeParams = useRouteParams();
  const { summarizationPrompt } = useDebuggerStore((state) => ({
    summarizationPrompt: state.summarizationPrompt,
  }));
  const { data: tags = [] } = useGetTagsQuery();
  const showPlaylists = useDebuggerStore((state) => state.showPlaylists);
  const { data: playlistResponse } = usePlaylists();
  const playlists = playlistResponse?.playlists ?? [];
  const { addMoments, removeMoments } = usePlaylistMoments();

  const getPlaylistSubmenuItems = (
    playlistMoment: components["schemas"]["PlaylistMoment"],
  ): (MenuItemDef | string)[] => {
    if (!showPlaylists) return [];
    const menuItems: (MenuItemDef | string)[] = [
      {
        name: "Create new playlist...",
        icon: '<span class="ag-icon ag-icon-plus"></span>',
        action: onCreatePlaylistClick,
      },
      "separator",
    ];

    playlists.forEach(({ title, id, moments }) => {
      const isInPlaylist = moments?.some(
        (moment) => moment.moment_id === playlistMoment.moment_id,
      );

      menuItems.push({
        name: title,
        icon: isInPlaylist
          ? '<span class="ag-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-4 h-4"><path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" /></svg></span>'
          : '<span class="w-4 h-4"></span>',
        action: () => {
          if (isInPlaylist) {
            removeMoments({
              playlist_id: id,
              moment_ids: [playlistMoment.moment_id],
            });
          } else if (playlistMoment) {
            addMoments({
              playlist_id: id,
              moments: [
                {
                  media_item_id: playlistMoment.media_item_id,
                  start_time: playlistMoment.start_time,
                  end_time: playlistMoment.end_time,
                  position: playlistMoment.position,
                },
              ],
            });
          }
        },
      });
    });
    return menuItems;
  };

  const deleteMediaMutation = apiClient.useMutation("delete", "/media", {
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [
          "get",
          "/all_media",
          {
            params: {
              query: {
                organization_id: orgStore.getEffectiveOrganizationId(),
                limit: 500,
                person_ids: routeParams.params.person_ids,
                tag_ids: routeParams.params.tag_ids,
              },
            },
          },
        ],
      });
    },
  });

  const handleDelete = async (mediaIds: string[]) => {
    if (!mediaIds.length || !orgStore.getEffectiveOrganizationId()) return;

    try {
      const response = await deleteMediaMutation.mutateAsync({
        body: {
          media_ids: mediaIds,
          organization_id: orgStore.getEffectiveOrganizationId(),
        },
      });

      // If media is deleted, remove it from the selected media store
      useSelectedMediaStore.getState().removeMediaByIds(mediaIds);

      const successCount = response.successful_deletions?.length ?? 0;
      const failureCount = response.failed_deletions?.length ?? 0;

      if (successCount > 0 && failureCount === 0) {
        toast.success(
          `Successfully deleted ${successCount} ${formatStringPlurality(successCount, "item", "items")}`,
        );
      } else if (successCount > 0 && failureCount > 0) {
        toast.warning(
          `Partially successful: ${successCount} ${formatStringPlurality(successCount, "item", "items")} deleted, ${failureCount} failed`,
        );
        response.failed_deletions?.forEach((failure) => {
          console.error(
            `Failed to delete ${failure.media_id}: ${failure.error}`,
          );
        });
      } else if (failureCount > 0) {
        const errors = response.failed_deletions
          ?.map((failure) => failure.error)
          .join(", ");
        toast.error(`Failed to delete items: ${errors}`);
      }
    } catch (error) {
      console.error("Error deleting media:", error);
      toast.error("Failed to delete media");
    }
  };

  const generateSummaryMutation = apiClient.useMutation(
    "post",
    "/summarize",
    {},
    queryClient,
  );

  const generateSummaries = async (
    inputs: components["schemas"]["MediaItem"][],
  ) => {
    const inputJson: Record<string, string> = {};
    const mediaIds: string[] = [];

    if (!summarizationPrompt) {
      toast.error("No summarization prompt set");
      return;
    }

    for (const input of inputs) {
      inputJson[input.id] = summarizationPrompt;
      mediaIds.push(input.id);
    }

    const fileCount = mediaIds.length;
    if (!orgStore.getEffectiveOrganizationId() || fileCount === 0) {
      toast.error("No valid files selected for summary generation");
      return;
    }

    useSummaryGenerationStore.getState().startGeneration(mediaIds);

    toast.promise(
      (async () => {
        const response = await generateSummaryMutation.mutateAsync({
          body: {
            input_json: inputJson,
            organization_id: orgStore.getEffectiveOrganizationId(),
            save_to_csv: true,
            by_media_id: true,
          },
        });

        if (!response.run_uuid) {
          mediaIds.forEach((id) => {
            useSummaryGenerationStore
              .getState()
              .updateStatus(id, "failed", "Invalid response");
          });
          throw new Error("Invalid response: missing run_uuid");
        }

        const successCount = response.summary_output_json
          ? Object.keys(response.summary_output_json).length
          : 0;
        const failureCount = fileCount - successCount;

        mediaIds.forEach((id) => {
          if (successCount > 0) {
            useSummaryGenerationStore.getState().updateStatus(id, "completed");
          } else {
            useSummaryGenerationStore
              .getState()
              .updateStatus(id, "failed", "Generation failed");
          }
        });

        await queryClient.invalidateQueries({
          queryKey: [
            "get",
            "/all_media",
            {
              params: {
                query: {
                  organization_id: orgStore.getEffectiveOrganizationId(),
                  limit: 500,
                  person_ids: routeParams.params.person_ids,
                  tag_ids: routeParams.params.tag_ids,
                },
              },
            },
          ],
        });

        return { successCount, failureCount };
      })(),
      {
        loading: (
          <GenerationLoadingToastContents
            fileCount={fileCount}
            summarizationPrompt={summarizationPrompt ?? ""}
          />
        ),
        success: ({ successCount, failureCount }) => {
          if (successCount > 0 && failureCount === 0) {
            return `Successfully processed ${successCount} ${formatStringPlurality(
              successCount,
              "summary",
              "summaries",
            )}`;
          } else if (successCount > 0 && failureCount > 0) {
            return `Partially successful: ${successCount} processed, ${failureCount} failed`;
          } else {
            throw new Error("Failed to process any summaries");
          }
        },
        error: (error) =>
          `Failed to process summaries: ${
            error instanceof Error ? error.message : "Unknown error"
          }`,
      },
    );
  };

  // Add tag assignment mutation
  const assignTagMutation = apiClient.useMutation(
    "post",
    "/tags/assignments",
    {
      onSuccess: (response: components["schemas"]["TagAssignmentResponse"]) => {
        queryClient.invalidateQueries({
          queryKey: [
            "get",
            "/all_media",
            {
              params: {
                query: {
                  organization_id: orgStore.getEffectiveOrganizationId(),
                  limit: 500,
                  person_ids: routeParams.params.person_ids,
                  tag_ids: routeParams.params.tag_ids,
                },
              },
            },
          ],
        });

        const successCount = response.assignments?.length ?? 0;
        const failureCount = response.failed_assignments?.length ?? 0;

        if (successCount > 0 && failureCount === 0) {
          toast.success(`Successfully assigned tag to ${successCount} items`);
        } else if (successCount > 0 && failureCount > 0) {
          toast.warning(
            `Partially successful: ${successCount} assigned, ${failureCount} failed`,
          );
        } else {
          toast.error(response.message || "Failed to assign any tags");
        }
      },
      onError: (error) => {
        console.error("Failed to assign tag:", error);
        toast.error("Failed to assign tag");
      },
    },
    queryClient,
  );

  const handleAssignTag = async (mediaIds: string[], tagId: string) => {
    try {
      await assignTagMutation.mutateAsync({
        body: {
          organization_id: orgStore.getEffectiveOrganizationId(),
          assignments: mediaIds.map((mediaId) => ({
            media_id: mediaId,
            tag_definition_id: tagId,
          })),
        },
      });
    } catch (error) {
      console.error("Error assigning tag:", error);
    }
  };

  const getSingleRowMenuItems = (
    params: GetContextMenuItemsParams,
  ): (string | MenuItemDef)[] => {
    const menuItems: (string | MenuItemDef)[] = [
      {
        name: "Process logs for row",
        action: () => {
          if (params.node?.data) {
            generateSummaries([params.node?.data]);
          }
        },
      },
      {
        name: "Delete row",
        action: () => {
          if (params.node?.data?.id) {
            handleDelete([params.node.data.id]);
          }
        },
      },
      "separator",
    ];

    if (showPlaylists && params.node?.data) {
      const playlistMoment = {
        moment_id: params.node.data.id,
        media_item_id: params.node.data.id,
        start_time: 0,
        end_time: params.node.data.ffprobe_data.duration,
        position: 0,
      };

      menuItems.push({
        name: "Add to playlist...",
        icon: '<span class="ag-icon ag-icon-playlist"></span>',
        subMenu: getPlaylistSubmenuItems(playlistMoment),
      });
      menuItems.push("separator");
    }

    menuItems.push({
      name: "Assign tag...",
      icon: '<span class="ag-icon ag-icon-tag"></span>',
      subMenu: params.node?.data?.id
        ? getTagSubmenuItems([params.node.data.id])
        : [],
    });

    return menuItems;
  };

  const getMultiRowMenuItems = (
    rows: components["schemas"]["MediaItem"][],
  ): (string | MenuItemDef)[] => [
    {
      name: `Process logs for ${rows.length} ${formatStringPlurality(
        rows.length,
        "row",
        "rows",
      )}`,
      icon: '<span class="ag-icon ag-icon-function"></span>',
      action: () => generateSummaries(rows),
    },
    {
      name: `Delete ${rows.length} ${formatStringPlurality(
        rows.length,
        "row",
        "rows",
      )}`,
      icon: '<span class="ag-icon ag-icon-remove"></span>',
      action: () => {
        const mediaIds = rows.map((row) => row.id).filter(Boolean);
        if (mediaIds.length > 0) {
          handleDelete(mediaIds);
        }
      },
    },
    "separator",
    {
      name: `Assign tag to ${rows.length} ${formatStringPlurality(
        rows.length,
        "row",
        "rows",
      )}...`,
      icon: '<span class="ag-icon ag-icon-tag"></span>',
      subMenu: getTagSubmenuItems(rows.map((row) => row.id)),
    },
  ];

  const getTagSubmenuItems = (mediaIds: string[]): (MenuItemDef | string)[] => {
    // Group tags by type and get top used ones
    const groupedTags = groupTagsByType(tags);
    const menuItems: (string | MenuItemDef)[] = [
      {
        name: "Create new tag...",
        icon: '<span class="ag-icon ag-icon-plus"></span>',
        action: onCreateTagClick,
      },
      "separator",
    ];

    // Add tag groups
    Object.entries(groupedTags).forEach(([tagType, tagsInType]) => {
      // Add tags under this type
      tagsInType
        .sort((a, b) => (b.usage_count || 0) - (a.usage_count || 0))
        .slice(0, 5) // Show top 5 most used tags per type
        .forEach((tag) => {
          menuItems.push({
            name: `<span class="text-neutral-500 text-xs">${tagType}</span> ${tag.value}`,
            icon: '<span class="ag-icon ag-icon-tag"></span>',
            action: () => handleAssignTag(mediaIds, tag.id),
            tooltip: `Used ${tag.usage_count} times`,
          });
        });

      // Add separator between tag types
      menuItems.push("separator");
    });

    // Remove the last separator
    if (menuItems[menuItems.length - 1] === "separator") {
      menuItems.pop();
    }

    return menuItems;
  };

  const getContextMenuItems: GetContextMenuItems<
    components["schemas"]["MediaItem"]
  > = (
    params: GetContextMenuItemsParams<components["schemas"]["MediaItem"]>,
  ): (string | MenuItemDef)[] => {
    const selectedRows = params.api.getSelectedRows();
    const currentData = params.node?.data;

    // TODO: DefaultItem is the type name but ag-grid doesn't export it
    const baseItems: (string | MenuItemDef)[] = [];

    if (selectedRows.length > 0) {
      return [...baseItems, ...getMultiRowMenuItems(selectedRows)];
    }

    if (currentData) {
      return [...baseItems, ...getSingleRowMenuItems(params)];
    }

    return baseItems;
  };

  return { getContextMenuItems };
};
