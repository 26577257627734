import { getTimeAgo } from "@/utils/time";
import { formatStringPlurality } from "@/utils/stringUtils";
import { cn } from "@kino/ui";
import { useState } from "react";
import { useMediaRemote, ZOOM_EVENT_TYPE } from "@kino/player";
import { usePrimaryViewportContext } from "@kino/player";
import { useCommentReplyStore } from "@/store/collaboration/commentReplyStore";
import { HydratedComment } from "@/hooks/useGetMediaComments";
import { useCommentInputContext } from "@/context/CommentInputContext";
import { TimeRange } from "./TimeRange";
import { UserBadge } from "@/components/shared/UserBadge";

interface ReplySectionProps {
  replyCount: number;
  showReplies: boolean;
  setShowReplies: (show: boolean) => void;
  handleReplyClick: (e: React.MouseEvent) => void;
}

const ReplySection = ({
  replyCount,
  showReplies,
  setShowReplies,
  handleReplyClick,
}: ReplySectionProps) => (
  <div className="flex items-center gap-2">
    <button
      onClick={(e) => {
        e.stopPropagation();
        setShowReplies(true);
        handleReplyClick(e);
      }}
      className="w-fit text-neutral-500 transition-colors hover:text-neutral-400"
    >
      Reply
    </button>
    {replyCount > 0 && (
      <button
        onClick={(e) => {
          e.stopPropagation();
          setShowReplies(!showReplies);
        }}
        className="flex items-center gap-1 text-indigo-500 transition-colors hover:text-indigo-400"
      >
        {showReplies ? "Hide" : "View"} {replyCount}{" "}
        {formatStringPlurality(replyCount, "reply", "replies")}
      </button>
    )}
  </div>
);

interface CommentItemProps {
  comment: HydratedComment;
  isReply?: boolean;
}

const CommentItem = ({ comment, isReply = false }: CommentItemProps) => {
  const [showReplies, setShowReplies] = useState(false);
  const replyCount = comment.replies?.length ?? 0;
  const { playerRef } = usePrimaryViewportContext();
  const { commentTextAreaRef: commentInputRef } = useCommentInputContext();

  const remote = useMediaRemote(playerRef);

  const handleJumpToSegment = () => {
    remote.seek(comment.startTime, new Event(ZOOM_EVENT_TYPE));
  };

  const { setReplyTo, replyToId } = useCommentReplyStore((state) => ({
    setReplyTo: state.setReplyTo,
    replyToId: state.replyToId,
  }));

  const handleReplyClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setReplyTo(comment, comment.clerkFirstname ?? "");
    setShowReplies(true);
    commentInputRef.current?.focus();
  };

  return (
    <>
      <div
        className={cn(
          "group relative flex w-full flex-col gap-2 p-2 text-xs hover:bg-neutral-800/60",
          isReply ? "pl-8" : "",
          replyToId === comment.rootCommentId &&
            "bg-indigo-500/10 hover:bg-indigo-500/20",
          replyToId === comment.rootCommentId &&
            comment.id !== comment.rootCommentId &&
            "bg-indigo-500/5 hover:bg-indigo-500/10",
        )}
        onClick={handleJumpToSegment}
      >
        <div className="flex items-center gap-1">
          <UserBadge
            imageUrl={comment.clerkImageUrl}
            firstName={comment.clerkFirstname ?? undefined}
          />
          {!isReply && (
            <TimeRange
              startTime={comment.startTime}
              endTime={comment.endTime}
            />
          )}
          <span className="text-neutral-500">•</span>
          <div className="text-neutral-500">
            {getTimeAgo(comment.updated_at)}
          </div>
          {comment.updated_at > comment.created_at && (
            <>
              <span className="text-neutral-500">•</span>
              <div className="text-neutral-500">Edited</div>
            </>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex text-neutral-400">{comment.text}</div>
          <ReplySection
            replyCount={replyCount}
            showReplies={showReplies}
            setShowReplies={setShowReplies}
            handleReplyClick={handleReplyClick}
          />
        </div>
      </div>
      {showReplies &&
        comment.replies?.map((reply) => (
          <CommentItem key={reply.id} comment={reply} isReply={true} />
        ))}
    </>
  );
};

export { CommentItem };
