import React, {
  forwardRef,
  MouseEventHandler,
  useCallback,
  useState,
} from "react";
import {
  BarChartIcon,
  BoxIcon,
  FileVideo2,
  LucideProps,
  VideoIcon,
} from "lucide-react";
import { TailwindColor } from "@/types/tailwind";
import { cn } from "@/utils/tailwind";
import {
  Gauge,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Breakdown,
  ScrollArea,
} from "@kino/ui";
import { useSeasonStats } from "@/hooks/useSeasonStats";
import Loading from "@/layouts/Loading";
import { SeasonStats } from "@/components/analytics/SeasonStats";
import { SeasonStatsRecord } from "@/types/season-stats";
import {
  getEffectiveMode,
  useDisplayStore,
} from "@/store/display/displayStore";
import { Navigate } from "react-router";
import { useSyncOrganization } from "@/store/organizationStore";
import { DisplayPopover } from "@/components/display/DisplayPopover";
import { SURVIVOR } from "@/utils/constants/organizations";
import { formatSize } from "@/utils/ffprobe";
import { useAllMedia } from "@/hooks/useAllMediaQuery";
import { useActiveServerStore } from "@/store/activeServerStore";
import { useSelectedMediaStore } from "@/store/selectedMediaStore";
import { useLayoutStore } from "@/store/layoutStore";

type OverviewCardProps = {
  color: TailwindColor;
  Icon: React.ForwardRefExoticComponent<
    Omit<LucideProps, "ref"> & React.RefAttributes<SVGSVGElement>
  >;
  title: string;
  children: React.ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  isOpen?: boolean;
};

const getIconContainerStyleFromColor = (color: string) =>
  `border-${color}-400/25 bg-${color}-400/10`;
const getTextStyleFromColor = (color: string) => `text-${color}-200`;
const getProgressStyleFromColor = (color: string) => `text-${color}-500`;

const SquareIcon: React.FC<{
  color: TailwindColor;
  Icon: React.ForwardRefExoticComponent<
    Omit<LucideProps, "ref"> & React.RefAttributes<SVGSVGElement>
  >;
}> = ({ color, Icon }) => {
  return (
    <div
      className={cn(
        "h-fit w-fit rounded border border-solid p-1",
        getIconContainerStyleFromColor(color),
      )}
    >
      <Icon className={cn("h-3 w-3", getTextStyleFromColor(color))} />
    </div>
  );
};

interface OverviewContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

const OverviewContainer = ({
  children,
  className,
  ...props
}: OverviewContainerProps) => {
  return (
    <div
      className={cn(
        "flex min-h-24 w-full overflow-hidden rounded border border-solid border-neutral-800 bg-neutral-800/30",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};

const OverviewCard = forwardRef<HTMLButtonElement, OverviewCardProps>(
  ({ color, Icon, title, children, onClick, isOpen }, ref) => {
    return (
      <button
        ref={ref}
        onClick={onClick}
        className={cn("block w-full", isOpen && "bg-neutral-800/50")}
      >
        <OverviewContainer className="h-32">
          <div className="flex w-full flex-col">
            <div
              className={cn(
                "flex h-fit w-full items-center gap-2 bg-neutral-900 p-2.5",
                isOpen && "bg-neutral-900/50",
              )}
            >
              <SquareIcon color={color} Icon={Icon} />
              <p
                className={cn(
                  "text-xs font-medium",
                  getTextStyleFromColor(color),
                )}
              >
                {title}
              </p>
            </div>
            <div className="flex h-full w-full items-center justify-between overflow-hidden border-t border-t-neutral-800 bg-neutral-800/30 px-6 py-2">
              {children}
            </div>
          </div>
        </OverviewContainer>
      </button>
    );
  },
);

const LoadingCard = () => (
  <OverviewContainer>
    <Loading className="min-h-0 w-full" />
  </OverviewContainer>
);

const MemoizedSeasonStats = React.memo(SeasonStats);
MemoizedSeasonStats.displayName = "MemoizedSeasonStats";

const testData = [
  {
    volume_name: "S48_MEDIA_1",
    volume_file_count: 161539,
    total_size: 30379071597603,
  },
  {
    volume_name: "S48_MEDIA_2",
    volume_file_count: 200506,
    total_size: 30385226078970,
  },
  {
    volume_name: "S48_MEDIA_3",
    volume_file_count: 36722,
    total_size: 13632353496626,
  },
  {
    volume_name: "S48_MEDIA_4",
    volume_file_count: 105175,
    total_size: 20054722924919,
  },
  {
    volume_name: "S48_MEDIA_5",
    volume_file_count: 2294,
    total_size: 722391502134,
  },
  {
    volume_name: "S48_MEDIA_6",
    volume_file_count: 3651,
    total_size: 405688360291,
  },
  {
    volume_name: "S48_MEDIA_7",
    volume_file_count: 934,
    total_size: 3642871656422,
  },
  {
    volume_name: "S48_MEDIA_8",
    volume_file_count: 180,
    total_size: 2054277877812,
  },
];

const breakdownTestData = {
  total: testData.reduce((acc, curr) => acc + curr.total_size, 0),
  data: testData.map(({ volume_name, total_size }) => ({
    name: volume_name,
    label: "Avid Nexis",
    value: total_size,
  })),
};

const ProcessedStatsCard = () => {
  const color = "green";
  const gaugeColor = `text-${color}-500`;

  const { seasonStats, isLoading } = useSeasonStats();

  const totalProcessedVideos = Object.values(seasonStats || {}).reduce(
    (total, stats) => total + stats.processed_videos,
    0,
  );
  const totalNumVideos = Object.values(seasonStats || {}).reduce(
    (total, stats) => total + stats.num_videos,
    0,
  );

  const [isOpen, setIsOpen] = useState(false);
  const handleOpenChange = useCallback((open: boolean) => {
    setIsOpen(open);
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if (!seasonStats) {
    return (
      <OverviewCard color={color} Icon={BarChartIcon} title="Media Processing">
        <div className="w-full text-xs text-neutral-700">
          No data available.
        </div>
      </OverviewCard>
    );
  }

  return (
    <Popover modal={true} open={isOpen} onOpenChange={handleOpenChange}>
      <PopoverTrigger asChild>
        <OverviewCard
          color={color}
          Icon={BarChartIcon}
          title="Media Processing Statistics"
          isOpen={isOpen}
        >
          <div className="flex gap-4">
            <div className="flex flex-col items-center justify-between gap-1.5 rounded-sm p-2">
              <Gauge
                value={totalProcessedVideos}
                max={totalNumVideos}
                size="base"
                showValue={true}
                showPercent
                classNameProgress={gaugeColor}
              />
            </div>

            <div className="flex flex-col items-center justify-center">
              <div className="flex gap-1 text-xl font-semibold text-white">
                <h1>{totalProcessedVideos}</h1>
                <h1>/</h1>
                <h1>{totalNumVideos}</h1>
              </div>
              <div className="w-full text-start text-xs font-medium text-neutral-600">
                Processed
              </div>
            </div>
          </div>
        </OverviewCard>
      </PopoverTrigger>
      <PopoverContent
        className="h-96 w-96 overflow-hidden p-0"
        avoidCollisions
        side="left"
        align="start"
        onOpenAutoFocus={(event) => {
          event.preventDefault();
          if (event.target) {
            (event.target as HTMLElement).focus();
          }
        }}
      >
        <MemoizedSeasonStats seasonStats={seasonStats} isLoading={isLoading} />
      </PopoverContent>
    </Popover>
  );
};

export const Thumbnail: React.FC<{ img: string }> = ({ img }) => {
  const [hasError, setHasError] = useState(false);

  const Fallback = () => (
    <div className="flex aspect-video h-full w-full items-center justify-center rounded-sm border border-solid border-neutral-400/10 bg-neutral-400/10 text-neutral-300/50">
      <VideoIcon size={12} />
    </div>
  );

  if (!img || hasError) {
    return <Fallback />;
  }

  return (
    <img
      src={img}
      alt="thumbnail"
      className="object-fit rounded-sm"
      onError={() => setHasError(true)}
    />
  );
};

const RecentlyAddedMediaCard = () => {
  const { data: media, isLoading } = useAllMedia();
  const { buildServerUrl } = useActiveServerStore();
  const { setCurrentSelection: setSelectedMedia } = useSelectedMediaStore();
  const { set: setLayoutState, isInspectorOpen } = useLayoutStore();

  if (isLoading) {
    return <LoadingCard />;
  }

  return (
    <OverviewContainer>
      <ScrollArea orientation="horizontal">
        {media &&
          media?.media_items.length > 0 &&
          media?.media_items.slice(17, 32).map((item, i) => (
            <div
              className="flex-none cursor-pointer rounded-sm"
              key={i}
              onClick={() => {
                !isInspectorOpen && setLayoutState("isInspectorOpen", true);
                setSelectedMedia([
                  {
                    mediaItem: item,
                    activeInspectorMoment: undefined,
                    id: item.id,
                  },
                ]);
              }}
            >
              <Thumbnail
                img={buildServerUrl(item.key_thumbnail_path ?? "")}
                key={i}
              />
            </div>
          ))}
        {!media?.media_items ||
          (media?.media_items.length === 0 && (
            <div className="flex w-full items-center justify-center gap-2 p-4 text-xs text-neutral-500">
              No videos found
            </div>
          ))}
      </ScrollArea>
    </OverviewContainer>
  );
};

const OverviewHeader = React.memo(() => (
  <div className="relative flex h-11 max-h-11 min-h-11 w-full items-center justify-end gap-2 p-2">
    <DisplayPopover variant="overview" />
  </div>
));

const OverviewPage: React.FC = () => {
  const { getEffectiveOrganizationId } = useSyncOrganization();
  const effectiveMode = useDisplayStore(getEffectiveMode);

  if (getEffectiveOrganizationId() !== SURVIVOR) {
    return <Navigate to="/media" replace />;
  }

  if (effectiveMode !== "s48") {
    return (
      <div className="flex h-full w-full items-center justify-center text-neutral-600">
        No data available.
      </div>
    );
  }

  return (
    <div className="flex h-full w-full flex-col divide-y">
      <OverviewHeader />
      <div className="flex h-full w-full flex-col gap-3 p-3">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <SquareIcon color="neutral" Icon={BoxIcon} />
            <h1 className="text-xs">File Breakdown</h1>
          </div>

          <Breakdown
            data={breakdownTestData.data}
            total={breakdownTestData.total}
            format={formatSize}
          />
        </div>

        <div className="flex w-full">
          <ProcessedStatsCard />
        </div>

        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <SquareIcon Icon={FileVideo2} color="indigo" />
            <h1 className="text-xs">Recently Added Media (12)</h1>
          </div>
          <RecentlyAddedMediaCard />
        </div>
      </div>
    </div>
  );
};

export default OverviewPage;
