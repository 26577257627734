import { components } from "@/openapi-bindings/v2";

type OriginalSegment = components["schemas"]["Segment"];

export interface ProcessedSegment extends OriginalSegment {
  isFiller: boolean;
}

export interface ProcessResult {
  segments: ProcessedSegment[];
  problematicSegments: { id: string; reason: string }[];
}

function isValidSegment(
  segment: OriginalSegment,
  prevSegment: OriginalSegment | null | undefined,
  problematicSegments: { id: string; reason: string }[],
): boolean {
  // Check if segment has valid start and end times
  if (segment.start >= segment.end) {
    console.error(
      `Removing segment due to invalid timing: start (${segment.start}) >= end (${segment.end}) for segment ${segment.id}`,
    );
    problematicSegments.push({
      id: segment.id,
      reason: `Invalid timing: start (${segment.start}) >= end (${segment.end})`,
    });
    return false;
  }

  // Check if segment maintains chronological order
  if (prevSegment && segment.start < prevSegment.end) {
    console.error(
      `Removing segment due to non-linear timing: segment ${segment.id} starts at ${segment.start} which is before previous segment ends at ${prevSegment.end}`,
    );
    problematicSegments.push({
      id: segment.id,
      reason: `Non-linear timing: starts at ${segment.start} which is before previous segment ends at ${prevSegment.end}`,
    });
    return false;
  }

  return true;
}

function processSegmentsWithGaps(
  originalSegments: OriginalSegment[],
  duration?: number,
): ProcessResult {
  const problematicSegments: { id: string; reason: string }[] = [];
  const validSegments: OriginalSegment[] = [];

  originalSegments.forEach((segment, index) => {
    const prevValidSegment =
      validSegments.length > 0 ? validSegments[validSegments.length - 1] : null;
    if (isValidSegment(segment, prevValidSegment, problematicSegments)) {
      validSegments.push(segment);
    }
  });

  if (!duration) {
    return {
      segments: validSegments.map((segment) => ({
        ...segment,
        isFiller: false,
      })),
      problematicSegments,
    };
  }

  const processedSegments: ProcessedSegment[] = [];

  validSegments.forEach((segment, index) => {
    // Add filler if there's a gap
    if (index === 0) {
      // Gap at the start
      if (segment.start > 0) {
        processedSegments.push({
          ...segment,
          id: `filler-start-${segment.id}`,
          start: 0,
          end: segment.start,
          text: "...",
          speaker_id: null,
          isFiller: true,
        });
      }
    } else {
      const prevSegment = validSegments[index - 1];
      if (prevSegment && segment.start > prevSegment.end) {
        processedSegments.push({
          ...segment,
          id: `filler-${prevSegment.id}-${segment.id}`,
          start: prevSegment.end,
          end: segment.start,
          text: "...",
          speaker_id: null,
          isFiller: true,
        });
      }
    }

    // Add the actual segment
    processedSegments.push({
      ...segment,
      isFiller: false,
    });
  });

  // Add final filler if needed
  const lastValidSegment =
    validSegments.length > 0 ? validSegments[validSegments.length - 1] : null;
  if (lastValidSegment && lastValidSegment.end < duration) {
    processedSegments.push({
      ...lastValidSegment,
      id: `filler-${lastValidSegment.id}-end`,
      start: lastValidSegment.end,
      end: duration,
      text: "...",
      speaker_id: null,
      isFiller: true,
    });
  } else if (duration > 0 && !lastValidSegment) {
    // If no valid segments exist, create a single filler for the entire duration
    processedSegments.push({
      id: "filler-full",
      start: 0,
      end: duration,
      text: "...",
      isFiller: true,
      speaker_id: null,
    } as ProcessedSegment);
  }

  return {
    segments: processedSegments,
    problematicSegments,
  };
}

export const getTranscriptSegmentsByIds = (
  ids: string[],
  mediaItem: components["schemas"]["MediaItem"],
): components["schemas"]["Segment"][] => {
  const transcript = mediaItem.transcript;
  if (!transcript) {
    return [];
  }
  return transcript.segments.filter((segment) => ids.includes(segment.id));
};

export default processSegmentsWithGaps;
