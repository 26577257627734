"use client";

import React, { useState } from "react";
import { ScrollArea, Button } from "@kino/ui";
import { UploadedImage } from "./types";
import { components } from "@/openapi-bindings/v2";
import { useClient, queryClient } from "@/hooks/useClient";
import { toast } from "sonner";
import { useSyncOrganization } from "@/store/organizationStore";

interface ImageAssignmentProps {
  images: UploadedImage[];
  selectedPerson: components["schemas"]["Person"] | null;
  onAssign: (imageId: string, personId: string) => void;
}

export const ImageAssignment: React.FC<ImageAssignmentProps> = ({
  images,
  selectedPerson,
  onAssign,
}) => {
  const { apiClient } = useClient();
  const orgStore = useSyncOrganization();
  const [isUploading, setIsUploading] = useState(false);

  const uploadFaceMutation = apiClient.useMutation(
    "post",
    "/person/{person_id}/reference-face",
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [
            "get",
            "/people",
            {
              params: {
                query: {
                  organization_id: orgStore.getEffectiveOrganizationId(),
                },
              },
            },
          ],
        });
      },
    },
    queryClient,
  );

  const handleAssign = async (image: UploadedImage, personId: string) => {
    setIsUploading(true);
    try {
      const response = await fetch(image.preview);
      const blob = await response.blob();
      const file = new File([blob], "face.jpg", { type: "image/jpeg" });

      const formData = new FormData();
      formData.append("files", file);

      await uploadFaceMutation.mutateAsync({
        params: {
          path: {
            person_id: personId,
          },
          query: {
            organization_id: orgStore.getEffectiveOrganizationId(),
            set_as_avatar: false,
          },
        },
        body: formData as unknown as { files: string[] },
        headers: {
          "Content-Type": undefined,
        },
      });

      toast.success("Reference face added successfully");
      onAssign(image.id, personId);
    } catch (error) {
      console.error("Error assigning image:", error);
      toast.error("Failed to assign image");
    } finally {
      setIsUploading(false);
    }
  };

  const unassignedImages = images.filter((img) => !img.assignedPersonId);

  if (unassignedImages.length === 0) {
    return (
      <div className="text-muted-foreground flex h-full items-center justify-center">
        No images to assign
      </div>
    );
  }

  return (
    <ScrollArea className="h-full">
      <div className="grid grid-cols-4 gap-4 p-4">
        {unassignedImages.map((image) => {
          return (
            <div
              key={image.id}
              className="border-border group relative overflow-hidden rounded-lg border"
            >
              <img
                src={image.preview}
                alt="Uploaded face"
                className="aspect-square w-full object-cover"
              />
              {selectedPerson && (
                <div className="absolute inset-0 flex items-center justify-center bg-black/50 opacity-0 transition-opacity group-hover:opacity-100">
                  <Button
                    onClick={() => handleAssign(image, selectedPerson.id)}
                    variant="secondary"
                    size="sm"
                    disabled={isUploading}
                  >
                    {isUploading
                      ? "Assigning..."
                      : `Assign to ${selectedPerson.name}`}
                  </Button>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </ScrollArea>
  );
};
