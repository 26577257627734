import { components } from "@/openapi-bindings/v2";
import { FilterBadgeTag } from "../../filter/filterPanel/tags/FilterBadgeTag";
import OmniSearchCardSectionEmptyState from "@components/omniSearch/cardModules/OmniSearchCardSectionEmptyState";
import {
  Avatar,
  AvatarImage,
  AvatarFallback,
  cn,
  TooltipTrigger,
  TooltipContent,
  Tooltip,
  TooltipProvider,
} from "@kino/ui";
import { useActiveServerStore } from "@/store/activeServerStore";
import { useDisplayTags } from "@/hooks/useGetTagTypeDefinition";

interface OmniSearchCardEntitiesProps {
  people: components["schemas"]["Person"][] | undefined;
  tags: components["schemas"]["Tag"][] | undefined;
}

interface PersonAvatarProps extends React.HTMLAttributes<HTMLSpanElement> {
  person: components["schemas"]["Person"];
}

const PersonAvatar = ({ person, className, ...props }: PersonAvatarProps) => {
  const { buildServerUrl } = useActiveServerStore();
  return (
    <Avatar
      className={cn("h-5 w-5 border border-neutral-700", className)}
      {...props}
    >
      <AvatarImage src={buildServerUrl(person.thumbnail_path ?? "")} />
      <AvatarFallback className="text-[8px]">{person.name[0]}</AvatarFallback>
    </Avatar>
  );
};

export const PeopleList = ({
  people,
}: {
  people: components["schemas"]["Person"][];
}) => {
  return (
    <>
      {people.map((person, index) => (
        <TooltipProvider key={person.id}>
          <Tooltip delayDuration={50}>
            <TooltipTrigger>
              <PersonAvatar
                person={person}
                className={`${index !== 0 && "-ml-4"}`}
              />
            </TooltipTrigger>
            <TooltipContent className="p-0">
              <div className="flex items-center gap-2">
                <PersonAvatar person={person} />
                <div>{person.name.replace("_", " ")}</div>
              </div>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ))}
    </>
  );
};

export const TagsList = ({
  tags,
}: {
  tags: components["schemas"]["Tag"][];
}) => {
  const { orderTags } = useDisplayTags();
  const orderedTags = tags ? orderTags(tags) : [];
  return (
    <>
      {orderedTags.map((tag) => (
        <TooltipProvider key={tag.id}>
          <Tooltip delayDuration={50}>
            <TooltipTrigger>
              <FilterBadgeTag tag={tag} displayOnly={true} />
            </TooltipTrigger>
            <TooltipContent>
              <div className="flex items-center gap-2">
                <div className="flex flex-col">
                  <span>
                    {tag.tag_type
                      .split("_")
                      .map(
                        (word) =>
                          word.charAt(0).toUpperCase() +
                          word.slice(1).toLowerCase(),
                      )
                      .join(" ")}
                  </span>
                </div>
                <FilterBadgeTag tag={tag} displayOnly={true} />
              </div>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ))}
    </>
  );
};

const OmniSearchCardEntities = ({
  people,
  tags,
}: OmniSearchCardEntitiesProps) => {
  if (!tags && !people) {
    return (
      <OmniSearchCardSectionEmptyState
        message="No entities"
        className="h-full w-full p-2"
      />
    );
  }

  return (
    <div className="no-scrollbar flex h-full w-full items-center gap-2 overflow-x-scroll px-2">
      {people && people.length > 0 && (
        <div className="flex items-center gap-2">
          <PeopleList people={people} />
        </div>
      )}
      <div className="flex items-center gap-2">
        {tags && tags.length > 0 && <TagsList tags={tags} />}
      </div>
    </div>
  );
};

export default OmniSearchCardEntities;
