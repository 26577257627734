import React from "react";
import OmniSearchCardSectionEmptyState from "./OmniSearchCardSectionEmptyState";
import { components } from "@/openapi-bindings/v2";
import { getFileName } from "@/utils/pathUtils";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@kino/ui";
import SearchResultFeedbackPopover from "@/components/forms/SearchResultFeedbackPopover";
import RemoveFromPlaylistPopover from "@/components/playlists/RemoveFromPlaylistPopover";
import { MomentId } from "@/store/playlists/playlistsStore";

interface OmniSearchCardPathProps {
  path?: string;
  moment: components["schemas"]["Moment"];
  mediaItem: components["schemas"]["MediaItem"];
  playlistId?: string;
  searchResponse?: components["schemas"]["SearchResponse"];
}

const OmniSearchCardPath: React.FC<OmniSearchCardPathProps> = ({
  path,
  moment,
  mediaItem,
  playlistId,
  searchResponse,
}) => {
  if (!path) return <OmniSearchCardSectionEmptyState message="No path" />;

  return (
    <div className="flex h-3 flex-row items-center justify-between gap-2">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <h3 className="text-xsm flex-1 cursor-default select-none truncate text-ellipsis font-light text-neutral-600">
              {getFileName(path)}
            </h3>
          </TooltipTrigger>
          <TooltipContent className="cursor-default select-none text-xs">
            {path}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <div className="hidden duration-200 group-hover/card:flex">
        {playlistId && (
          <RemoveFromPlaylistPopover
            momentId={moment.id as MomentId}
            playlistId={playlistId}
          />
        )}
        {searchResponse && (
          <SearchResultFeedbackPopover moment={moment} mediaItem={mediaItem} />
        )}
      </div>
    </div>
  );
};

export default OmniSearchCardPath;
