import ResultsSection from "@/components/omniSearch/ResultsSection";
import OmniSearchCard from "@/components/omniSearch/OmniSearchCard";
import ResultsGrid from "../ResultsGrid";
import { useOmniSearch } from "@/hooks/useOmniSearch";
import { ContextMenu, ContextMenuContent, ContextMenuTrigger } from "@kino/ui";
import { AddToPlaylistSubContextMenu } from "@/components/playlists/AddToPlaylistSubContextMenu";

const AllResultsView = () => {
  const { searchResponse } = useOmniSearch();

  const buckets = {
    visual: searchResponse?.visual_hits,
    transcript: searchResponse?.transcript_hits,
  };

  // Track seen moments across all buckets
  const seenMomentIds = new Set<string>();

  return (
    <>
      {Object.entries(buckets).map(([key, bucket]) => {
        if (!bucket) return null;

        // Filter out duplicates for this bucket
        const uniqueHits = bucket.filter((hit) => {
          const mediaItem = searchResponse?.media_items?.[hit.media_item_id];
          const moment = mediaItem?.moments.find((m) => m.id === hit.moment_id);
          if (!moment) return false;

          if (seenMomentIds.has(moment.id)) {
            console.log(
              `NOTICED DUPLICATE IN ALL RESULTS at moment.id: ${moment.id}`,
            );
            return false;
          }
          seenMomentIds.add(moment.id);
          return true;
        });

        return (
          <ResultsSection
            key={key}
            title={key.slice(0, 1).toUpperCase() + key.slice(1)}
            numResults={uniqueHits.length}
          >
            <ResultsGrid truncate={true}>
              {uniqueHits.map((hit) => {
                const mediaItem =
                  searchResponse?.media_items?.[hit.media_item_id];
                const moment = mediaItem?.moments.find(
                  (moment) => moment.id === hit.moment_id,
                );
                if (!mediaItem || !moment) return null;
                return (
                  <ContextMenu key={hit.moment_id}>
                    <ContextMenuTrigger>
                      <OmniSearchCard
                        score={hit.score}
                        moment={moment}
                        searchResponse={searchResponse}
                        mediaItem={mediaItem}
                      />
                    </ContextMenuTrigger>
                    <ContextMenuContent>
                      <AddToPlaylistSubContextMenu
                        playlistMoment={{
                          moment_id: moment.id,
                          media_item_id: mediaItem.id,
                          start_time: moment.start,
                          end_time: moment.end,
                          position: 0,
                        }}
                      />
                    </ContextMenuContent>
                  </ContextMenu>
                );
              })}
            </ResultsGrid>
          </ResultsSection>
        );
      })}
    </>
  );
};

export default AllResultsView;
