import { createClearable, StoreCollectionIdentifier } from "@/utils/zustand/createClearable";

interface ActiveTranscriptState {
  currentSegmentIndex: number;
  setCurrentSegmentIndex: (index: number) => void;
}

export const useActiveTranscriptStore = createClearable([
  StoreCollectionIdentifier.ORGANIZATION,
])<ActiveTranscriptState>()((set) => ({
  currentSegmentIndex: -1,
  setCurrentSegmentIndex: (index) => set({ currentSegmentIndex: index }),
}));
