import { type components } from "@/openapi-bindings/v2";
import { ParsedQuery } from "@/types/search";
import { createClearable, StoreCollectionIdentifier } from "@/utils/zustand/createClearable";

interface SearchRequestStore {
  searchRequest: components["schemas"]["SearchRequest"] | null;
  setSearchRequest: (
    searchRequest: components["schemas"]["SearchRequest"],
  ) => void;
  searchQuery: components["schemas"]["SearchRequest"];
  parsedSearchQuery: ParsedQuery | null;
  setSearchQuery: (searchQuery: components["schemas"]["SearchRequest"]) => void;
  setParsedSearchQuery: (parsedSearchQuery: ParsedQuery) => void;
  reset: () => void;
}

export const useSearchRequestStore = createClearable([
  StoreCollectionIdentifier.SEARCH,
])<SearchRequestStore>()((set) => ({
  searchRequest: null,
  setSearchRequest: (searchRequest: components["schemas"]["SearchRequest"]) =>
    set({ searchRequest }),
  searchQuery: {
    id: "",
    text: "",
    person_ids: [],
    tag_ids: [],
    search_type: "all",
    return_full_metadata: false,
    organization_id: "",
    max_results: 20,
  },
  parsedSearchQuery: null,
  setSearchQuery: (searchQuery: components["schemas"]["SearchRequest"]) =>
    set({ searchQuery }),
  setParsedSearchQuery: (parsedSearchQuery: ParsedQuery) =>
    set({ parsedSearchQuery }),
  reset: () =>
    set({
      searchQuery: {
        id: "",
        text: "",
        person_ids: [],
        tag_ids: [],
        search_type: "all",
        return_full_metadata: false,
        organization_id: "",
        max_results: 20,
      },
      parsedSearchQuery: null,
    }),
}));
