import { getPersonNameInitials } from "@/utils/stringUtils";
import { Avatar, AvatarFallback, AvatarImage } from "@kino/ui";

interface UserBadgeProps {
  imageUrl?: string;
  firstName?: string;
}

export const UserBadge = ({ imageUrl, firstName }: UserBadgeProps) => (
  <div className="flex items-center gap-1">
    <Avatar size="sm" className="h-4 w-4 rounded-[4px]">
      <AvatarImage src={imageUrl} />
      <AvatarFallback>{getPersonNameInitials(firstName ?? "")}</AvatarFallback>
    </Avatar>
    <div className="text-xs">{firstName}</div>
  </div>
);
