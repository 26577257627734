import React, { useCallback, useMemo, useState } from "react";
import { components } from "@/openapi-bindings/v2";
import ResultsSection from "@/components/omniSearch/ResultsSection";
import OmniSearchCard from "@/components/omniSearch/OmniSearchCard";
import {
  getHydratedBucketsByAggregationKey,
  getMomentsBySearchMode,
} from "@/utils/search";
import VideoGroupHeader from "./aggregation/VideoGroupHeader";
import {
  AggregationKey,
  useSearchDisplayStore,
} from "@/store/search/searchDisplayStore";
import { useActiveBucketStore } from "@/store/search/activeBucketStore";
import ResultsCarousel from "../ResultsCarousel";
import ResultsGrid from "../ResultsGrid";
import useGetSearchMode from "@/hooks/useGetSearchMode";
import { useOmniSearch } from "@/hooks/useOmniSearch";
import { getInspectorItemId } from "@/utils/stringUtils";
import { HydratedBucket } from "@/types";

interface GroupHeaderProps {
  searchResponse: components["schemas"]["SearchResponse"];
  searchMode: components["schemas"]["SearchMode"];
  aggregationKey: AggregationKey;
  bucketId: string;
  hydratedBucket: HydratedBucket;
}

const GroupHeader = React.memo(
  ({
    searchResponse,
    searchMode,
    aggregationKey,
    bucketId,
    hydratedBucket,
  }: GroupHeaderProps) => {
    switch (aggregationKey) {
      case "by_video": {
        return <VideoGroupHeader hydratedBucket={hydratedBucket} />;
      }
      default:
        return null;
    }
  },
);

// Memoized card component that manages its own hover state
const MemoizedOmniSearchCard = React.memo(
  ({
    moment,
    mediaItem,
    searchResponse,
    isHighlighted,
    onMouseEnter,
    onMouseLeave,
    aggregationKey,
  }: {
    moment: components["schemas"]["Moment"];
    mediaItem: components["schemas"]["MediaItem"];
    searchResponse: components["schemas"]["SearchResponse"];
    isHighlighted: boolean;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
    aggregationKey: AggregationKey;
  }) => {
    return (
      <OmniSearchCard
        key={moment.id}
        moment={moment}
        mediaItem={mediaItem}
        isHighlighted={isHighlighted}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        searchResponse={searchResponse}
        displayTags={aggregationKey !== "by_video"}
      />
    );
  },
);

MemoizedOmniSearchCard.displayName = "MemoizedOmniSearchCard";

// Memoized bucket content that manages hover state
const BucketContent = React.memo(
  ({
    moments,
    searchResponse,
    bucketId,
    aggregationKey,
  }: {
    moments: components["schemas"]["Moment"][];
    searchResponse: components["schemas"]["SearchResponse"];
    bucketId: string;
    aggregationKey: AggregationKey;
  }) => {
    const { activeMarkerId, setActiveMarkerId } = useActiveBucketStore();

    return (
      <ResultsCarousel id={bucketId}>
        {moments.map((moment, index) => {
          const mediaItem = searchResponse.media_items?.[moment.media_item_id];
          if (!mediaItem) return null;

          const inspectorItemId = getInspectorItemId(mediaItem.id, moment.id);
          const isHighlighted = activeMarkerId === inspectorItemId;

          return (
            <MemoizedOmniSearchCard
              key={moment.id}
              moment={moment}
              mediaItem={mediaItem}
              searchResponse={searchResponse}
              isHighlighted={isHighlighted}
              onMouseEnter={() => setActiveMarkerId(inspectorItemId)}
              onMouseLeave={() => setActiveMarkerId(null)}
              aggregationKey={aggregationKey}
            />
          );
        })}
      </ResultsCarousel>
    );
  },
);

BucketContent.displayName = "BucketContent";

interface BucketProps {
  bucket: HydratedBucket;
  searchResponse: components["schemas"]["SearchResponse"];
  tabType: components["schemas"]["SearchMode"];
  aggregationKey: AggregationKey;
}

const Bucket = React.memo(
  ({ bucket, searchResponse, tabType, aggregationKey }: BucketProps) => {
    return (
      <ResultsSection
        key={bucket.key}
        header={
          <GroupHeader
            searchResponse={searchResponse}
            searchMode={tabType}
            aggregationKey={aggregationKey}
            bucketId={bucket.key}
            hydratedBucket={bucket}
          />
        }
        className="mx-0 w-full max-w-[1500px] rounded border p-4"
      >
        <BucketContent
          moments={bucket.moments}
          searchResponse={searchResponse}
          bucketId={bucket.key}
          aggregationKey={aggregationKey}
        />
      </ResultsSection>
    );
  },
);

Bucket.displayName = "Bucket";

interface GroupedBucketResultViewProps {
  aggregationKey: AggregationKey;
}

const GroupedBucketResultView = React.memo(
  ({ aggregationKey }: GroupedBucketResultViewProps) => {
    const { searchResponse } = useOmniSearch();
    const tabType = useGetSearchMode();

    if (!searchResponse) return null;

    const buckets = useMemo(
      () =>
        getHydratedBucketsByAggregationKey(
          searchResponse,
          aggregationKey,
          tabType,
        ),
      [searchResponse, aggregationKey, tabType],
    );

    return (
      <div className="flex w-full flex-col px-4 *:my-2 first:*:mt-4 last:*:mb-4">
        {buckets?.map((bucket) => (
          <Bucket
            key={bucket.key}
            bucket={bucket}
            searchResponse={searchResponse}
            tabType={tabType}
            aggregationKey={aggregationKey}
          />
        ))}
      </div>
    );
  },
);

interface BucketResultViewProps {
  searchMode: components["schemas"]["SearchMode"];
}

const BucketResultView = React.memo(({ searchMode }: BucketResultViewProps) => {
  const { aggregationKey } = useSearchDisplayStore();

  const { searchResponse } = useOmniSearch();

  const moments = useMemo(
    () =>
      searchResponse
        ? getMomentsBySearchMode(searchResponse, searchMode)
        : null,
    [searchResponse, searchMode],
  );

  if (!searchResponse) return null;

  return (
    <>
      {aggregationKey === "none" && (
        <ResultsSection>
          <ResultsGrid>
            {moments?.map((moment) => {
              const mediaItem =
                searchResponse?.media_items?.[moment.media_item_id];
              if (!mediaItem) return null;
              return (
                <OmniSearchCard
                  key={moment.id}
                  moment={moment}
                  mediaItem={mediaItem}
                  searchResponse={searchResponse}
                />
              );
            })}
          </ResultsGrid>
        </ResultsSection>
      )}
      {aggregationKey !== "none" && (
        <GroupedBucketResultView aggregationKey={aggregationKey} />
      )}
    </>
  );
});

export default BucketResultView;
