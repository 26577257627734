import { ToggleGroup, ToggleGroupItem } from "@kino/ui";
import { ViewType } from "@/types/search";
import {
  useDisplayStore,
  getEffectiveMode,
} from "@/store/display/displayStore";
import { GridIcon, RowsIcon } from "@radix-ui/react-icons";

export const ViewTypeToggles = () => {
  const { viewType, setViewType } = useDisplayStore();
  const effectiveMode = useDisplayStore(getEffectiveMode);

  return (
    <ToggleGroup
      type="single"
      value={viewType}
      onValueChange={(value) => value && setViewType(value as ViewType)}
      className="flex"
    >
      <ToggleGroupItem
        value={ViewType.Grid}
        aria-label="Grid view"
        className="h-6 w-6 p-0.5"
      >
        <GridIcon className="h-4 w-4" />
      </ToggleGroupItem>
      <ToggleGroupItem
        value={ViewType.List}
        aria-label="List view"
        className="h-6 w-6 p-0.5"
      >
        <RowsIcon className="h-4 w-4" />
      </ToggleGroupItem>
    </ToggleGroup>
  );
};
