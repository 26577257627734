import { forwardRef, useImperativeHandle, useState } from "react";
import { useNavigate } from "react-router";
import { z } from "zod";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  DialogDescription,
  DialogFooter,
  Input,
  Textarea,
  Label,
  DialogPortal,
} from "@kino/ui";
import { usePlaylistMutations } from "@/hooks/usePlaylists";
import { toast } from "sonner";
import { components } from "@/openapi-bindings/v2";

const playlistSchema = z.object({
  title: z.string().min(1, "Title is required").max(100, "Title is too long"),
  description: z.string().max(500, "Description is too long").optional(),
});

type PlaylistFormData = z.infer<typeof playlistSchema>;

type PlaylistDialogProps = {
  trigger?: React.ReactNode;
  initialMoments?: components["schemas"]["PlaylistMoment"][];
  playlist?: components["schemas"]["Playlist"];
  mode: "create" | "edit";
};

export type PlaylistDialogRef = {
  setOpen: (open: boolean) => void;
};

export const PlaylistDialog = forwardRef<
  PlaylistDialogRef,
  PlaylistDialogProps
>(({ trigger, initialMoments, playlist, mode }, ref) => {
  const { createPlaylist, createPlaylistMutation } = usePlaylistMutations();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState<PlaylistFormData>({
    title: playlist?.title || "",
    description: playlist?.description || "",
  });
  const [errors, setErrors] = useState<Record<string, string>>({});

  useImperativeHandle(ref, () => ({
    setOpen,
  }));

  const handleSubmit = async () => {
    try {
      const validatedData = playlistSchema.parse(formData);

      if (mode === "create") {
        const response = await createPlaylist({
          title: validatedData.title,
          description: validatedData.description,
          moments: (initialMoments ?? []).map((moment) => ({
            media_item_id: moment.media_item_id,
            start_time: moment.start_time,
            end_time: moment.end_time,
          })),
        });

        // Get the first playlist from the response since we're only creating one
        const createdPlaylist = response.playlists?.[0];
        if (createdPlaylist?.id) {
          navigate(`/playlists/${createdPlaylist.id}`);
          setOpen(false);
        } else {
          toast.error("Failed to get created playlist ID");
        }
      }
    } catch (error) {
      if (error instanceof z.ZodError) {
        const formattedErrors: Record<string, string> = {};
        error.errors.forEach((err) => {
          if (err.path[0]) {
            formattedErrors[err.path[0].toString()] = err.message;
          }
        });
        setErrors(formattedErrors);
      } else {
        console.error("Error submitting playlist:", error);
        toast.error("Failed to save playlist");
      }
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogPortal>
        <DialogContent className="max-w-96">
          <DialogHeader>
            <DialogTitle>
              {mode === "create" ? "Create new playlist" : "Edit playlist"}
            </DialogTitle>
            <DialogDescription>
              {mode === "create"
                ? "Create a new playlist to collect moments and videos"
                : "Update your playlist details"}
            </DialogDescription>
          </DialogHeader>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <Label htmlFor="playlistTitle">Title</Label>
              <Input
                type="text"
                name="playlistTitle"
                value={formData.title}
                onChange={(e) =>
                  setFormData({ ...formData, title: e.target.value })
                }
                className={errors.title ? "border-red-500" : ""}
              />
              {errors.title && (
                <span className="text-sm text-red-500">{errors.title}</span>
              )}
            </div>
            <div className="flex flex-col gap-2">
              <Label htmlFor="playlistDescription">Description</Label>
              <Textarea
                name="playlistDescription"
                value={formData.description}
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
                className={errors.description ? "border-red-500" : ""}
              />
              {errors.description && (
                <span className="text-sm text-red-500">
                  {errors.description}
                </span>
              )}
            </div>
          </div>
          <DialogFooter className="flex items-center justify-end gap-2">
            {mode === "edit" && (
              <Button variant="ghost" onClick={() => setOpen(false)}>
                Cancel
              </Button>
            )}
            <Button
              onClick={handleSubmit}
              type="submit"
              disabled={createPlaylistMutation.isPending}
            >
              {createPlaylistMutation.isPending
                ? "Creating..."
                : mode === "create"
                  ? "Create playlist"
                  : "Save changes"}
            </Button>
          </DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
});

PlaylistDialog.displayName = "PlaylistDialog";
