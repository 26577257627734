import { useCommentInputContext } from "@/context/CommentInputContext";
import { useClerkMemberships } from "@/hooks/useClerkMemberships";
import { useCommentReplyStore } from "@/store/collaboration/commentReplyStore";
import { ReplyIcon, Send, XIcon } from "lucide-react";
import React, { useState, useEffect } from "react";
import { toast } from "sonner";
import { TimeRange } from "./TimeRange";

const CommentInput = () => {
  const [comment, setComment] = useState("");
  const { getClerkUser } = useClerkMemberships();
  const { commentTextAreaRef } = useCommentInputContext();
  const { replyToName, clearReplyTo, replyToId, replyToComment } =
    useCommentReplyStore((state) => ({
      replyToName: state.replyToName,
      clearReplyTo: state.clearReplyTo,
      replyToId: state.replyToId,
      replyToComment: state.replyToComment,
    }));

  const clerkUser = replyToComment
    ? getClerkUser(replyToComment?.clerkUserId)
    : null;

  useEffect(() => {
    if (commentTextAreaRef.current) {
      commentTextAreaRef.current.style.height = "auto";
      commentTextAreaRef.current.style.height = `${commentTextAreaRef.current.scrollHeight}px`;
    }
  }, [comment]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (comment.trim()) {
      setComment("");
      toast.success(
        `Not implemented: Comment submitted to ${replyToComment?.id} by ${replyToName}`,
      );
    }
  };

  return (
    <div className="flex flex-col gap-2 bg-neutral-900 p-2">
      {clerkUser && replyToComment && (
        <div className="flex items-center justify-between gap-2 py-1 text-xs text-neutral-400">
          <div className="flex items-center gap-1">
            <ReplyIcon className="h-4 w-4" />
            <span className="flex items-center gap-1">
              Replying to {clerkUser.publicUserData.firstName}
              <TimeRange
                startTime={replyToComment.startTime}
                endTime={replyToComment.endTime}
              />
            </span>
          </div>
          <button
            onClick={clearReplyTo}
            className="text-neutral-500 hover:text-neutral-300"
          >
            <XIcon className="h-3 w-3" />
          </button>
        </div>
      )}
      <form onSubmit={handleSubmit} className="relative min-h-9">
        <div className="group relative flex rounded-lg bg-neutral-800 focus-within:ring-1 focus-within:ring-indigo-500">
          <textarea
            ref={commentTextAreaRef}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            onKeyDown={(e) => {
              if ((e.metaKey || e.ctrlKey) && e.key === "Enter") {
                e.preventDefault();
                if (comment.trim()) {
                  setComment("");
                  toast.success(
                    `Not implemented: Comment submitted to ${replyToId} by ${replyToName}`,
                  );
                }
              }
            }}
            placeholder={
              replyToName ? `Reply to ${replyToName}` : "Leave a comment..."
            }
            className="max-h-52 w-full resize-none overflow-y-scroll bg-transparent px-3 py-2 text-xs text-white placeholder-neutral-400 focus:outline-none"
            rows={1}
          />
          <div className="flex items-center pr-2">
            <button
              type="submit"
              disabled={!comment.trim()}
              className={`transition-all ${
                comment.trim()
                  ? "text-white opacity-100 hover:text-indigo-400"
                  : "pointer-events-none opacity-0"
              }`}
            >
              <Send className="h-4 w-4" />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export { CommentInput };
