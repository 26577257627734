import { HydratedComment } from "@/hooks/useGetMediaComments";
import {
  createClearable,
  StoreCollectionIdentifier,
} from "@/utils/zustand/createClearable";

interface CommentReplyStore {
  replyToComment: HydratedComment | null;
  replyToId: string | null;
  replyToName: string | null;
  setReplyTo: (comment: HydratedComment | null, name: string | null) => void;
  clearReplyTo: () => void;
}

export const useCommentReplyStore = createClearable([
  StoreCollectionIdentifier.ORGANIZATION,
])<CommentReplyStore>()((set) => ({
  replyToComment: null,
  replyToId: null,
  replyToName: null,
  setReplyTo: (comment, name) =>
    set({
      replyToComment: comment,
      replyToId: comment?.id,
      replyToName: name,
    }),
  clearReplyTo: () =>
    set({ replyToComment: null, replyToId: null, replyToName: null }),
}));
