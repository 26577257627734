import Loading from "@/layouts/Loading";
import { components } from "@/openapi-bindings/v2";
import { useSummaryGenerationStore } from "@/store/processing/summaryGenerationStore";
import type { ICellRendererParams } from "ag-grid-community";
import React from "react";

type MediaItem = components["schemas"]["MediaItem"];

interface DynamicCellRendererParams extends ICellRendererParams<MediaItem> {
  cellRenderer?: React.ComponentType<any>;
  cellRendererParams?: Record<string, unknown>;
}

const DynamicContentCell: React.FC<DynamicCellRendererParams> = ({
  value,
  data,
  cellRenderer: Renderer,
  cellRendererParams,
}) => {
  if (!data) return null;

  const { getStatus } = useSummaryGenerationStore();
  const status = getStatus(data.id);

  if (status === "generating") {
    return <Loading displayText="Processing..." />;
  }

  if (!Renderer) {
    return <div>{value?.toString()}</div>;
  }

  return <Renderer value={value} data={data} {...cellRendererParams} />;
};

export default DynamicContentCell;
