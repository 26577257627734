import { create } from "zustand";
import { persist } from "zustand/middleware";
import { useDebuggerStore } from "./debuggerStore";

interface ActiveServerState {
  baseUrl: string;
  overrideUrl: string | null;
  setBaseUrl: (url: string) => void;
  setOverrideUrl: (url: string | null) => void;
  buildServerUrl: (path: string) => string;
  getEffectiveUrl: () => string;
}

const useActiveServerStore = create<ActiveServerState>()(
  persist(
    (set, get) => ({
      baseUrl: "",
      overrideUrl: null,
      setBaseUrl: (url) => set({ baseUrl: url }),
      setOverrideUrl: (url) => set({ overrideUrl: url }),
      getEffectiveUrl: () => {
        return get().overrideUrl || get().baseUrl;
      },
      buildServerUrl: (path: string) => {
        if (useDebuggerStore.getState().isMockMediaEndpoint) {
          return path;
        }

        const cleanPath = path.replace(/^\//, "");
        const effectiveUrl = get().getEffectiveUrl().replace(/\/$/, "");

        if (!effectiveUrl) {
          return cleanPath;
        }

        return `${effectiveUrl}/${cleanPath}`;
      },
    }),
    {
      name: "active-server-storage",
    },
  ),
);

export { useActiveServerStore };
