import { components } from "@/openapi-bindings/v2";
import { ViewType } from "@/types/search";
import {
  createClearable,
  StoreCollectionIdentifier,
} from "@/utils/zustand/createClearable";
import { devtools, persist } from "zustand/middleware";

export type AggregationKey = components["schemas"]["Aggregation"]["key"];

interface PropertyVisibility {
  [key: string]: boolean;
}

interface AdvancedSettings {
  loop: boolean;
}

interface SearchDisplayState {
  viewType: ViewType;
  aggregationKey: AggregationKey;
  propertyVisibility: PropertyVisibility;
  advancedSettings: AdvancedSettings;
}

interface SearchDisplayActions {
  setViewType: (viewType: ViewType) => void;
  setAggregationKey: (key: AggregationKey) => void;
  togglePropertyVisibility: (property: string) => void;
  toggleLoop: () => void;
  resetState: () => void;
}

type SearchDisplayStore = SearchDisplayState & SearchDisplayActions;

export const SEARCH_DISPLAY_INITIAL_STATE: SearchDisplayState = {
  viewType: ViewType.Grid,
  aggregationKey: "none",
  propertyVisibility: {
    transcript: true,
    // preview: true,
    // summary: true,
    timeline: true,
    tags: true,
    people: true,
  },
  advancedSettings: {
    loop: false,
  },
};

export const useSearchDisplayStore = createClearable([
  StoreCollectionIdentifier.SEARCH,
])<SearchDisplayStore>()(
  devtools(
    persist(
      (set) => ({
        ...SEARCH_DISPLAY_INITIAL_STATE,

        setViewType: (viewType) => set({ viewType }),

        setAggregationKey: (key) => set({ aggregationKey: key }),

        togglePropertyVisibility: (property) =>
          set((state) => ({
            propertyVisibility: {
              ...state.propertyVisibility,
              [property]: !state.propertyVisibility[property],
            },
          })),

        toggleLoop: () =>
          set((state) => ({
            advancedSettings: {
              ...state.advancedSettings,
              loop: !state.advancedSettings.loop,
            },
          })),

        resetState: () => set(SEARCH_DISPLAY_INITIAL_STATE),
      }),
      {
        name: "search-display-storage",
        version: 1,
      },
    ),
    { name: "SearchDisplayStore" },
  ),
);
