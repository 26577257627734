"use client";

import * as React from "react";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import * as NavigationMenuPrimitive from "@radix-ui/react-navigation-menu";
import { cva } from "class-variance-authority";
import { useMemo } from "react";

import { cn } from "../utils/tailwind";

// Memoize the viewport component since it's used in every menu
const NavigationMenuViewportInner = React.memo(
  React.forwardRef<
    React.ElementRef<typeof NavigationMenuPrimitive.Viewport>,
    React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Viewport>
  >(({ className, ...props }, ref) => {
    const computedClassName = useMemo(
      () =>
        cn(
          "origin-top-center bg-popover text-popover-foreground data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-90 relative mt-1.5 h-[var(--radix-navigation-menu-viewport-height)] w-full overflow-hidden rounded-md border shadow md:w-[var(--radix-navigation-menu-viewport-width)]",
          className,
        ),
      [className],
    );

    return (
      <NavigationMenuPrimitive.Viewport
        className={computedClassName}
        ref={ref}
        {...props}
      />
    );
  }),
);

const NavigationMenuViewport = React.memo(
  React.forwardRef<
    React.ElementRef<typeof NavigationMenuPrimitive.Viewport>,
    React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Viewport>
  >(({ className, ...props }, ref) => {
    const computedClassName = useMemo(
      () => cn("absolute left-0 top-full flex justify-center"),
      [],
    );

    return (
      <div className={computedClassName}>
        <NavigationMenuViewportInner ref={ref} {...props} />
      </div>
    );
  }),
);

const NavigationMenu = React.memo(
  React.forwardRef<
    React.ElementRef<typeof NavigationMenuPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Root>
  >(({ className, children, ...props }, ref) => {
    const computedClassName = useMemo(
      () =>
        cn(
          "relative z-10 flex max-w-max flex-1 items-center justify-center",
          className,
        ),
      [className],
    );

    return (
      <NavigationMenuPrimitive.Root
        ref={ref}
        className={computedClassName}
        {...props}
      >
        {children}
        <NavigationMenuViewport />
      </NavigationMenuPrimitive.Root>
    );
  }),
);

const NavigationMenuList = React.memo(
  React.forwardRef<
    React.ElementRef<typeof NavigationMenuPrimitive.List>,
    React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.List>
  >(({ className, ...props }, ref) => {
    const computedClassName = useMemo(
      () =>
        cn(
          "group flex flex-1 list-none items-center justify-center space-x-1",
          className,
        ),
      [className],
    );

    return (
      <NavigationMenuPrimitive.List
        ref={ref}
        className={computedClassName}
        {...props}
      />
    );
  }),
);

// Memoize the MenuItem since it's used directly
const NavigationMenuItem = React.memo(NavigationMenuPrimitive.Item);

const navigationMenuTriggerStyle = cva(
  "group inline-flex h-9 w-max items-center justify-center rounded-md bg-background px-4 py-2 text-sm font-medium transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[active]:bg-accent/50 data-[state=open]:bg-accent/50",
);

// Memoize the ChevronIcon to prevent re-renders
const ChevronIcon = React.memo(() => {
  const computedClassName = useMemo(
    () =>
      "relative top-[1px] ml-1 h-3 w-3 transition duration-300 group-data-[state=open]:rotate-180",
    [],
  );

  return <ChevronDownIcon className={computedClassName} aria-hidden="true" />;
});

const NavigationMenuTrigger = React.memo(
  React.forwardRef<
    React.ElementRef<typeof NavigationMenuPrimitive.Trigger>,
    React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Trigger>
  >(({ className, children, ...props }, ref) => {
    const computedClassName = useMemo(
      () => cn(navigationMenuTriggerStyle(), "group", className),
      [className],
    );

    return (
      <NavigationMenuPrimitive.Trigger
        ref={ref}
        className={computedClassName}
        {...props}
      >
        {children} <ChevronIcon />
      </NavigationMenuPrimitive.Trigger>
    );
  }),
);

const NavigationMenuContent = React.memo(
  React.forwardRef<
    React.ElementRef<typeof NavigationMenuPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Content>
  >(({ className, ...props }, ref) => {
    const computedClassName = useMemo(
      () =>
        cn(
          "data-[motion^=from-]:animate-in data-[motion^=to-]:animate-out data-[motion^=from-]:fade-in data-[motion^=to-]:fade-out data-[motion=from-end]:slide-in-from-right-52 data-[motion=from-start]:slide-in-from-left-52 data-[motion=to-end]:slide-out-to-right-52 data-[motion=to-start]:slide-out-to-left-52 left-0 top-0 w-full md:absolute md:w-auto",
          className,
        ),
      [className],
    );

    return (
      <NavigationMenuPrimitive.Content
        ref={ref}
        className={computedClassName}
        {...props}
      />
    );
  }),
);

// Memoize the Link since it's used directly
const NavigationMenuLink = React.memo(NavigationMenuPrimitive.Link);

const NavigationMenuIndicator = React.memo(
  React.forwardRef<
    React.ElementRef<typeof NavigationMenuPrimitive.Indicator>,
    React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Indicator>
  >(({ className, ...props }, ref) => {
    const computedClassName = useMemo(
      () =>
        cn(
          "data-[state=visible]:animate-in data-[state=hidden]:animate-out data-[state=hidden]:fade-out data-[state=visible]:fade-in top-full z-[1] flex h-1.5 items-end justify-center overflow-hidden",
          className,
        ),
      [className],
    );

    const indicatorClassName = useMemo(
      () =>
        "bg-border relative top-[60%] h-2 w-2 rotate-45 rounded-tl-sm shadow-md",
      [],
    );

    return (
      <NavigationMenuPrimitive.Indicator
        ref={ref}
        className={computedClassName}
        {...props}
      >
        <div className={indicatorClassName} />
      </NavigationMenuPrimitive.Indicator>
    );
  }),
);

// Add display names
NavigationMenu.displayName = NavigationMenuPrimitive.Root.displayName;
NavigationMenuList.displayName = NavigationMenuPrimitive.List.displayName;
NavigationMenuTrigger.displayName = NavigationMenuPrimitive.Trigger.displayName;
NavigationMenuContent.displayName = NavigationMenuPrimitive.Content.displayName;
NavigationMenuIndicator.displayName =
  NavigationMenuPrimitive.Indicator.displayName;
NavigationMenuViewport.displayName =
  NavigationMenuPrimitive.Viewport.displayName;

export {
  navigationMenuTriggerStyle,
  NavigationMenu,
  NavigationMenuList,
  NavigationMenuItem,
  NavigationMenuContent,
  NavigationMenuTrigger,
  NavigationMenuLink,
  NavigationMenuIndicator,
  NavigationMenuViewport,
};
