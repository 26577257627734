import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-enterprise";
import "./ag-theme-kino.css";
import { useCallback, useEffect, useMemo, useRef } from "react";
import {
  CellEditingStartedEvent,
  CellFocusedEvent,
  RowDoubleClickedEvent,
  RowSelectionOptions,
  IServerSideDatasource,
} from "ag-grid-community";
import { components } from "@/openapi-bindings/v2";
import { useSelectedMediaStore } from "@/store/selectedMediaStore";
import { getInspectorItemId } from "@/utils/stringUtils";
import useMediaGrid from "@/hooks/ag-grid/useAGMediaGrid";
import { useGetContextMenuItems } from "@/hooks/ag-grid/useGetContextMenuItems";
import { useCanAccessDevFeatures } from "@/hooks/useCanAccessDevFeatures";
import { useSummaryGenerationStore } from "@/store/processing/summaryGenerationStore";
import { AddTagsDialog } from "@/components/face-uploader/add-tags-dialog";
import {
  getEffectiveMode,
  useDisplayStore,
} from "@/store/display/displayStore";
import { useLayoutStore } from "@/store/layoutStore";
import { PlaylistDialog } from "../playlists/PlaylistDialog";
import { formatMomentId } from "@/utils/moment";
import { DisplayMode } from "@/types/display";
import { IServerSideGetRowsParams } from "ag-grid-enterprise";
import { useClient, queryClient } from "@/hooks/useClient";
import { useSyncOrganization } from "@/store/organizationStore";
import { useSearchParams } from "react-router-dom";
import { useURLSearchParams } from "@/hooks/useURLSearchParams";

type MediaItem = components["schemas"]["MediaItem"];

interface FilterModel {
  [key: string]: {
    filterType: string;
    type?: string;
    filter?: string;
    filterTo?: string;
    values?: string[];
  };
}

interface MediaGridProps extends AgGridReactProps<MediaItem> {
  media?: components["schemas"]["AllMediaResponse"];
  gridRef: React.RefObject<AgGridReact<MediaItem>>;
}

const MediaGrid = ({ media, gridRef, ...agGridProps }: MediaGridProps) => {
  const effectiveMode = useDisplayStore(getEffectiveMode);
  const { columnDefs } = useMediaGrid({
    media,
    displayMode: effectiveMode as DisplayMode,
  });
  const { setCurrentSelection: setSelectedMedia } = useSelectedMediaStore();
  const canAccessDevFeatures = useCanAccessDevFeatures();
  const { getStatus } = useSummaryGenerationStore();
  const tagDialogRef = useRef<{ setOpen: (open: boolean) => void }>(null);
  const playlistDialogRef = useRef<{ setOpen: (open: boolean) => void }>(null);
  const selectedCellRef = useRef<MediaItem | null>(null);
  const { set: setLayoutState, isInspectorOpen } = useLayoutStore();

  // Move hooks to component level
  const { fetchClient } = useClient();
  const store = useSyncOrganization();
  const [searchParams] = useSearchParams();
  const { decodeURLSearchParams } = useURLSearchParams();

  // Create a ref to hold the latest searchParams
  const searchParamsRef = useRef(searchParams);

  // Keep searchParamsRef up to date
  useEffect(() => {
    searchParamsRef.current = searchParams;
  }, [searchParams]);

  // Create the datasource once and keep it stable
  const getServerSideDatasource = useCallback(
    (): IServerSideDatasource => ({
      getRows: async (params: IServerSideGetRowsParams<MediaItem>) => {
        const {
          startRow = 0,
          endRow = 100,
          sortModel,
          filterModel,
        } = params.request;

        // Always use the latest searchParams from the ref
        const { filterState } = decodeURLSearchParams(searchParamsRef.current);

        const combinedFilterModel: FilterModel = {
          ...(filterModel as FilterModel),
        };

        if (filterState) {
          if (filterState.person_ids?.length > 0) {
            combinedFilterModel.people = {
              filterType: "set",
              values: filterState.person_ids,
            };
          }

          if (filterState.tag_ids?.length > 0) {
            combinedFilterModel.tags = {
              filterType: "set",
              values: filterState.tag_ids,
            };
          }

          if (filterState.timecode_range) {
            combinedFilterModel.smpte_start_time = {
              filterType: "text",
              type: "inRange",
              filter: filterState.timecode_range[0],
              filterTo: filterState.timecode_range[1],
            };
          }
        }

        try {
          const { data } = await queryClient.ensureQueryData({
            queryKey: [
              "ag-grid-media",
              {
                organizationId: store.getEffectiveOrganizationId(),
                startRow,
                endRow,
                sortModel,
                filterModel: combinedFilterModel,
              },
            ],
            queryFn: () =>
              fetchClient.GET("/media/ag-grid-media", {
                params: {
                  query: {
                    organization_id: store.getEffectiveOrganizationId(),
                    start_row: startRow,
                    end_row: endRow,
                    sort_model: sortModel
                      ? JSON.stringify(sortModel)
                      : undefined,
                    filter_model:
                      Object.keys(combinedFilterModel).length > 0
                        ? JSON.stringify(combinedFilterModel)
                        : undefined,
                  },
                },
              }),
            staleTime: 30000,
          });

          if (!data) {
            throw new Error("No data received from server");
          }

          params.success({
            rowData: data.rows,
            rowCount: data.lastRow,
          });
        } catch (error) {
          console.error("Failed to fetch grid data:", error);
          params.fail();
        }
      },
    }),
    [fetchClient, store, decodeURLSearchParams],
  );

  // Create datasource once and store in ref
  const datasourceRef = useRef(getServerSideDatasource());

  // Memoize grid properties
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: "agTextColumnFilter",
      filterParams: {
        newRowsAction: "keep",
      },
      resizable: true,
    }),
    [],
  );

  const selectionColumnDef = useMemo(
    () => ({
      pinned: "left" as const,
      width: 40,
      minWidth: 40,
      maxWidth: 40,
      filter: false,
    }),
    [],
  );

  // Grid options with stable datasource
  const gridOptions = useMemo(
    () => ({
      rowModelType: "serverSide" as const,
      serverSideStoreType: "partial" as const,
      cacheBlockSize: 100,
      maxBlocksInCache: 10,
      blockLoadDebounceMillis: 100,
      suppressServerSideInfiniteScroll: false,
      serverSideSortOnServer: true,
      debug: false, // Turn off debug now that it's working
      serverSideDatasource: datasourceRef.current,
    }),
    [], // No dependencies since we use ref
  );

  // Refresh grid when URL params change
  useEffect(() => {
    if (gridRef.current?.api) {
      gridRef.current.api.refreshServerSide({ purge: true });
    }
  }, [searchParams]);

  const getRowId = useCallback((params: any) => params.data.id, []);

  const { getContextMenuItems } = useGetContextMenuItems({
    onCreateTagClick: () => tagDialogRef.current?.setOpen(true),
    onCreatePlaylistClick: () => {
      if (selectedCellRef.current) {
        playlistDialogRef.current?.setOpen(true);
      }
    },
  });

  // TODO: Make selection happen on thumbnail and filename click, all other cells should be focus/editing
  const onCellFocused = useCallback(
    (event: CellFocusedEvent<MediaItem>) => {
      const rowIndex = event.rowIndex;
      // Only play on single click if inspector is open but empty
      if (rowIndex !== null && isInspectorOpen) {
        const rowData = event.api.getDisplayedRowAtIndex(rowIndex)?.data;
        if (rowData) {
          setSelectedMedia([
            {
              mediaItem: rowData,
              activeInspectorMoment: undefined,
              id: getInspectorItemId(rowData.id),
            },
          ]);
          selectedCellRef.current = rowData;
        }
      }
    },
    [isInspectorOpen, setSelectedMedia],
  );

  // TODO: Feels like too much manual handling of selection
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        gridRef.current?.api.deselectAll();
        gridRef.current?.api.clearFocusedCell();
        gridRef.current?.api.clearCellSelection();
        setSelectedMedia([]);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [setSelectedMedia]);

  const rowSelection: RowSelectionOptions<MediaItem> = useMemo(
    () => ({
      mode: "multiRow" as const,
      selectAll: "filtered",
    }),
    [],
  );

  const onCellEditingStarted = useCallback(
    (event: CellEditingStartedEvent<MediaItem>) => {
      if (event.data?.id) {
        const status = getStatus(event.data.id);
        if (status === "generating") {
          event.api.stopEditing();
        }
      }
    },
    [getStatus],
  );

  const onRowDoubleClicked = useCallback(
    (event: RowDoubleClickedEvent<MediaItem>) => {
      if (event.data) {
        setLayoutState("isInspectorOpen", true);
        setSelectedMedia([
          {
            mediaItem: event.data,
            activeInspectorMoment: undefined,
            id: getInspectorItemId(event.data.id),
          },
        ]);
      }
    },
    [setLayoutState, setSelectedMedia],
  );

  return (
    <>
      <div className="ag-theme-quartz-dark ag-theme-kino h-full">
        <AgGridReact<MediaItem>
          ref={gridRef}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          enableCellTextSelection={false}
          rowSelection={rowSelection}
          suppressMovableColumns={true}
          onCellFocused={onCellFocused}
          rowHeight={60}
          onCellEditingStarted={onCellEditingStarted}
          onRowDoubleClicked={onRowDoubleClicked}
          animateRows={false}
          getRowId={getRowId}
          suppressContextMenu={!canAccessDevFeatures}
          getContextMenuItems={getContextMenuItems}
          selectionColumnDef={selectionColumnDef}
          gridOptions={gridOptions}
          {...agGridProps}
        />
      </div>
      <AddTagsDialog ref={tagDialogRef} />
      <PlaylistDialog
        ref={playlistDialogRef}
        mode="create"
        initialMoments={
          selectedCellRef.current
            ? [
                {
                  moment_id: formatMomentId({
                    mediaItemId: selectedCellRef.current.id,
                    start: 0,
                    end: selectedCellRef.current.ffprobe_data.duration,
                  }),
                  media_item_id: selectedCellRef.current.id,
                  start_time: 0,
                  end_time: selectedCellRef.current.ffprobe_data.duration,
                  position: 0,
                },
              ]
            : []
        }
      />
    </>
  );
};

export default MediaGrid;
