import {
  SignOutButton,
  useClerk,
  useOrganization,
  useUser,
} from "@clerk/clerk-react";
import { Button } from "@kino/ui";
import { useEffect } from "react";
import { useLocation, Navigate } from "react-router-dom";

export const NoOrganizationPage = () => {
  const { state } = useLocation();
  const { organization, isLoaded: isOrgLoaded } = useOrganization();
  const { isLoaded: isUserLoaded, isSignedIn, user } = useUser();

  const clerk = useClerk();

  const {
    reason = "You're not part of any organization",
    direction = "Please get added to one to use Kino for Enterprise.",
  } = state ?? {};

  // Auto-select first organization if none is selected
  useEffect(() => {
    if (!isSignedIn || !user) return;
    if (organization) return; // Already have an org selected

    const memberships = user.organizationMemberships;
    const firstOrg = memberships?.[0]?.organization;
    if (firstOrg?.id) {
      clerk.setActive({ organization: firstOrg.id });
    }
  }, [isUserLoaded, isOrgLoaded, isSignedIn, user, organization, clerk]);

  // If we have an active organization, redirect to home
  if (organization) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="flex h-screen items-center justify-center">
      <div className="rounded-lg border border-neutral-800 bg-neutral-900 p-8 text-center shadow-lg">
        <h1 className="mb-8 text-2xl font-semibold text-white">{reason}</h1>
        <p className="mb-6 text-neutral-300">{direction}</p>

        <div className="mb-8 text-neutral-400">
          <strong className="text-neutral-200">Reminder:</strong> Check your
          email for an invitation to join Kino AI.
        </div>

        <div className="mb-8 text-neutral-400">
          For assistance, please contact{" "}
          <a
            href="mailto:support@kino.ai"
            className="text-indigo-400 transition-colors hover:text-indigo-300"
          >
            support@kino.ai
          </a>
        </div>

        <SignOutButton redirectUrl="/">
          <Button className="bg-indigo-600 text-white transition-colors hover:bg-indigo-500">
            Sign Out
          </Button>
        </SignOutButton>
      </div>
    </div>
  );
};
