import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@kino/ui";
import { useDisplayStore } from "@/store/display/displayStore";

const GROUP_OPTIONS = [
  { value: "none", label: "None" },
  { value: "date", label: "Date" },
  { value: "type", label: "Type" },
  { value: "tags", label: "Tags" },
];

export const GroupBySelect = () => {
  const { groupingKey, setGroupingKey } = useDisplayStore();

  return (
    <Select value={groupingKey} onValueChange={setGroupingKey}>
      <SelectTrigger className="h-7 w-[130px]">
        <SelectValue placeholder="Select grouping" />
      </SelectTrigger>
      <SelectContent>
        {GROUP_OPTIONS.map((option) => (
          <SelectItem key={option.value} value={option.value}>
            {option.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
