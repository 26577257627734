import { useDebuggerStore } from "@/store/debuggerStore";
import { queryClient, useClient } from "./useClient";
import dummySeasonStatsResponse from "@/api/dummyData/v1/dummySeasonStatsResponse.json";
import { useSyncOrganization } from "@/store/organizationStore";
import { SeasonStatsRecord } from "@/types/season-stats";

// TODO: Add union discriminator to ensure that when data is loading it is undefined.
type UseSeasonStats = {
  isLoading: boolean;
  seasonStats: SeasonStatsRecord | undefined;
};

export const useSeasonStats = (): UseSeasonStats => {
  const { getEffectiveOrganizationId } = useSyncOrganization();
  const isMockSeasonStats = useDebuggerStore(
    (state) => state.isMockSeasonStats,
  );
  const { apiClient } = useClient();

  const { data, isLoading } = apiClient.useQuery(
    "get",
    "/season_stats",
    {
      params: {
        query: {
          organization_id: getEffectiveOrganizationId(),
        },
      },
    },
    {
      enabled: !isMockSeasonStats && !!getEffectiveOrganizationId(),
      placeholderData: isMockSeasonStats
        ? (dummySeasonStatsResponse as unknown as SeasonStatsRecord)
        : undefined,
    },
    queryClient,
  );

  // TODO: Add zod validation to ensure this response matches this type.
  const seasonStats = data as SeasonStatsRecord;

  return { seasonStats, isLoading };
};
