import { MomentId } from "@/store/playlists/playlistsStore";
import { constructMomentFromMomentId } from "@/utils/moment";
import { useGetMediaItems } from "./useGetMediaItem";
import { useSelectedMediaStore } from "@/store/selectedMediaStore";
import { useCallback } from "react";
import { components } from "@/openapi-bindings/v2";

export const usePlaylistAutoplay = ({
  playlist,
  momentId,
}: {
  playlist?: components["schemas"]["Playlist"];
  momentId?: MomentId;
}) => {
  const playlistMediaItems = useGetMediaItems(
    playlist?.moments?.map((moment) => moment.media_item_id) || [],
  );
  const setCurrentSelection = useSelectedMediaStore(
    (state) => state.setCurrentSelection,
  );

  const onPlaylistItemEnd = useCallback(() => {
    if (!playlist?.moments || !momentId || !playlistMediaItems.data) {
      return;
    }

    const previousMomentIndex = playlist.moments.findIndex(
      (moment) => moment.media_item_id === momentId,
    );
    const nextMomentIndex = previousMomentIndex + 1;
    const nextMomentId = playlist.moments[nextMomentIndex];
    const nextMomentMediaItem = playlistMediaItems.data[nextMomentIndex];

    if (!nextMomentId || !nextMomentMediaItem) {
      return;
    }

    setCurrentSelection([
      {
        id: momentId,
        mediaItem: nextMomentMediaItem,
        activeInspectorMoment: {
          moment: constructMomentFromMomentId(momentId, nextMomentMediaItem),
          searchMode: "omni",
        },
      },
    ]);
  }, [momentId, playlist?.moments, playlistMediaItems.data]);

  return { onPlaylistItemEnd };
};
