import { cn, Skeleton } from "@kino/ui";
import React from "react";

interface LoadingProps extends React.HTMLAttributes<HTMLDivElement> {
  displayText?: string;
}

const Loading = ({ className, displayText = "Loading..." }: LoadingProps) => {
  return (
    <div
      className={cn(
        "flex h-full min-h-screen w-full flex-col items-center justify-center bg-neutral-900",
        className,
      )}
    >
      <div className="flex items-center gap-2">
        <Skeleton className="loading-dot" />
        <span className="text-xs text-neutral-500">{displayText}</span>
      </div>
    </div>
  );
};

export default Loading;
