import React, { forwardRef, useImperativeHandle, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Button,
  Input,
  Label,
} from "@kino/ui";
import { Plus, X } from "lucide-react";
import { useSyncOrganization } from "@/store/organizationStore";
import { toast } from "sonner";
import { queryClient, useClient } from "@/hooks/useClient";
import { useHotkeys } from "react-hotkeys-hook";

interface PersonEntry {
  name: string;
  nickname: string;
  id: string;
}

interface AddPersonDialogProps {
  onAddPerson: (name: string, nickname: string, id?: string) => void;
  referenceFacePath?: string;
  thumbnailPath?: string;
}

export const AddPersonDialog = forwardRef<
  { setOpen: (open: boolean) => void },
  AddPersonDialogProps
>(({ onAddPerson, referenceFacePath, thumbnailPath }, ref) => {
  const orgStore = useSyncOrganization();
  const { apiClient } = useClient();
  const createPerson = apiClient.useMutation(
    "post",
    "/person",
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["get", "/people"] });
      },
    },
    queryClient,
  );

  const [open, setOpen] = useState(false);
  const [personEntries, setPersonEntries] = useState<PersonEntry[]>([
    { name: "", nickname: "", id: "" },
  ]);

  useImperativeHandle(ref, () => ({
    setOpen,
  }));

  const handleSubmit = async (e?: React.FormEvent) => {
    e?.preventDefault();

    const validEntries = personEntries.filter((entry) => entry.name.trim());

    if (validEntries.length === 0) {
      toast.error("Please fill in at least one person's name");
      return;
    }

    try {
      let successCount = 0;
      let failureCount = 0;

      for (const entry of validEntries) {
        try {
          await createPerson.mutateAsync({
            body: {
              organization_id: orgStore.getEffectiveOrganizationId(),
              name: entry.name.trim(),
              nickname: entry.nickname.trim(),
              id: entry.id.trim() || undefined,
              reference_face_paths: referenceFacePath
                ? [referenceFacePath]
                : [],
              thumbnail_path: thumbnailPath ?? "",
            },
          });
          onAddPerson(
            entry.name.trim(),
            entry.nickname.trim(),
            entry.id.trim() || undefined,
          );
          successCount++;
        } catch (error) {
          failureCount++;
          console.error("Failed to create person:", error);
        }
      }

      if (successCount > 0 && failureCount === 0) {
        toast.success(`Successfully created ${successCount} people`);
      } else if (successCount > 0 && failureCount > 0) {
        toast.warning(
          `Created ${successCount} people, but ${failureCount} failed`,
        );
      } else {
        toast.error("Failed to create any people");
      }

      setPersonEntries([{ name: "", nickname: "", id: "" }]);
      setOpen(false);
    } catch (error) {
      toast.error("Failed to create people");
      console.error("Failed to create people:", error);
    }
  };

  const addNewPersonEntry = () => {
    setPersonEntries([...personEntries, { name: "", nickname: "", id: "" }]);
  };

  const removePersonEntry = (index: number) => {
    if (personEntries.length > 1) {
      setPersonEntries(personEntries.filter((_, i) => i !== index));
    }
  };

  const updatePersonEntry = (
    index: number,
    field: keyof PersonEntry,
    value: string,
  ) => {
    setPersonEntries((entries) => {
      const newEntries = [...entries];
      const currentEntry = entries[index];
      if (!currentEntry) return entries;

      newEntries[index] = {
        name: field === "name" ? value : currentEntry.name,
        nickname: field === "nickname" ? value : currentEntry.nickname,
        id: field === "id" ? value : currentEntry.id,
      };
      return newEntries;
    });
  };

  useHotkeys(
    "mod+enter",
    () => {
      if (open) {
        handleSubmit();
      }
    },
    [open],
  );

  useHotkeys("c", () => setOpen(true), { preventDefault: true });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button
          variant="ghost"
          size="sm"
          className="text-muted-foreground hover:text-foreground absolute right-4 top-4"
        >
          <Plus className="h-5 w-5" />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Add New People</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-6 pt-4">
          {personEntries.map((person, index) => (
            <div
              key={index}
              className="relative flex items-start gap-4 rounded-lg border border-neutral-800 p-4"
            >
              <div className="flex-1 space-y-4">
                <div className="space-y-2">
                  <Label htmlFor={`name-${index}`}>Name</Label>
                  <Input
                    id={`name-${index}`}
                    value={person.name}
                    onChange={(e) =>
                      updatePersonEntry(index, "name", e.target.value)
                    }
                    placeholder="Enter name"
                    autoFocus={index === 0}
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor={`nickname-${index}`}>
                    Nickname{" "}
                    <span className="text-muted-foreground">(optional)</span>
                  </Label>
                  <Input
                    id={`nickname-${index}`}
                    value={person.nickname}
                    onChange={(e) =>
                      updatePersonEntry(index, "nickname", e.target.value)
                    }
                    placeholder="Enter nickname"
                  />
                </div>
                <div className="space-y-2 opacity-75">
                  <Label htmlFor={`id-${index}`}>
                    ID <span className="text-muted-foreground">(optional)</span>
                  </Label>
                  <Input
                    id={`id-${index}`}
                    value={person.id}
                    onChange={(e) =>
                      updatePersonEntry(index, "id", e.target.value)
                    }
                    placeholder="Enter ID"
                  />
                </div>
              </div>
              {personEntries.length > 1 && (
                <Button
                  type="button"
                  variant="ghost"
                  size="icon"
                  className="absolute right-2 top-2"
                  onClick={() => removePersonEntry(index)}
                >
                  <X className="h-4 w-4" />
                </Button>
              )}
            </div>
          ))}

          <div className="flex justify-between">
            <Button
              type="button"
              variant="outline"
              onClick={addNewPersonEntry}
              className="gap-2"
            >
              <Plus className="h-4 w-4" /> Add Another Person
            </Button>
            <Button
              type="submit"
              disabled={!personEntries.some((p) => p.name.trim())}
            >
              Create People
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
});

AddPersonDialog.displayName = "AddPersonDialog";
