import { Link } from "react-router-dom";
import { Card } from "@kino/ui";
import PlaylistCover from "./PlaylistCover";
import PlaylistMetadata from "./PlaylistMetadata";
import { useUser } from "@clerk/clerk-react";
import { components } from "@/openapi-bindings/v2";

type Playlist = components["schemas"]["Playlist"];

const PlaylistCard = ({ playlist }: { playlist: Playlist }) => {
  const { user } = useUser();
  return (
    <Link
      key={playlist.id}
      to={playlist.id}
      className="flex flex-col overflow-hidden"
    >
      <Card className="flex flex-col gap-2 border-none shadow-none transition-colors hover:bg-neutral-800/50">
        <PlaylistCover playlist={playlist} />
        <div className="w-full cursor-default select-none truncate text-ellipsis text-sm font-medium">
          {playlist.title}
        </div>
        <PlaylistMetadata
          playlist={playlist}
          userImageUrl={user?.imageUrl}
          userFirstName={user?.firstName ?? undefined}
        />
      </Card>
    </Link>
  );
};

export default PlaylistCard;
