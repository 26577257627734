import { useEffect, useState } from "react";
import { useUser } from "@clerk/clerk-react";
import { useClerkMemberships } from "@/hooks/useClerkMemberships";

// TODO: Use real react query comments

export interface Comment {
  id: string;
  rootCommentId: string;
  text: string;
  created_at: Date;
  updated_at: Date;
  clerkUserId: string;
  startTime: number;
  endTime?: number;
  replies?: HydratedComment[];
}

interface UseGetMediaCommentsResult {
  data: HydratedComment[] | undefined;
  isLoading: boolean;
  error: Error | null;
}

export interface HydratedComment extends Comment {
  clerkFirstname: string;
  clerkLastname: string;
  clerkImageUrl: string;
}

const COMMENT_TEXTS = [
  "The composition in this shot is really striking",
  "Can we adjust the color grading here?",
  "The pacing feels a bit off in this section",
  "Great work on the lighting setup!",
  "This transition could be smoother",
  "Love the camera movement here",
  "The audio mix needs some work",
  "Perfect timing on this cut",
  "Should we try a different angle?",
  "The focus pull is really effective",
  "Background elements are distracting",
  "Nice choice of lens for this shot",
  "Consider adjusting the depth of field",
  "The framing really tells the story",
  "Maybe we should reshoot this part",
  "Color temperature seems off here",
  "Sound effects are spot on",
  "Can we extend this shot longer?",
  "The blocking works really well",
  "Need to fix the continuity issue",
] as const;

function createSeededRandom(seed: number) {
  return function () {
    seed = (seed * 16807) % 2147483647;
    return (seed - 1) / 2147483646;
  };
}

function seededShuffleArray<T>(
  array: readonly T[],
  seededRandom: () => number,
): T[] {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(seededRandom() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j]!, shuffled[i]!];
  }
  return shuffled;
}

export function useGetMediaComments(
  maxVideoLength?: number,
): UseGetMediaCommentsResult {
  const { user } = useUser();
  const { memberships } = useClerkMemberships();

  const [state, setState] = useState<UseGetMediaCommentsResult>({
    data: undefined,
    isLoading: true,
    error: null,
  });

  useEffect(() => {
    if (!maxVideoLength || !memberships) {
      setState({
        data: undefined,
        isLoading: false,
        error: new Error("Missing required data"),
      });
      return;
    }

    try {
      const getRandom = createSeededRandom(Math.floor(maxVideoLength * 1000));
      const baseTime = new Date();
      const comments: HydratedComment[] = [];
      const shuffledTexts = seededShuffleArray(COMMENT_TEXTS, getRandom);

      // Generate comments
      for (let i = 0; i < 20; i++) {
        const startTime = Math.floor(getRandom() * maxVideoLength);
        const endTime =
          getRandom() > 0.3
            ? Math.min(
                startTime + Math.floor(getRandom() * 30) + 5,
                maxVideoLength,
              )
            : undefined;

        const randomMemberId =
          memberships?.data?.[
            Math.floor(getRandom() * (memberships?.data?.length ?? 1))
          ]?.publicUserData.userId ??
          user?.id ??
          "";

        const rootComment: HydratedComment = {
          id: `comment-${i}-${startTime}`,
          rootCommentId: `comment-${i}-${startTime}`,
          text: shuffledTexts[i % shuffledTexts.length] ?? "",
          created_at: new Date(baseTime.getTime() - i * 60 * 60 * 1000),
          updated_at: new Date(baseTime.getTime() - i * 60 * 60 * 1000),
          clerkUserId: randomMemberId,
          clerkFirstname:
            memberships?.data?.[0]?.publicUserData.firstName ?? "",
          clerkLastname: memberships?.data?.[0]?.publicUserData.lastName ?? "",
          clerkImageUrl: memberships?.data?.[0]?.publicUserData.imageUrl ?? "",
          startTime,
          endTime,
        };

        // Generate replies
        if (getRandom() < 0.4) {
          const numReplies = Math.floor(getRandom() * 3) + 1;
          const replies: HydratedComment[] = [];

          for (let j = 0; j < numReplies; j++) {
            const replyIndex = (i + 10 + j) % shuffledTexts.length;
            replies.push({
              id: `reply-${i}-${j}-${startTime}`,
              rootCommentId: rootComment.rootCommentId,
              text: shuffledTexts[replyIndex] ?? "",
              created_at: new Date(
                baseTime.getTime() - (i * 60 + j * 15) * 60 * 1000,
              ),
              updated_at: new Date(
                baseTime.getTime() - (i * 60 + j * 15) * 60 * 1000,
              ),
              clerkUserId: randomMemberId,
              clerkFirstname:
                memberships?.data?.[0]?.publicUserData.firstName ?? "",
              clerkLastname:
                memberships?.data?.[0]?.publicUserData.lastName ?? "",
              clerkImageUrl:
                memberships?.data?.[0]?.publicUserData.imageUrl ?? "",
              startTime,
              endTime,
            });
          }

          rootComment.replies = replies;
        }

        comments.push(rootComment);
      }

      setState({
        data: comments,
        isLoading: false,
        error: null,
      });
    } catch (error) {
      setState({
        data: undefined,
        isLoading: false,
        error:
          error instanceof Error ? error : new Error("Unknown error occurred"),
      });
    }
  }, [maxVideoLength, memberships?.data?.length, user?.id]);

  return state;
}
