import React, { useState } from "react";
import { ServerOffIcon, ServerIcon } from "lucide-react";
import {
  cn,
  ClickableIcon,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@kino/ui";
import { useActiveServerStore } from "@/store/activeServerStore";
import { usePythonServerStatus } from "@/hooks/usePythonServerStatus";
import { useClerkServerInfo } from "@/hooks/useClerkMetadata";

type ServerStatus = boolean | null;

interface StatusIndicatorProps {
  status: ServerStatus;
  className?: string;
}

interface ServerUrlDisplayProps {
  baseUrl: string;
  overrideUrl?: string | null;
}

const getStatusColor = (status: ServerStatus) => {
  if (status === null) return "bg-yellow-500";
  return status ? "bg-green-500" : "bg-red-500";
};

const getStatusText = (status: ServerStatus) => {
  if (status === null) return "Loading...";
  return status ? "Online" : "Offline";
};

const getServerIcon = (status: ServerStatus) => {
  if (status === null) return ServerIcon;
  return status ? ServerIcon : ServerOffIcon;
};

const StatusIndicator: React.FC<StatusIndicatorProps> = ({
  status,
  className,
}) => (
  <div
    className={cn(
      "h-1.5 w-1.5 rounded-full border border-neutral-900",
      getStatusColor(status),
      className,
    )}
  />
);

const StatusBadge: React.FC<{ status: ServerStatus }> = ({ status }) => (
  <div className="flex items-center gap-1 rounded bg-neutral-800 px-1.5 py-0.5">
    <StatusIndicator status={status} />
    <div className="text-xsm text-neutral-500">{getStatusText(status)}</div>
  </div>
);

const ServerUrlDisplay: React.FC<ServerUrlDisplayProps> = ({
  baseUrl,
  overrideUrl,
}) => (
  <div className="flex items-center gap-1">
    <div className="text-neutral-600">
      {overrideUrl ? (
        <>
          <span className="line-through">{baseUrl}</span>
          <span className="ml-2 text-green-600">{overrideUrl}</span>
        </>
      ) : (
        baseUrl
      )}
    </div>
  </div>
);

const ActiveServerPopover: React.FC = () => {
  const overrideUrl = useActiveServerStore((state) => state.overrideUrl);
  const { apiUrl } = useClerkServerInfo();
  const isPythonServerOnline = usePythonServerStatus();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger className="relative">
        <ClickableIcon
          Icon={getServerIcon(isPythonServerOnline)}
          name="server-status"
          tooltip="Server"
          onClick={() => setIsOpen(!isOpen)}
          className={cn(
            "rounded-md p-1 text-neutral-500 hover:bg-neutral-800",
            isOpen ? "bg-neutral-800" : "",
          )}
        />
        <StatusIndicator
          status={isPythonServerOnline}
          className="absolute bottom-[2px] left-[17px]"
        />
      </PopoverTrigger>
      <PopoverContent
        className="flex min-w-72 flex-col gap-4 text-xs"
        align="start"
      >
        <div className="flex w-full flex-col gap-2">
          <div className="flex items-center gap-2">
            <div className="text-xs">Active Server</div>
            <StatusBadge status={isPythonServerOnline} />
          </div>
          <ServerUrlDisplay
            baseUrl={apiUrl ?? "No backend URL configured"}
            overrideUrl={overrideUrl}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default ActiveServerPopover;
