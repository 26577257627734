import React, { useMemo } from "react";
import { getFileName } from "@/utils/pathUtils";
import { formatStringPlurality, getInspectorItemId } from "@/utils/stringUtils";
import { ImageIcon } from "@radix-ui/react-icons";
import OmniSearchCardRelativeTimeline from "../../cardModules/OmniSearchCardRelativeTimeline";
import { useActiveBucketStore } from "@/store/search/activeBucketStore";
import { useCarouselStore } from "@/store/search/carouselStore";
import { useSelectedMediaStore } from "@/store/selectedMediaStore";
import { HydratedBucket } from "@/types";
import { useOmniSearch } from "@/hooks/useOmniSearch";
import { useActiveServerStore } from "@/store/activeServerStore";
import { TagsList } from "../../cardModules/OmniSearchCardEntities";
import { components } from "@/openapi-bindings/v2";
import { useGetTagsQuery } from "@/hooks/useGetTagsQuery";
import { secondsToTimestamp } from "@/utils/time";

interface VideoGroupHeaderProps {
  hydratedBucket: HydratedBucket;
}

const VideoGroupHeader = ({ hydratedBucket }: VideoGroupHeaderProps) => {
  const { key, moments } = hydratedBucket;
  const { searchResponse } = useOmniSearch();
  const mediaItems = searchResponse?.media_items;

  const { setActiveMarkerId, activeMarkerId } = useActiveBucketStore();
  const { selectedMedia } = useSelectedMediaStore((state) => ({
    selectedMedia: state.currentSelection,
  }));
  const { isItemInViewport, scrollToItem } = useCarouselStore();

  const firstMoment = moments[0];
  if (!firstMoment) return null;

  const mediaItem = mediaItems?.[firstMoment.media_item_id];
  if (!mediaItem) return null;

  const handleMarkerInteraction = (index: number) => {
    if (!isItemInViewport(key, index)) {
      scrollToItem(key, index);
    }
  };
  const { buildServerUrl } = useActiveServerStore();

  const { data: storedTags } = useGetTagsQuery();

  const tags = useMemo(() => {
    if (!firstMoment.tag_ids || !storedTags) {
      return [];
    }

    const mappedTags = firstMoment.tag_ids
      .map((tagId) => {
        const tag = storedTags.find((t) => t.id === tagId);
        return tag;
      })
      .filter(
        (tag): tag is components["schemas"]["Tag"] =>
          tag !== undefined && tag !== null,
      );

    return mappedTags;
  }, [firstMoment.tag_ids, storedTags]);

  return (
    <div className="flex w-full flex-col gap-4 text-xs">
      <div className="flex w-full items-center justify-between gap-2 overflow-hidden">
        <div className="flex basis-2/3 items-center gap-2 overflow-hidden">
          {mediaItem.key_thumbnail_path ? (
            <img
              src={buildServerUrl(mediaItem.key_thumbnail_path)}
              className="h-8 w-auto overflow-hidden rounded border object-cover"
              alt="Video thumbnail"
            />
          ) : (
            <div className="flex h-8 w-auto min-w-8 items-center justify-center rounded border bg-neutral-800 text-neutral-600">
              <ImageIcon className="h-4 w-4" />
            </div>
          )}
          <div className="flex h-full w-full flex-col justify-between overflow-hidden">
            <div className="flex flex-1 items-center gap-2 text-xs">
              <span className="basis-2/3 truncate text-ellipsis whitespace-nowrap">
                {getFileName(mediaItem.ffprobe_data.format.filename ?? "")}
              </span>
              <span className="basis-1/3 truncate text-ellipsis whitespace-nowrap text-neutral-600">
                {mediaItem.ffprobe_data.format.filename}
              </span>
            </div>
            <div className="text-xs text-neutral-600">
              {moments.length}{" "}
              {formatStringPlurality(moments.length, "moment", "moments")}
            </div>
          </div>
        </div>
        <div className="basis-1/3 items-center">
          <OmniSearchCardRelativeTimeline
            segments={[]}
            markers={moments.map((moment, index) => {
              const inspectorItemId = getInspectorItemId(
                mediaItem.id,
                moment.id,
              );

              // Check if this marker is active by comparing its ID with any active inspector items
              const isActive = selectedMedia?.some(
                (item) => item.id === inspectorItemId,
              );

              return {
                id: inspectorItemId,
                percentage: mediaItem.ffprobe_data.format.duration
                  ? (100 * (moment.start ?? 0)) /
                    Number(mediaItem.ffprobe_data.format.duration)
                  : 0,
                onMouseOver: () => {
                  setActiveMarkerId(inspectorItemId);
                  // handleMarkerInteraction(index);
                },
                onMouseLeave: () => {
                  setActiveMarkerId(null);
                },
                onClick: () => {
                  setActiveMarkerId(inspectorItemId);
                  handleMarkerInteraction(index);
                },
                isActive,
                isHighlighted: activeMarkerId === inspectorItemId,
                tooltipText: `${secondsToTimestamp(moment.start, {
                  offset: moment.smpte_timecode_offset ?? undefined,
                })} - ${secondsToTimestamp(moment.end, {
                  offset: moment.smpte_timecode_offset ?? undefined,
                })}`,
                className: "cursor-pointer",
              };
            })}
            duration={Number(mediaItem.ffprobe_data.format.duration)}
            showTimestamps={false}
          />
        </div>
      </div>
      <div className="flex items-center gap-2">
        <TagsList tags={tags} />
      </div>
    </div>
  );
};

export default VideoGroupHeader;
