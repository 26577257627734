import { useAllMedia, useMediaItems } from "./useAllMediaQuery";

export function useGetMediaItem(mediaId: string | undefined) {
  const { data: mediaItems, isLoading } = useMediaItems(
    mediaId ? [mediaId] : [],
  );

  return {
    data: mediaItems && mediaItems[mediaId || ""],
    isLoading,
  };
}

export function useGetMediaItems(
  mediaIds: string[],
  removeDuplicates: boolean = false,
) {
  const uniqueMediaIds = removeDuplicates ? [...new Set(mediaIds)] : mediaIds;
  const { data: mediaItems, isLoading } = useMediaItems(uniqueMediaIds);

  return {
    data: removeDuplicates
      ? Object.values(mediaItems || {})
      : mediaIds.map((mediaId) => mediaItems?.[mediaId]),
    isLoading,
  };
}
