import { Avatar, AvatarFallback } from "@kino/ui";
import { getPersonNameInitials } from "@/utils/stringUtils";

// Clean speaker_id by removing non-alphanumeric characters
const cleanSpeakerId = (speakerId: string): string => {
  return speakerId.replace(/[^a-zA-Z0-9_\s]/g, "").trim();
};

interface SpeakerLabelProps {
  speakerId: string;
  speakerName?: string;
  speakerImgSrc?: string;
}

const SpeakerLabel = ({ speakerId }: SpeakerLabelProps) => {
  if (!speakerId) {
    return null;
  }

  return (
    <div className="flex items-center gap-1">
      <Avatar size="xs" className="h-5 w-5 border border-neutral-600">
        <AvatarFallback className="flex h-full w-full items-center justify-center border text-[8px]">
          {getPersonNameInitials(cleanSpeakerId(speakerId))}
        </AvatarFallback>
      </Avatar>
      <div className="text-xs">{cleanSpeakerId(speakerId)}</div>
    </div>
  );
};

export default SpeakerLabel;
