import {
  createClearable,
  StoreCollectionIdentifier,
} from "@/utils/zustand/createClearable";
import {
  type BaseFilters,
  EMPTY_SEARCH_RESULTS_FILTER_OPTIONS,
  type SearchResultsFilters,
} from "@/types/filters";

interface FilterOptionsStore {
  filterOptions: SearchResultsFilters;
  setFilterOptions: (options: SearchResultsFilters) => void;
  updateBaseFilterOptionKey: <K extends keyof BaseFilters>(
    key: K,
    value: BaseFilters[K],
  ) => void;
}

export const useFilterOptionsStore = createClearable([
  StoreCollectionIdentifier.ORGANIZATION,
])<FilterOptionsStore>()((set) => ({
  filterOptions: EMPTY_SEARCH_RESULTS_FILTER_OPTIONS,
  setFilterOptions: (options) =>
    set((currentState) => ({
      ...currentState,
      filterOptions: { ...options },
    })),
  updateBaseFilterOptionKey: <K extends keyof BaseFilters>(
    key: K,
    value: BaseFilters[K],
  ) =>
    set((currentState) => ({
      ...currentState,
      filterOptions: { ...currentState.filterOptions, [key]: value },
    })),
}));
