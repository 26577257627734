import { secondsToTimestamp } from "@/utils/time";

interface TimeRangeProps {
  startTime: number;
  endTime?: number;
  offset?: number;
}

export const TimeRange = ({ startTime, endTime, offset }: TimeRangeProps) => {
  return (
    <span className="rounded-md bg-neutral-400/10 p-1 px-2 text-xs text-neutral-400">
      {secondsToTimestamp(startTime, { offset })}
      {endTime ? `- ${secondsToTimestamp(endTime, { offset })}` : ""}
    </span>
  );
};
