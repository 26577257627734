import React, { HTMLAttributes, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Button,
  ClickableIcon,
  cn,
} from "@kino/ui";
import { MinusCircleIcon } from "lucide-react";
import { MomentId } from "@/store/playlists/playlistsStore";
import { usePlaylistMoments } from "@/hooks/usePlaylists";

interface RemoveFromPlaylistPopoverProps
  extends HTMLAttributes<HTMLDivElement> {
  momentId: MomentId;
  playlistId: string;
}

const RemoveFromPlaylistPopover: React.FC<RemoveFromPlaylistPopoverProps> = ({
  momentId,
  playlistId,
  className,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { removeMoments } = usePlaylistMoments();

  const handleRemove = (e: React.MouseEvent) => {
    e.stopPropagation();
    removeMoments({
      playlist_id: playlistId,
      moment_ids: [momentId],
    });
    setIsOpen(false);
  };

  return (
    <div className={cn("flex gap-2", className)} {...props}>
      <ClickableIcon
        Icon={MinusCircleIcon}
        name="Remove from playlist"
        tooltip="Remove from playlist"
        className="p-0 text-xs font-normal text-neutral-700 hover:text-neutral-400"
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(true);
        }}
      />

      <Dialog open={isOpen} onOpenChange={setIsOpen} modal={true}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader className="text-left">
            <DialogTitle className="text-neutral">
              Remove from Playlist
            </DialogTitle>
            <DialogDescription>
              Are you sure you want to remove this item from the playlist?
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="gap-2">
            <Button
              variant="outline"
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button variant="destructive" onClick={handleRemove}>
              Remove
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default RemoveFromPlaylistPopover;
