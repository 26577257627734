import { useSelectedMediaStore } from "@/store/selectedMediaStore";
import { ScrollArea } from "@kino/ui";
import { constructMomentFromMomentId, formatMomentId } from "@/utils/moment";
import { Highlight } from "@/utils/highlights";
import ResultsGrid from "@/components/omniSearch/ResultsGrid";
import { PreviewFrame } from "../previewFrames/PreviewFrame";

const MIN_INTERVAL = 5;
const MAX_PREVIEWS = 20;

export const InspectorFramePreviews = () => {
  const selectedMedia = useSelectedMediaStore(
    (state) => state.currentSelection,
  );
  const singleSelection =
    selectedMedia?.length === 1 ? selectedMedia[0] : undefined;

  if (!singleSelection) {
    return null;
  }

  const { duration } = singleSelection.mediaItem.ffprobe_data;
  const interval = Math.max(MIN_INTERVAL, duration / MAX_PREVIEWS);
  const numPreviews = Math.min(Math.floor(duration / interval), MAX_PREVIEWS);

  const previews: Highlight[] = Array.from({ length: numPreviews }, (_, i) => {
    const start = i * interval;
    const end = start + Math.min(duration, interval);

    return {
      ...constructMomentFromMomentId(
        formatMomentId({
          mediaItemId: singleSelection.mediaItem.id,
          start,
          end,
        }),
        singleSelection.mediaItem,
      ),
      description: "",
    };
  });

  return (
    <ScrollArea className="h-full w-full p-4">
      <ResultsGrid scale={1}>
        {previews.map((preview) => (
          <PreviewFrame
            key={preview.id}
            highlight={preview}
            mediaItem={singleSelection.mediaItem}
          />
        ))}
      </ResultsGrid>
    </ScrollArea>
  );
};
