import { components } from "@/openapi-bindings/v2";
import { constructMomentFromMomentId, formatMomentId } from "./moment";

type TimestampString = `${number}:${number}:${number}`;
export type Highlight = components["schemas"]["Moment"] & {
  description: string;
};

const parseTimestampString = (timestamp: TimestampString): number => {
  try {
    const [hours = 0, minutes = 0, seconds = 0] = timestamp
      .split(":")
      .map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  } catch {
    return 0;
  }
};

export const constructMomentsFromHighlightString = (
  highlightString: string,
  mediaItem: components["schemas"]["MediaItem"],
): Highlight[] => {
  if (!highlightString || !mediaItem?.id) {
    return [];
  }

  try {
    return highlightString
      .split(";")
      .filter(Boolean)
      .map((highlight) => {
        try {
          const match = highlight.match(
            /\[(\d{2}:\d{2}:\d{2}),\s*(\d{2}:\d{2}:\d{2})\]:\s*(.*)/,
          );
          if (!match) {
            return null;
          }

          const [_, startStr, endStr, description] = match as [
            string,
            TimestampString,
            TimestampString,
            string,
          ];
          const start = parseTimestampString(startStr);
          const end = parseTimestampString(endStr);

          if (start >= end || start < 0) {
            return null;
          }

          return {
            ...constructMomentFromMomentId(
              formatMomentId({ mediaItemId: mediaItem.id, start, end }),
              mediaItem,
            ),
            description: description.trim(),
          };
        } catch {
          return null;
        }
      })
      .filter((moment): moment is Highlight => moment !== null);
  } catch {
    return [];
  }
};
