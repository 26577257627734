import React, { useState } from "react";
import { TextWithTooltip } from "@kino/ui";
import { toast } from "sonner";
import { CopyIcon } from "@radix-ui/react-icons";
import { LockIcon } from "lucide-react";

interface AdvancedPropertyProps {
  label: string;
  value?: string | number | null;
  isDevOnly?: boolean;
  children?: React.ReactNode;
}

const AdvancedProperty: React.FC<AdvancedPropertyProps> = ({
  label,
  value,
  isDevOnly = false,
  children,
}) => {
  const [isHover, setIsHover] = useState(false);

  const handleCopy = () => {
    if (!value) return;

    navigator.clipboard.writeText(value.toString());
    toast.success(`${label} copied to clipboard`);
  };

  const renderValue = () => {
    if (value === "" || value === null || value === undefined) {
      return <span className="text-neutral-700">N/A</span>;
    }
    return (
      <div className="word-break flex w-full whitespace-break-spaces break-all text-xs text-neutral-400">
        {value}
      </div>
    );
  };

  const renderCopyButton = () => {
    if (!isHover || !value) return null;

    return (
      <div className="absolute right-1 top-1 z-10 flex items-center justify-center rounded bg-neutral-800 p-1">
        <TextWithTooltip tooltipText="Click to copy" position="top">
          <CopyIcon
            onClick={handleCopy}
            className="h-3 w-3 cursor-pointer text-neutral-400"
          />
        </TextWithTooltip>
      </div>
    );
  };

  const renderContent = () => {
    if (children) return children;

    return (
      <div
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        className="relative flex min-h-6 flex-grow items-center gap-1 overflow-hidden rounded bg-neutral-800/70 px-2 py-1"
      >
        {renderValue()}
        {renderCopyButton()}
      </div>
    );
  };

  return (
    <div className="flex w-full cursor-default items-start justify-between gap-1">
      <div className="flex w-1/3 items-center gap-1">
        {isDevOnly && <LockIcon className="h-3 w-3 text-neutral-500" />}
        {label}
      </div>
      <div className="flex w-2/3 overflow-hidden">{renderContent()}</div>
    </div>
  );
};

export default AdvancedProperty;
