import React from "react";
import { Separator } from "@kino/ui";
import { ColDef } from "ag-grid-community";
import { components } from "@/openapi-bindings/v2";
import useAGMediaGrid from "@/hooks/ag-grid/useAGMediaGrid";
import { useSelectedMediaStore } from "@/store/selectedMediaStore";
import { useDisplayStore } from "@/store/display/displayStore";
import AdvancedProperty from "@/components/inspector/propertiesCard/AdvancedProperty";
import { useCanAccessDevFeatures } from "@/hooks/useCanAccessDevFeatures";
import { secondsToTimestamp } from "@/utils/time";

interface PropertyRendererProps {
  def: ColDef<components["schemas"]["MediaItem"]>;
  mediaItem: components["schemas"]["MediaItem"];
}

const PropertyRenderer = ({ def, mediaItem }: PropertyRendererProps) => {
  const getValue = (): { formatted: string; raw: string | null } => {
    try {
      let rawValue;
      if (typeof def.valueGetter === "function") {
        rawValue = def.valueGetter({ data: mediaItem } as any);
      } else {
        const path = def.valueGetter || def.field;
        if (path) {
          rawValue = path
            .split(".")
            .reduce<unknown>(
              (obj, key) => (obj as Record<string, unknown>)?.[key],
              mediaItem as Record<string, unknown>,
            );
        }
      }

      if (typeof def.valueFormatter === "function") {
        return {
          formatted: def.valueFormatter({
            value: rawValue,
            data: mediaItem,
          } as any),
          raw: rawValue,
        };
      }

      return {
        formatted: rawValue,
        raw: rawValue,
      };
    } catch (err) {
      return {
        formatted: `Error: ${(err as Error).message}`,
        raw: null,
      };
    }
  };

  const { formatted } = getValue();

  return (
    <AdvancedProperty
      label={def.headerName || def.field || "Unknown"}
      value={def.cellRenderer ? undefined : formatted}
      isDevOnly={def.hide !== undefined}
    >
      {/* TODO: There are more edge cases here to cover. Ideally it always shows the empty N/A */}
      {def.cellRenderer &&
        formatted &&
        formatted !== "" &&
        formatted !== "[]" && (
          <div className="no-scrollbar w-full overflow-x-scroll">
            {def.cellRenderer({
              value: formatted,
              data: mediaItem,
              ...(def.cellRendererParams || {}),
            })}
          </div>
        )}
    </AdvancedProperty>
  );
};

const MetadataSection = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => (
  <div className="flex flex-col">
    <div className="font-medium text-neutral-300">{title}</div>
    <div className="flex flex-col divide-y divide-neutral-800/50 *:py-2 first:*:pt-4 last:*:pb-4">
      {children}
    </div>
  </div>
);

const Metadata = () => {
  const { mode } = useDisplayStore();
  const { columnDefs } = useAGMediaGrid({ displayMode: mode });
  const canAccessDevFeatures = useCanAccessDevFeatures();
  const { selectedMedia, numSelectedMedia } = useSelectedMediaStore(
    (state) => ({
      selectedMedia: state.currentSelection ?? [],
      numSelectedMedia: state.currentSelection?.length ?? 0,
    }),
  );
  const singleSelection = numSelectedMedia === 1 ? selectedMedia[0] : undefined;

  const { mediaItem, activeInspectorMoment } = singleSelection ?? {};
  const moment = activeInspectorMoment?.moment;

  if (!mediaItem) {
    return null;
  }

  const visibleColumns = columnDefs.filter(
    (def) => !def.hide && def.colId !== "index",
  );

  return (
    <div className="flex flex-col gap-2 p-2 pt-0 text-xs text-neutral-600">
      {moment && (
        <>
          <MetadataSection title="Moment Metadata">
            <AdvancedProperty
              label="Moment Start"
              value={secondsToTimestamp(moment.start)}
            />
            <AdvancedProperty
              label="Moment End"
              value={secondsToTimestamp(moment.end)}
            />
            {canAccessDevFeatures && (
              <AdvancedProperty label="Moment ID" value={moment.id} isDevOnly />
            )}
          </MetadataSection>
        </>
      )}

      <MetadataSection title="File Metadata">
        {visibleColumns.map((def, index) => (
          <React.Fragment key={def.colId || index}>
            <PropertyRenderer def={def} mediaItem={mediaItem} />
          </React.Fragment>
        ))}
      </MetadataSection>
    </div>
  );
};

export default Metadata;
