import { cn } from "@/utils/tailwind";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@kino/ui";

interface TabProps {
  icon: React.ReactNode;
  label?: string;
  value: string;
  onClick?: () => void;
  isActive?: boolean;
  tooltip?: {
    content: React.ReactNode;
    shortcutKeys?: string;
  };
}

export const Tab: React.FC<TabProps> = ({
  icon,
  label,
  onClick,
  isActive = false,
  tooltip,
}) => {
  const tabContent = (
    <div
      onClick={onClick}
      className={cn(
        "flex h-5 cursor-pointer items-center justify-start gap-1 text-xs transition-all",
        isActive ? "text-white" : "text-neutral-600 hover:text-white",
        `group/${label}`,
      )}
    >
      <div
        className={cn(
          "flex h-4 w-4 items-center",
          isActive
            ? "text-indigo-500"
            : `text-neutral-500 group-hover/${label}:text-indigo-500`,
        )}
      >
        {icon}
      </div>
      {label && <div>{label}</div>}
    </div>
  );

  if (tooltip) {
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>{tabContent}</TooltipTrigger>
          <TooltipContent side={"bottom"} shortcutKeys={tooltip.shortcutKeys}>
            {tooltip.content}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }

  return tabContent;
};
