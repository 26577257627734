import React from "react";
import { Tag } from "@/types/filters";
import { CommandItem, Checkbox } from "@kino/ui";
import { FilterBadgeTag } from "./FilterBadgeTag";
import { useFilterState } from "@/hooks/useFilterState";

interface TagsListProps {
  tags: Tag[];
}

const TagsList = ({ tags }: TagsListProps) => {
  const { updateFilterState, searchState } = useFilterState();
  const filterState = searchState.filterState;
  const handleCheck = (tag: Tag) => {
    updateFilterState("tag_ids", [tag.id]);
  };

  return (
    <div className="flex flex-col gap-2">
      {tags.map((tag) => (
        <CommandItem
          key={tag.id}
          value={tag.value}
          disableDefaultHoverStyle
          className={`flex items-center gap-2 p-0 text-neutral-400 ${tag.usage_count === 0 ? "opacity-50" : ""}`}
        >
          <Checkbox
            checked={filterState.tag_ids?.some((t) => t === tag.id)}
            onCheckedChange={() => handleCheck(tag)}
          />
          <FilterBadgeTag tag={tag} displayOnly={true} focused={false} />
          <div className="truncate text-ellipsis whitespace-nowrap">
            {tag.usage_count}
          </div>
        </CommandItem>
      ))}
    </div>
  );
};
export default TagsList;
