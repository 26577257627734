import { components } from "@/openapi-bindings/v2";
import { MomentId } from "@/store/playlists/playlistsStore";

type ParsedMomentId = {
  mediaItemId: string;
  start: number;
  end: number;
};

export const parseMomentId = (momentId: MomentId): ParsedMomentId | null => {
  try {
    const parts = momentId.split("_moment_");
    if (parts.length !== 2) {
      console.warn(`Invalid moment ID format: ${momentId}`);
      return null;
    }

    const mediaItemId = parts[0];
    const timestamps = parts[1];

    if (!mediaItemId || !timestamps) {
      console.warn(`Invalid moment ID components: ${momentId}`);
      return null;
    }

    const timestampParts = timestamps.split("_");
    if (timestampParts.length !== 2) {
      console.warn(`Invalid timestamp format: ${momentId}`);
      return null;
    }

    const start = Number(timestampParts[0]);
    const end = Number(timestampParts[1]);

    if (!Number.isFinite(start) || !Number.isFinite(end)) {
      console.warn(`Invalid moment ID timestamps: ${momentId}`);
      return null;
    }

    return { mediaItemId, start, end };
  } catch (error) {
    console.error(`Unexpected error parsing moment ID: ${momentId}`, error);
    return null;
  }
};

export const formatMomentId = (momentId: ParsedMomentId): MomentId => {
  return `${momentId.mediaItemId}_moment_${momentId.start}_${momentId.end}`;
};

const getTranscriptSegmentIds = (
  start: number,
  end: number,
  transcript: components["schemas"]["MediaItem"]["transcript"],
): string[] => {
  return (
    transcript?.segments
      ?.filter((segment) => segment.start >= start && segment.end <= end)
      .map((segment) => segment.id) ?? []
  );
};

export const constructMomentFromMomentId = (
  momentId: MomentId,
  mediaItem: components["schemas"]["MediaItem"],
): components["schemas"]["Moment"] => {
  const parsedMomentId = parseMomentId(momentId);
  if (!parsedMomentId) {
    throw new Error("Invalid moment ID");
  }
  const { mediaItemId, start, end } = parsedMomentId;
  const transcriptSegmentIds = getTranscriptSegmentIds(
    start,
    end,
    mediaItem.transcript,
  );

  const transcriptText = transcriptSegmentIds.map((id) => {
    const segment = mediaItem.transcript?.segments?.find((s) => s.id === id);
    return segment?.text;
  });

  const relevantTranscriptText = transcriptText.join(" ");

  return {
    id: momentId,
    start,
    end,
    media_item_id: mediaItemId,
    hls_manifest_path: mediaItem.hls_manifest_path,
    smpte_start_timecode: "",
    smpte_end_timecode: "",
    smpte_timecode_offset: 0,
    transcript_segment_ids: transcriptSegmentIds,
    relevant_transcript_text: relevantTranscriptText,
    tag_ids: mediaItem.tag_ids, // TODO: Not timestamped to moment
    person_ids: mediaItem.person_ids, // TODO: Not timestamped to moment
  };
};
