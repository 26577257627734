import { useOrganization } from "@clerk/clerk-react";

export const useClerkMemberships = () => {
  const { memberships } = useOrganization({
    memberships: {
      infinite: true,
      keepPreviousData: true,
    },
  });

  const getClerkUser = (clerkUserId: string) => {
    return memberships?.data?.find(
      (membership) => membership.publicUserData.userId === clerkUserId,
    );
  };

  return { memberships, getClerkUser };
};
