import { useEffect, useState } from "react";
import { useClerkServerInfo } from "@/hooks/useClerkMetadata";
import { useActiveServerStore } from "@/store/activeServerStore";

interface ServerProviderProps {
  children: React.ReactNode;
}

export function ServerProvider({ children }: ServerProviderProps) {
  const { apiUrl } = useClerkServerInfo();
  const setBaseUrl = useActiveServerStore((state) => state.setBaseUrl);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (apiUrl) {
      setBaseUrl(apiUrl);
      setIsInitialized(true);
    }
  }, [apiUrl, setBaseUrl]);

  // Optional: Show loading state while server URL is being initialized
  if (!isInitialized) {
    return null;
  }

  return <>{children}</>;
}

// Hook to override server URL temporarily (e.g. for development/testing)
export function useOverrideServerUrl() {
  const setOverrideUrl = useActiveServerStore((state) => state.setOverrideUrl);
  const { apiUrl } = useClerkServerInfo();

  return {
    setOverride: (url: string | null) => {
      setOverrideUrl(url);
    },
    clearOverride: () => {
      setOverrideUrl(null);
    },
  };
}
