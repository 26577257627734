import { Separator, Tabs, TabsContent, TabsList, TabsTrigger } from "@kino/ui";
import { useMemo } from "react";
import { InspectorSearchResultSummary } from "@/components/inspector/tabs/InspectorSearchResultSummary";
import { InspectorBasicPropertiesTab } from "./InspectorBasicPropertiesTab";
import { CardStackIcon } from "@radix-ui/react-icons";
import { getFileName } from "@/utils/pathUtils";
import { InspectorTranscript } from "@/components/inspector/tabs/InspectorTranscript";
import PanelHeader from "@/layouts/panels/PanelHeader";
import { useSelectedMediaStore } from "@/store/selectedMediaStore";
import { Tab } from "@/components/shared/Tab";
import {
  Code2Icon,
  FileTypeIcon,
  ImageIcon,
  MessageCircleIcon,
  StarIcon,
} from "lucide-react";
import {
  InspectorTab,
  useInspectorTabStore,
} from "@/store/inspectorActiveTabStore";
import { InspectorTabValues } from "@/store/inspectorActiveTabStore";
import { InspectorCommentsTab } from "./InspectorComments";
import { useDebuggerStore } from "@/store/debuggerStore";
import { InspectorHighlightsTab } from "./InspectorHighlights";
import { InspectorFramePreviews } from "./InspectorFramePreviews";

const InspectorTabsEmptyState: React.FC = () => {
  return (
    <div className="flex h-full w-full items-center justify-center text-xs text-neutral-700">
      Nothing to Inspect
    </div>
  );
};

interface InspectorMultipleMediaState {
  numSelectedMedia: number;
}

const InspectorMultipleMediaState: React.FC<InspectorMultipleMediaState> = ({
  numSelectedMedia,
}) => {
  return (
    <div className="flex h-64 w-full items-center justify-center text-xs text-neutral-700">
      {numSelectedMedia} selected
    </div>
  );
};

const InspectorTabs = () => {
  const showComments = useDebuggerStore((state) => state.showComments);
  const selectedMedia = useSelectedMediaStore(
    (state) => state.currentSelection ?? [],
  );
  const numSelectedMedia = selectedMedia.length;
  const singleSelection = numSelectedMedia === 1 ? selectedMedia[0] : undefined;

  const { activeTab, setActiveTab } = useInspectorTabStore();

  const tabs: InspectorTab[] = useMemo(
    () => [
      {
        value: InspectorTabValues.SearchResultSummary,
        label: "Search Result",
        icon: CardStackIcon,
        content: <InspectorSearchResultSummary />,
        hidden: true,
      },
      {
        value: InspectorTabValues.Transcript,
        label: "Transcript",
        icon: FileTypeIcon,
        content: <InspectorTranscript />,
      },
      {
        value: InspectorTabValues.FramePreviews,
        label: "Preview",
        icon: ImageIcon,
        content: <InspectorFramePreviews />,
        hidden: !singleSelection,
      },
      {
        value: InspectorTabValues.Highlights,
        label: "Highlights",
        icon: StarIcon,
        content: <InspectorHighlightsTab />,
        hidden: !singleSelection || !singleSelection.mediaItem.moments,
      },
      {
        value: InspectorTabValues.Metadata,
        label: "Metadata",
        icon: Code2Icon,
        content: <InspectorBasicPropertiesTab />,
      },
      {
        value: InspectorTabValues.Comments,
        label: "Comments",
        icon: MessageCircleIcon,
        content: <InspectorCommentsTab />,
        hidden: !showComments,
      },
    ],
    [showComments],
  );

  const filteredTabs = tabs.filter((tab) => !tab.hidden);

  return (
    <Tabs
      defaultValue={InspectorTabValues.Transcript}
      onValueChange={(value) => setActiveTab(value as InspectorTabValues)}
      value={activeTab}
      className="flex h-full w-full flex-col overflow-hidden"
    >
      <PanelHeader className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-1">Inspector</div>
        {/* {singleSelection?.mediaItem && ( */}
        {/* <span className="truncate text-ellipsis text-xs text-neutral-400"> */}
        {/* {getFileName( */}
        {/* singleSelection.mediaItem.ffprobe_data?.format?.filename ?? "", */}
        {/* )} */}
        {/* </span> */}
        {/* )} */}
      </PanelHeader>

      <TabsList className="flex h-10 max-h-10 min-h-10 w-full justify-start gap-2 overflow-hidden border-b p-2 text-xs font-normal">
        {filteredTabs.map((tab, i) => (
          <div key={tab.value} className="flex items-center">
            <TabsTrigger
              value={tab.value}
              className="p-0"
              disabled={!singleSelection}
            >
              <Tab
                icon={<tab.icon />}
                value={tab.label}
                label={tab.label}
                isActive={activeTab === tab.value}
              />
            </TabsTrigger>
            {i !== filteredTabs.length - 1 && (
              <Separator
                orientation="vertical"
                className="ml-2 h-3 bg-neutral-700"
              />
            )}
          </div>
        ))}
      </TabsList>

      {numSelectedMedia === 0 && <InspectorTabsEmptyState />}
      {numSelectedMedia > 1 && (
        <InspectorMultipleMediaState numSelectedMedia={numSelectedMedia} />
      )}
      {singleSelection &&
        filteredTabs.map((tab) => (
          <TabsContent
            key={tab.value}
            value={tab.value}
            asChild
            className="m-0"
          >
            <div className="flex h-full flex-col overflow-hidden">
              {tab.content}
            </div>
          </TabsContent>
        ))}
    </Tabs>
  );
};

export { InspectorTabs };
