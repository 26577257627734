import {
  create as _create,
  StateCreator,
  StoreMutatorIdentifier,
} from "zustand";

export enum StoreCollectionIdentifier {
  LAYOUT = "layout",
  SEARCH = "SEARCH",
  ORGANIZATION = "organization",
  DISPLAY = "DISPLAY",
}
const storeResetFns = new Map<
  "all" | StoreCollectionIdentifier,
  Set<() => void>
>();

type AnyMiddleware = [StoreMutatorIdentifier, unknown];

const createClearable = (identifiers: StoreCollectionIdentifier[] = []) => {
  return <T>() => {
    return <Middlewares extends AnyMiddleware[] = []>(
      initializer: StateCreator<T, [], Middlewares> | StateCreator<T>,
    ) => {
      const store = _create<T>()(initializer as StateCreator<T>);
      const initialState = store.getState();
      const allStores = storeResetFns.get("all") ?? new Set();
      allStores.add(() => store.setState(initialState, true));
      storeResetFns.set("all", allStores);

      for (const identifier of identifiers) {
        const storeCollection = storeResetFns.get(identifier) ?? new Set();
        storeCollection.add(() => store.setState(initialState, true));
        storeResetFns.set(identifier, storeCollection);
      }

      return store;
    };
  };
};

export const resetClearableStores = (
  identifiers: Array<StoreCollectionIdentifier>,
) => {
  const storeCollections = identifiers.map((identifier) =>
    storeResetFns.get(identifier),
  );

  storeCollections.forEach((store) => store?.forEach((clearFn) => clearFn()));
};

export const resetAllClearableStores = () => {
  storeResetFns.get("all")?.forEach((storeClearFn) => storeClearFn());
};

export { createClearable };
