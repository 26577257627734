import React, { useState, useEffect, useRef, HTMLAttributes } from "react";
import styles from "./ResultsGrid.module.css";
import { cn } from "@/utils/tailwind";

interface ResultsGridProps extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  truncate?: boolean;
  scale?: number;
}

const ResultsGrid = ({
  children,
  truncate = false,
  className,
  scale = 0,
}: ResultsGridProps) => {
  const [columnCount, setColumnCount] = useState(1);
  const gridRef = useRef<HTMLDivElement>(null);

  // We are using this instead of media queries because we're dealing with panels.
  useEffect(() => {
    const updateColumnCount = () => {
      if (!gridRef.current) return;

      const gridWidth = gridRef.current.offsetWidth;

      if (gridWidth >= 1920) setColumnCount(6);
      else if (gridWidth >= 1280)
        setColumnCount(5 + scale); // xl
      else if (gridWidth >= 1024)
        setColumnCount(4 + scale); // lg
      else if (gridWidth >= 768)
        setColumnCount(3 + scale); // md
      else if (gridWidth >= 640)
        setColumnCount(3 + scale); // sm
      else if (gridWidth >= 400)
        setColumnCount(2 + scale); // xsm
      else setColumnCount(1 + scale); // xs
    };

    updateColumnCount();

    const resizeObserver = new ResizeObserver(updateColumnCount);
    if (gridRef.current) {
      resizeObserver.observe(gridRef.current);
    }

    return () => {
      if (gridRef.current) {
        resizeObserver.unobserve(gridRef.current);
      }
    };
  }, []);

  const visibleChildren = truncate
    ? React.Children.toArray(children).slice(0, columnCount)
    : React.Children.toArray(children);

  return (
    <div
      ref={gridRef}
      className={cn(styles.resultsGrid, className)}
      style={{ "--column-count": columnCount } as React.CSSProperties}
    >
      {visibleChildren}
    </div>
  );
};

export default ResultsGrid;
