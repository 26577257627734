import { Navigate } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";
import Loading from "@/layouts/Loading";
import SignInPage from "./sign-in";

export default function IndexPage() {
  const { isSignedIn, isLoaded } = useAuth();

  if (!isLoaded) {
    return <Loading className="h-screen" />;
  } else if (!isSignedIn) {
    return <SignInPage />;
  } else {
    return <Navigate to="/media" replace />;
  }
}
