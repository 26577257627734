import * as React from "react";

interface KeyboardKeyProps {
  children: React.ReactNode;
  className?: string;
}

const formatKey = (key: string): string => {
  const specialKeys: Record<string, string> = {
    alt: "⌥",
    ctrl: "Ctrl",
    meta: "⌘",
    shift: "⇧",
    mod: navigator.platform.includes("Mac") ? "⌘" : "Ctrl",
    command: "⌘",
    option: "⌥",
    enter: "↵",
    return: "↵",
    left: "←",
    right: "→",
    up: "↑",
    down: "↓",
    plus: "+",
    space: "␣",
    backspace: "⌫",
    delete: "⌦",
    escape: "Esc",
    tab: "⇥",
  };

  return specialKeys[key.toLowerCase()] || key.toUpperCase();
};
export const KeyboardKey = React.forwardRef<HTMLSpanElement, KeyboardKeyProps>(
  ({ children, className }, ref) => {
    return (
      <span
        ref={ref}
        style={{
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          width: "20px",
          height: "20px",
          background:
            "linear-gradient(to bottom, rgb(45, 55, 71), rgb(21, 31, 45))",
          borderRadius: "4px",
          color: "rgb(229, 231, 235)",
          fontFamily: "ui-monospace, SFMono-Regular, Menlo",
          fontSize: "12px",
          fontWeight: "500",
          boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
          border: "1px solid rgb(75, 85, 99)",
        }}
        className={className}
      >
        {typeof children === "string" ? formatKey(children) : children}
      </span>
    );
  },
);

interface KeyboardShortcutProps {
  shortcut: string;
}

export const KeyboardShortcut: React.FC<KeyboardShortcutProps> = ({
  shortcut,
}) => {
  const keys = shortcut.split("+").map((key) => key.trim());

  return (
    <span className="inline-flex items-center gap-0.5">
      {keys.map((key, index) => (
        <React.Fragment key={index}>
          <KeyboardKey>{key}</KeyboardKey>
          {index < keys.length - 1 && (
            <span style={{ color: "rgb(156, 163, 175)" }}></span>
          )}
        </React.Fragment>
      ))}
    </span>
  );
};

KeyboardKey.displayName = "KeyboardKey";
