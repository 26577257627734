import { UserBadge } from "../shared/UserBadge";
import { getTotalPlaylistDuration } from "@/utils/playlist";
import { DotIcon } from "lucide-react";
import { components } from "@/openapi-bindings/v2";

interface PlaylistMetadataProps {
  playlist: components["schemas"]["Playlist"];
  userImageUrl?: string;
  userFirstName?: string;
}

const PlaylistMetadata = ({
  playlist,
  userImageUrl,
  userFirstName,
}: PlaylistMetadataProps) => {
  return (
    <div className="flex items-center gap-2">
      {userImageUrl && userFirstName && (
        <UserBadge imageUrl={userImageUrl} firstName={userFirstName} />
      )}
      <div className="flex items-center">
        <div className="whitespace-nowrap text-xs text-neutral-400">
          {playlist.modified_at
            ? new Date(playlist.modified_at).toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
              })
            : ""}
        </div>
        <DotIcon className="h-4 w-4 text-neutral-400" />

        <div className="whitespace-nowrap text-xs text-neutral-400">
          {getTotalPlaylistDuration(playlist)}
        </div>
        <DotIcon className="h-4 w-4 text-neutral-400" />
        {playlist.moments?.length !== undefined && (
          <div className="whitespace-nowrap text-xs text-neutral-400">
            {playlist.moments?.length} moments
          </div>
        )}
      </div>
    </div>
  );
};

export default PlaylistMetadata;
