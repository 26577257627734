import React, { useCallback, useState, useEffect } from "react";
import {
  MediaErrorDetail,
  Player,
  useMediaPlayer,
  useMediaRemote,
  useMediaStore,
} from "@kino/player";
import {
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  Tooltip,
} from "@kino/ui";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { components } from "@/openapi-bindings/v2";
import { useActiveServerStore } from "@/store/activeServerStore";
import { cn } from "@/utils/tailwind";
import OmniSearchCardThumbnail from "./OmniSearchCardThumbnail";
import { useSearchDisplayStore } from "@/store/search/searchDisplayStore";
import {
  getEffectiveMode,
  useDisplayStore,
} from "@/store/display/displayStore";
import { match } from "ts-pattern";
import { useMediaItemTimecode } from "@/hooks/useMediaItemTimecode";

export const SeekByHoverPlayerGesture = ({
  clipStartTime,
  clipEndTime,
}: {
  clipStartTime: number;
  clipEndTime: number;
}) => {
  const player = useMediaPlayer();
  const remote = useMediaRemote();
  const { currentTime, paused } = useMediaStore();
  const { advancedSettings } = useSearchDisplayStore();

  const [cursorX, setCursorX] = useState(0);
  const [isHovering, setIsHovering] = useState(false);

  const clipDuration = clipEndTime - clipStartTime;

  const seekOnHover: React.MouseEventHandler = useCallback(
    (e) => {
      const target = e.currentTarget as HTMLDivElement;
      const rect = target.getBoundingClientRect();
      const offsetX = e.clientX - rect.left;
      const width = target.offsetWidth;

      if (offsetX >= 0 && offsetX <= width) {
        const fraction = Math.min(Math.max(offsetX / width, 0), 1);
        // Just use fraction of clipDuration since currentTime is already relative
        const seekTime = fraction * clipDuration;

        setCursorX(offsetX);
        remote.seek(seekTime);
        if (!advancedSettings.loop) {
          remote.pause();
        }
      }
    },
    [clipDuration, remote, advancedSettings.loop],
  );

  const handlePointerLeave = () => {
    setIsHovering(false);
    if (!advancedSettings.loop) {
      remote.seek(0); // Seek to start of clip (relative time)
      remote.pause();
    }
    player?.el?.blur();
  };

  // currentTime is already relative to clip start
  const playingCursorX = (currentTime / clipDuration) * 100;

  return (
    <div
      className="pointer-events-auto absolute inset-0 z-0 block h-full w-full cursor-default"
      onPointerMove={seekOnHover}
      onPointerEnter={() => setIsHovering(true)}
      onPointerLeave={handlePointerLeave}
    >
      {isHovering && (
        <>
          <div
            className="absolute bottom-0 top-0 z-10 w-px bg-red-500"
            style={{ left: cursorX }}
          />
          {!paused && (
            <div
              className="absolute bottom-0 top-0 w-px bg-white/60"
              style={{ left: `${playingCursorX}%` }}
            />
          )}
        </>
      )}
    </div>
  );
};

export const Time = () => {
  const { clipStartTime, currentTime } = useMediaStore();
  const startTimecode = useMediaItemTimecode(clipStartTime + currentTime);

  return (
    <div className=" text-xsm absolute left-2 top-2 z-50 hidden w-auto rounded bg-black/50 p-1 text-white antialiased backdrop-blur-sm group-hover/player:block">
      {startTimecode.toString()}
    </div>
  );
};

// export const TimeCountdown = () => {
//   const { clipStartTime, clipEndTime, currentTime } = useMediaStore();
//   const endTimecode = useMediaItemTimecode(
//     clipEndTime - (currentTime + clipStartTime),
//   );

//   return (
//     <div className=" text-xsm absolute right-2 top-2 z-50 hidden w-auto rounded bg-black/50 p-1 text-white antialiased backdrop-blur-sm group-hover/player:block">
//       {endTimecode.toString()}
//     </div>
//   );
// };

export const VideoLoadingError: React.FC<{ error: MediaErrorDetail }> = ({
  error,
}) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger className="absolute right-2 top-2 rounded-full bg-gray-500/40 p-1">
          <ExclamationTriangleIcon className="h-3 w-3 text-white" />
        </TooltipTrigger>
        <TooltipContent>{error.message}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

interface OmniSearchCardPlayerProps {
  moment: components["schemas"]["Moment"];
  mediaItem: components["schemas"]["MediaItem"] | null;
}

export const OmniSearchCardPlayer: React.FC<OmniSearchCardPlayerProps> = ({
  moment,
  mediaItem,
}) => {
  const [videoLoadingError, setVideoLoadingError] =
    useState<MediaErrorDetail | null>(null);
  const { buildServerUrl } = useActiveServerStore();
  const [seekCount, setSeekCount] = useState(0);
  const { advancedSettings } = useSearchDisplayStore();
  const remote = useMediaRemote();
  const mode = useDisplayStore(getEffectiveMode);
  const clipStartTime = match(mode)
    .with("s48", () => moment.start ?? 0 + moment.smpte_timecode_offset)
    .otherwise(() => moment.start ?? 0);
  const clipEndTime = match(mode)
    .with("s48", () => moment.end ?? 0 + moment.smpte_timecode_offset)
    .otherwise(() => moment.end ?? 0);

  // Add effect to handle autoplay when looping is toggled
  useEffect(() => {
    if (advancedSettings.loop) {
      remote.play();
    }
  }, [advancedSettings.loop, remote]);

  return (
    <div className="relative flex aspect-video w-full items-center justify-center overflow-hidden bg-neutral-900 object-contain">
      {!videoLoadingError ? (
        <Player
          src={buildServerUrl(moment.hls_manifest_path)}
          key={moment.id + advancedSettings.loop} // TODO: This is a hack to force the player to re-render when the loop state changes
          clipStartTime={clipStartTime}
          clipEndTime={clipEndTime}
          useDefaultControls={false}
          load="eager" // most reliably makes video preview load
          muted={true}
          loop={advancedSettings.loop}
          onSeeked={() => {
            // Ensures video is seeked to clipStartTime before showing preview
            // Feels hacky but thus far is the most reliable way to ensure the video is seeked to the start
            if (seekCount === 0) {
              setSeekCount((prev) => prev + 1);
            }
          }}
          onEnded={() => {
            if (advancedSettings.loop) {
              remote.seek(0);
              remote.play();
            }
          }}
          duration={
            mediaItem?.ffprobe_data?.format?.duration
              ? Number(mediaItem.ffprobe_data.format.duration)
              : undefined
          }
          frameRate={
            mediaItem?.ffprobe_data?.framerate_numerator &&
            mediaItem?.ffprobe_data?.framerate_denominator
              ? [
                  mediaItem.ffprobe_data.framerate_numerator,
                  mediaItem.ffprobe_data.framerate_denominator,
                ]
              : undefined
          }
          autoPlay={advancedSettings.loop}
          onError={(error) => {
            console.error(
              "Video loading error code",
              error.code,
              error.message,
            );
            setVideoLoadingError(error);
          }}
          className={cn(
            "group/player transition-opacity duration-200",
            seekCount > 0 ? "opacity-100" : "opacity-0",
          )}
          customGestures={
            <SeekByHoverPlayerGesture
              clipStartTime={clipStartTime}
              clipEndTime={clipEndTime}
            />
          }
          customControls={<Time />}
        />
      ) : (
        <OmniSearchCardThumbnail src={moment.thumbnail_path ?? ""} />
      )}
      {videoLoadingError && <VideoLoadingError error={videoLoadingError} />}
    </div>
  );
};
