import React, { useMemo } from "react";
import {
  arrayFilterKeys,
  type ArrayFilterKeys,
  type BaseFilters,
  type MediaFilterKey,
} from "@/types/filters";
import { FilterBadgeTag } from "../components/filter/filterPanel/tags/FilterBadgeTag";
import { FilterBadgeArray } from "../components/filter/filterPanel/array/FilterBadgeArray";
import { useSearchParams } from "react-router-dom";
import { useURLSearchParams } from "@/hooks/useURLSearchParams";
import { useGetTagsQuery } from "@/hooks/useGetTagsQuery";
import { useFilterState } from "./useFilterState";

const useRenderFilterBadges = () => {
  const [searchParams] = useSearchParams();
  const { decodeURLSearchParams } = useURLSearchParams();
  const filterState = decodeURLSearchParams(searchParams).filterState;
  const { data: tags } = useGetTagsQuery();
  const { updateFilterState } = useFilterState();

  const hydratedTags = useMemo(() => {
    if (filterState.tag_ids && tags) {
      return filterState.tag_ids.map((tagId) =>
        tags.find((tag) => tag.id === tagId),
      );
    }
    return [];
  }, [filterState.tag_ids, tags]);

  const isArrayFilterKey = useMemo(
    () =>
      (key: any): key is ArrayFilterKeys =>
        arrayFilterKeys.includes(key),
    [],
  );

  const getAppliedFilterKeys = () => {
    return Object.keys(filterState).filter(
      (key) => !isFilterEmpty(key as MediaFilterKey),
    ) as MediaFilterKey[];
  };

  const isFilterEmpty = (key: MediaFilterKey): boolean => {
    const value = filterState[key];
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return !value;
  };

  const getFilterBadges = (
    filters: BaseFilters,
    filterKey: MediaFilterKey,
    displayOnly = false,
    focused = true,
  ) => {
    if (filterKey === "tag_ids") {
      if (!tags) return null;

      return (
        <React.Fragment key={`tags-container-${filterState.tag_ids.join("-")}`}>
          {hydratedTags.map((tag, index) => {
            if (!tag) return null;
            return (
              <FilterBadgeTag
                key={`tag-${tag.id}-${index}`}
                tag={tag}
                displayOnly={displayOnly}
                focused={focused}
                onClose={() => {
                  updateFilterState("tag_ids", [tag.id]);
                }}
              />
            );
          })}
        </React.Fragment>
      );
    }

    if (isArrayFilterKey(filterKey)) {
      const typedFilterState = filterState[filterKey];
      const typedFilter = filters[filterKey];

      if (!typedFilterState || !typedFilter) return null;

      return (
        <>
          {typedFilterState.map((id, index) => {
            const filterOption = typedFilter.criteria?.find(
              (option) => option.id === id,
            );

            if (!filterOption) return null;

            return (
              <FilterBadgeArray
                key={`array-${filterKey}-${id}-${index}`}
                filterKey={filterKey}
                filterOption={filterOption}
              />
            );
          })}
        </>
      );
    }

    return null;
  };

  return {
    getFilterBadges,
    getAppliedFilterKeys,
    filterState,
  };
};

export default useRenderFilterBadges;
