import React, { useRef } from "react";
import { useSelectedMediaStore } from "@/store/selectedMediaStore";
import { CommentItem } from "@/components/collaboration/comments/CommentItem";
import {
  HydratedComment,
  useGetMediaComments,
} from "@/hooks/useGetMediaComments";
import { CommentInput } from "@/components/collaboration/comments/CommentInput";
import { CommentInputProvider } from "@/context/CommentInputContext";

const sortComments = (comments: HydratedComment[]) => {
  return [...comments].sort((a, b) => {
    if (a.startTime !== b.startTime) {
      return a.startTime - b.startTime;
    }

    if (a.endTime && b.endTime) {
      return a.endTime - b.endTime;
    }

    if (a.endTime && !b.endTime) {
      return 1;
    }
    if (!a.endTime && b.endTime) {
      return -1;
    }

    return a.id.localeCompare(b.id);
  });
};

const InspectorCommentsTab = () => {
  const selectedMedia = useSelectedMediaStore(
    (state) => state.currentSelection,
  );
  const numSelectedMedia = selectedMedia?.length ?? 0;
  const singleSelection =
    numSelectedMedia === 1 ? selectedMedia?.[0] : undefined;

  if (!singleSelection) {
    return null;
  }

  const { data: unsortedComments } = useGetMediaComments(
    singleSelection.mediaItem.ffprobe_data.duration,
  );
  const commentsSectionRef = useRef<HTMLDivElement>(null);

  const comments = unsortedComments ? sortComments(unsortedComments) : [];

  return (
    <CommentInputProvider>
      <div className="flex h-full w-full flex-col">
        <div
          className="flex-1 divide-y divide-neutral-800 overflow-y-auto"
          ref={commentsSectionRef}
        >
          {comments.map((comment) => (
            <CommentItem key={comment.id} comment={comment} />
          ))}
        </div>
        <div className="mt-auto border-t border-neutral-800">
          <CommentInput />
        </div>
      </div>
    </CommentInputProvider>
  );
};

export { InspectorCommentsTab };
