import { ICellRendererParams } from "ag-grid-community";
import { useDisplayTags } from "@/hooks/useGetTagTypeDefinition";
import { cn } from "@/utils/tailwind";
import { LucideIcon } from "lucide-react";
import { components } from "@/openapi-bindings/v2";

type Tag = components["schemas"]["Tag"];

export const PlainTagsRenderer = (props: ICellRendererParams) => {
  const { getTagTypeDefinition } = useDisplayTags();
  const tags = props.value as Tag[];
  if (!tags?.length) return null;

  return (
    <div className="flex h-full w-full items-center">
      <div className="flex flex-wrap gap-2">
        {tags.map((tag) => {
          const tagDef = getTagTypeDefinition(tag.tag_type);
          const Icon = tagDef.icon as LucideIcon;
          const baseColor = tagDef.baseColor;

          return (
            <div
              key={tag.id}
              className={cn(
                "flex h-6 items-center gap-1 rounded",
                "border border-neutral-400/20 px-[9px]",
              )}
            >
              <Icon className={cn("h-3.5 w-3.5", `text-${baseColor}-200`)} />
              <span className="text-xs text-neutral-200">{tag.value}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

PlainTagsRenderer.cellStyle = {
  display: "flex",
  alignItems: "center",
  padding: "0 8px",
};
