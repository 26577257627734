import { OmniSearchCardPlayer } from "@/components/omniSearch/cardModules/OmniSearchCardPlayer";
import { components } from "@/openapi-bindings/v2";
import { Highlight } from "@/utils/highlights";
import { secondsToTimestamp } from "@/utils/time";
import {
  Card,
  cn,
  ContextMenu,
  ContextMenuContent,
  ContextMenuTrigger,
} from "@kino/ui";
import {
  useMediaRemote,
  useMediaStore,
  usePrimaryViewportContext,
  ZOOM_EVENT_TYPE,
} from "@kino/player";
import OmniSearchCardTranscript from "@/components/omniSearch/cardModules/OmniSearchCardTranscript";
import { getTranscriptSegmentsByIds } from "@/utils/transcript";
import { AddToPlaylistSubContextMenu } from "@/components/playlists/AddToPlaylistSubContextMenu";
import { useFormatTimecodeForMediaItem } from "@/hooks/useMediaItemTimecode";

interface HighlightItemProps {
  highlight: Highlight;
  mediaItem: components["schemas"]["MediaItem"];
}

export const HighlightItem = ({ highlight, mediaItem }: HighlightItemProps) => {
  const { description, ...moment } = highlight;
  const { playerRef } = usePrimaryViewportContext();
  const { currentTime, clipStartTime } = useMediaStore(playerRef);
  const remote = useMediaRemote(playerRef);

  const formatTimecode = useFormatTimecodeForMediaItem(mediaItem.id);

  const currentTimeInRange =
    currentTime >= moment.start && currentTime < moment.end;

  const handleJumpToSegment = () => {
    remote.seek(
      moment.start - (clipStartTime || 0),
      new Event(ZOOM_EVENT_TYPE),
    );
  };

  const progressPercentage =
    ((currentTime - moment.start) / (moment.end - moment.start)) * 100;

  const transcriptSegments = getTranscriptSegmentsByIds(
    moment.transcript_segment_ids,
    mediaItem,
  );

  const firstSegment = transcriptSegments[0];

  return (
    <ContextMenu>
      <ContextMenuTrigger>
        <Card
          onClick={handleJumpToSegment}
          className={cn(
            "group relative flex w-full cursor-pointer gap-2 rounded-sm p-2 pl-0 hover:bg-neutral-800/60",
            currentTimeInRange &&
              "border border-indigo-500/60 transition-all hover:bg-indigo-500/20",
          )}
        >
          <div className="flex h-24 w-fit pl-2">
            <OmniSearchCardPlayer moment={moment} mediaItem={mediaItem} />
          </div>
          <div className="flex flex-col gap-1">
            <div className="text-xs text-neutral-200">{description}</div>
            <div className="text-xs text-neutral-500">
              {formatTimecode(moment.start ?? 0).toString()} -{" "}
              {formatTimecode(moment.end ?? 5).toString()}
            </div>
            <OmniSearchCardTranscript
              text={firstSegment?.text ?? ""}
              speakerId={firstSegment?.speaker_id ?? undefined}
            />
          </div>
          {currentTimeInRange && (
            <div
              className="absolute left-0 w-1.5 bg-indigo-400"
              style={{ top: `${progressPercentage}%`, height: "1px" }}
            />
          )}
        </Card>
      </ContextMenuTrigger>
      <ContextMenuContent>
        <AddToPlaylistSubContextMenu
          playlistMoment={{
            moment_id: moment.id,
            media_item_id: mediaItem.id,
            start_time: moment.start,
            end_time: moment.end,
            position: 0,
          }}
        />
      </ContextMenuContent>
    </ContextMenu>
  );
};
