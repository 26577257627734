export const formatStringPlurality = (
  num: number,
  singular: string,
  plural: string,
) => {
  return num === 1 ? singular : plural;
};

export const getServerUrl = (
  serverAddress: string,
  serverPort: string,
  useHttps: boolean,
) => {
  const protocol = useHttps ? "https" : "http";
  return `${protocol}://${serverAddress}:${serverPort}/`;
};

export const serverUrlToFormValues = (url: string) => {
  const parsedUrl = new URL(url);
  return {
    ip: parsedUrl.hostname,
    port: parsedUrl.port,
    useHttps: parsedUrl.protocol === "https:",
  };
};

export const getInspectorItemId = (mediaItemId: string, momentId?: string) => {
  return momentId ? `${mediaItemId}-${momentId}` : mediaItemId;
};

export const getInspectorItemIdsFromInspectorItemId = (
  inspectorItemId: string,
) => {
  const parts = inspectorItemId.split("-");
  return {
    mediaItemId: parts[0],
    momentId: parts.length > 1 ? parts[1] : undefined,
  };
};

export const getPersonNameInitials = (personName: string) => {
  return personName
    .split(" ")
    .map((word) => word[0])
    .join("")
    .substring(0, 2)
    .toUpperCase();
};
