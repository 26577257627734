import OmniSearchCard from "@/components/omniSearch/OmniSearchCard";
import ResultsGrid from "@/components/omniSearch/ResultsGrid";
import { useGetMediaItems } from "@/hooks/useGetMediaItem";
import { constructMomentFromMomentId } from "@/utils/moment";
import Loading from "@/layouts/Loading";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
  Button,
  ScrollArea,
} from "@kino/ui";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import PlaylistCover from "@/components/playlists/PlaylistCover";
import { useUser } from "@clerk/clerk-react";
import { LockIcon, PlayIcon } from "lucide-react";
import PlaylistMetadata from "@/components/playlists/PlaylistMetadata";
import { PlaylistOptionsDropdown } from "@/components/playlists/PlaylistOptionsDropdown";
import { useSelectedMediaStore } from "@/store/selectedMediaStore";
import useGetSearchMode from "@/hooks/useGetSearchMode";
import { useLayoutStore } from "@/store/layoutStore";
import { useEffect } from "react";
import { MomentId } from "@/store/playlists/playlistsStore";
import { usePlaylists } from "@/hooks/usePlaylists";

const Playlist = () => {
  const { user } = useUser();
  const { playlistId } = useParams();
  const { data: playlistResponse } = usePlaylists();
  const searchMode = useGetSearchMode();

  const { setSelectedMedia, selectPlaylist } = useSelectedMediaStore(
    (state) => ({
      setSelectedMedia: state.setCurrentSelection,
      selectPlaylist: state.selectPlaylist,
    }),
  );

  const { isInspectorOpen, set } = useLayoutStore((state) => ({
    isInspectorOpen: state.isInspectorOpen,
    set: state.set,
  }));

  const playlist = playlistResponse?.playlists.find(
    ({ id }) => id === playlistId,
  );

  const mediaItemIds =
    playlist?.moments?.map((moment) => moment.media_item_id) || [];
  const mediaItemResults = useGetMediaItems(mediaItemIds);

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setSelectedMedia([]);
      }
    };

    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [setSelectedMedia]);

  if (!playlist || mediaItemResults.isLoading) {
    return <Loading />;
  }

  const mediaItemsById = new Map(
    mediaItemResults.data
      ?.filter((result) => !!result)
      .map((result) => [result.id, result]),
  );

  const moments =
    playlist.moments
      ?.map((moment) => {
        const mediaItem = mediaItemsById.get(moment.media_item_id);
        if (!mediaItem) return null;
        return constructMomentFromMomentId(
          moment.moment_id as MomentId,
          mediaItem,
        );
      })
      .filter(Boolean) || [];

  const handlePlay = () => {
    const mediaItem = mediaItemsById.values().next().value;
    const moment = moments.find((m) => m?.media_item_id === mediaItem?.id);
    if (!mediaItem || !moment) return;

    setSelectedMedia([
      {
        mediaItem,
        activeInspectorMoment: { moment, searchMode },
        id: moment.id,
      },
    ]);

    selectPlaylist(playlistId);

    if (!isInspectorOpen) {
      set("isInspectorOpen", true);
    }
  };

  return (
    <div className="flex h-full w-full flex-col gap-4 overflow-hidden">
      <Breadcrumb className="flex h-9 min-h-9 items-center border-b px-3">
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink asChild>
              <Link to="/playlists">Playlists</Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>{playlist.title}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <div className="flex justify-between px-3 py-6">
        <div className="flex items-center gap-6">
          <div className="h-44 w-44">
            <PlaylistCover playlist={playlist} />
          </div>
          <div className="flex h-full flex-col justify-between">
            <div className="flex flex-col gap-3">
              <div className="flex items-center gap-1 text-xs text-neutral-400">
                <LockIcon className="h-3 w-3" />
                Personal Playlist
              </div>
              <div className="flex items-center gap-1">
                <h1 className="m-0 p-0 text-4xl font-bold leading-none">
                  {playlist.title}
                </h1>
              </div>
              {playlist.description && (
                <p className="!border-t-0 text-sm text-neutral-400">
                  {playlist.description}
                </p>
              )}
              <PlaylistMetadata
                playlist={playlist}
                userImageUrl={user?.imageUrl}
                userFirstName={user?.firstName ?? undefined}
              />
            </div>
            <div className="flex items-center gap-3">
              <Button
                variant="ghost"
                size="icon"
                className="h-10 w-10 rounded-full bg-indigo-600"
                onClick={handlePlay}
              >
                <PlayIcon className="h-4 w-4 text-neutral-900" fill="0 0% 9%" />
              </Button>
              <PlaylistOptionsDropdown playlist={playlist} />
            </div>
          </div>
        </div>
      </div>
      <ScrollArea className="w-full p-3 pt-0">
        <ResultsGrid>
          {moments.map((moment) => {
            if (!moment) return null;
            const mediaItem = mediaItemsById.get(moment.media_item_id);
            if (!mediaItem) return null;
            return (
              <OmniSearchCard
                key={moment.id}
                moment={moment}
                mediaItem={mediaItem}
                playlistId={playlistId}
              />
            );
          })}
        </ResultsGrid>
      </ScrollArea>
    </div>
  );
};

export default Playlist;
