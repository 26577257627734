import React, { HTMLAttributes, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Button,
} from "@kino/ui";
import { usePlaylistMutations } from "@/hooks/usePlaylists";
import { components } from "@/openapi-bindings/v2";

interface DeletePlaylistPopoverProps extends HTMLAttributes<HTMLDivElement> {
  playlist: components["schemas"]["Playlist"];
  trigger: React.ReactNode;
  onOpenChange?: (open: boolean) => void;
}

const DeletePlaylistPopover: React.FC<DeletePlaylistPopoverProps> = ({
  playlist,
  trigger,
  onOpenChange,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { deletePlaylists } = usePlaylistMutations();

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!playlist.id) return;

    deletePlaylists([playlist.id]);
    setIsOpen(false);
    if (onOpenChange) {
      onOpenChange(false);
    }
    navigate("/playlists");
  };

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
    if (onOpenChange) {
      onOpenChange(open);
    }
  };

  return (
    <>
      <div
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(true);
        }}
      >
        {trigger}
      </div>

      <Dialog open={isOpen} onOpenChange={handleOpenChange} modal={true}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader className="text-left">
            <DialogTitle className="text-neutral">Delete Playlist</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete "
              <b className="text-neutral-300">{playlist.title}</b>"? This action
              cannot be undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="gap-2">
            <Button
              variant="outline"
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                handleOpenChange(false);
              }}
            >
              Cancel
            </Button>
            <Button variant="destructive" onClick={handleDelete}>
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DeletePlaylistPopover;
