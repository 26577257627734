import { forwardRef } from "react";

export const WindowHandleLeftIcon = forwardRef<
  SVGSVGElement,
  React.SVGAttributes<SVGElement>
>((props, forwardedRef) => (
  <svg viewBox="0 0 4 10" fill="none" {...props} ref={forwardedRef}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.96 0H2.96V3.7L1.5 5L2.96 6.3V10H3.96V6.1L2.8 5L3.96 3.9V0Z"
      fill="currentColor"
    />
    <path d="M2.8 5L3.96 3.9V6.1L2.8 5Z" />
  </svg>
));

export default WindowHandleLeftIcon;
