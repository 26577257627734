import { components } from "@/openapi-bindings/v2";
import { useGetMediaItems } from "./useGetMediaItem";
import { useActiveServerStore } from "@/store/activeServerStore";

export const useGetPlaylistThumbnails = (
  playlist: components["schemas"]["Playlist"],
) => {
  const { buildServerUrl } = useActiveServerStore();
  const { data: mediaItems, isLoading } = useGetMediaItems(
    playlist.moments?.map((moment) => moment.media_item_id) ?? [],
    true,
  );

  return {
    data:
      mediaItems?.map((item) =>
        buildServerUrl(item?.key_thumbnail_path ?? ""),
      ) ?? [],
    isLoading,
  };
};
