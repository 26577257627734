import {
  useRegisterPlayerMoment,
  type PlayerMomentType,
} from "../context/PlayerMomentsContext";
import { PropsWithChildren, useEffect } from "react";

export const PlayerMoment: React.FC<
  PropsWithChildren<{
    startTime: number;
    endTime: number;
    type: PlayerMomentType;
    shouldUnregister?: boolean;
    id?: string;
  }>
> = ({ startTime, endTime, type, children, shouldUnregister = true, id }) => {
  const { registerMoment, unregisterMoment } = useRegisterPlayerMoment();

  useEffect(() => {
    registerMoment({ startTime, endTime, type, id });

    return () => {
      if (!shouldUnregister) return;

      unregisterMoment({ startTime, endTime, type, id });
    };
  }, [
    startTime,
    endTime,
    type,
    shouldUnregister,
    registerMoment,
    unregisterMoment,
    id,
  ]);

  return children;
};
