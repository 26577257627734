import { secondsToHumanDuration } from "./time";
import { components } from "@/openapi-bindings/v2";

export const getTotalPlaylistDuration = (
  playlist: components["schemas"]["Playlist"],
) => {
  if (!playlist.moments) return 0;
  return secondsToHumanDuration(
    playlist.moments.reduce((acc, moment) => {
      return acc + moment.end_time - moment.start_time;
    }, 0),
  );
};
