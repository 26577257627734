import { ViewType } from "@/types/search";
import {
  createClearable,
  StoreCollectionIdentifier,
} from "@/utils/zustand/createClearable";
import { devtools, persist } from "zustand/middleware";
import {
  DisplayMode,
  DisplayVariant,
  DISPLAY_MODE_CONFIGS,
} from "@/types/display";

interface DisplayState {
  variant: DisplayVariant;
  mode: DisplayMode;
  modeOverride: DisplayMode | null;
  viewType: ViewType;
  groupingKey: string;
  propertyVisibility: Record<string, boolean>;
}

interface DisplayActions {
  setVariant: (variant: DisplayVariant) => void;
  setMode: (mode: DisplayMode) => void;
  setViewType: (viewType: ViewType) => void;
  setGroupingKey: (key: string) => void;
  togglePropertyVisibility: (property: string) => void;
  resetState: () => void;
  setModeOverride: (mode: DisplayMode | null) => void;
}

type DisplayStore = DisplayState & DisplayActions;

const getInitialState = (
  variant: DisplayVariant,
  mode: DisplayMode,
): DisplayState => {
  const modeConfig = DISPLAY_MODE_CONFIGS[mode];
  const defaultViewType = modeConfig.allowedViewTypes[0];
  if (!defaultViewType) {
    throw new Error(`No allowed view types for mode: ${mode}`);
  }

  return {
    variant,
    mode,
    viewType: defaultViewType,
    groupingKey: "none",
    propertyVisibility: modeConfig.propertyVisibility,
    modeOverride: null,
  };
};

export const useDisplayStore = createClearable([
  StoreCollectionIdentifier.DISPLAY,
])<DisplayStore>()(
  devtools(
    persist(
      (set) => ({
        ...getInitialState("media", "default"),

        setVariant: (variant) => {
          const newState = getInitialState(variant, "default");
          set(newState);
        },

        setMode: (mode) => {
          const modeConfig = DISPLAY_MODE_CONFIGS[mode];
          const defaultViewType = modeConfig.allowedViewTypes[0];
          if (!defaultViewType) {
            throw new Error(`No allowed view types for mode: ${mode}`);
          }

          set((state) => ({
            mode,
            viewType: defaultViewType,
            propertyVisibility: modeConfig.propertyVisibility,
            groupingKey: modeConfig.groupingEnabled
              ? state.groupingKey
              : "none",
          }));
        },

        setViewType: (viewType: ViewType) => {
          set({ viewType });
        },

        setGroupingKey: (key) => {
          set({ groupingKey: key });
        },

        togglePropertyVisibility: (property) => {
          set((state) => ({
            propertyVisibility: {
              ...state.propertyVisibility,
              [property]: !state.propertyVisibility[property],
            },
          }));
        },

        resetState: () =>
          set((state) => getInitialState(state.variant, "default")),

        setModeOverride: (modeOverride) => {
          if (modeOverride) {
            const modeConfig = DISPLAY_MODE_CONFIGS[modeOverride];
            set({
              modeOverride,
              viewType: modeConfig.allowedViewTypes[0],
              propertyVisibility: modeConfig.propertyVisibility,
            });
          } else {
            set((state) => {
              const baseConfig = DISPLAY_MODE_CONFIGS[state.mode];
              return {
                modeOverride: null,
                viewType: baseConfig.allowedViewTypes[0],
                propertyVisibility: baseConfig.propertyVisibility,
              };
            });
          }
        },
      }),
      {
        name: "display-storage",
        version: 2,
      },
    ),
    { name: "DisplayStore" },
  ),
);

export const displayStore = useDisplayStore.getState;

export const getEffectiveMode = (state: DisplayState) =>
  state.modeOverride ?? state.mode;
