import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuItem,
  DropdownMenuContent,
} from "@kino/ui";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import { PlaylistDialog } from "./PlaylistDialog";
import DeletePlaylistPopover from "./DeletePlaylistPopover";
import { components } from "@/openapi-bindings/v2";

interface PlaylistOptionsDropdownProps {
  playlist: components["schemas"]["Playlist"];
}

export const PlaylistOptionsDropdown = ({
  playlist,
}: PlaylistOptionsDropdownProps) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <DotsHorizontalIcon className="cursor-pointer text-neutral-400 hover:text-white" />
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start">
        <PlaylistDialog
          playlist={playlist}
          mode="edit"
          trigger={
            <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
              Edit playlist
            </DropdownMenuItem>
          }
        />
        <DeletePlaylistPopover
          playlist={playlist}
          trigger={
            <DropdownMenuItem
              onSelect={(e) => e.preventDefault()}
              className="text-red-500"
            >
              Delete playlist
            </DropdownMenuItem>
          }
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
