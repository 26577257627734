import React, { useEffect } from "react";
import { Panel, PanelGroup } from "react-resizable-panels";
import useClientOs from "../../../../packages/ui/src/utils/useOperatingSystem";
import { useLayoutStore } from "../store/layoutStore";
import { cn } from "@kino/ui";
import { Navigate, Outlet, useLocation } from "react-router";
import { DebuggerPanel } from "./panels/Debugger";
import PanelResizeHandle from "../components/panel/PanelResizeHandle";
import { useOrganization, useUser } from "@clerk/clerk-react";
import { usePostHog } from "posthog-js/react";
import ContextMenu from "@/components/ContextMenu";
import { InspectorPanel } from "@/components/inspector/InspectorPanel";
import Navbar from "@/layouts/panels/Navbar";
import MainPanelHeader from "@/layouts/panels/MainPanelHeader";
import Loading from "./Loading";
import { useInitializeFilters } from "@/hooks/useInitializeFilters";
import { ServerProvider } from "@/providers/ServerProvider";

// Memoize the main content panel to prevent re-renders
const MainPanelContent = React.memo(() => (
  <>
    <MainPanelHeader />
    <Outlet />
  </>
));

// Memoize the panel group structure while maintaining proper nesting
const PanelGroupContainer = React.memo(
  ({
    isInspectorOpen,
    isDebuggerPanelOpen,
    canAccessDevFeatures,
  }: {
    isInspectorOpen: boolean;
    isDebuggerPanelOpen: boolean;
    canAccessDevFeatures: boolean | undefined;
  }) => (
    <PanelGroup
      autoSaveId="kino-layout-wrapper-autoSaveId"
      direction="horizontal"
      className="z-0 h-full w-full"
    >
      <Panel
        className="flex h-full w-full flex-col"
        minSize={40}
        maxSize={100}
        defaultSize={60}
        id="main-panel"
        order={2}
      >
        <MainPanelContent />
      </Panel>
      {isInspectorOpen && (
        <>
          <PanelResizeHandle />
          <InspectorPanel />
        </>
      )}
      {canAccessDevFeatures && isDebuggerPanelOpen && (
        <>
          <PanelResizeHandle />
          <DebuggerPanel />
        </>
      )}
    </PanelGroup>
  ),
);

const KinoWrapper: React.FC = () => {
  const clientOs = useClientOs();
  const canAccessDevFeatures = true;
  const isInspectorOpen = useLayoutStore((state) => state.isInspectorOpen);
  const isDebuggerPanelOpen = useLayoutStore(
    (state) => state.isDebuggerPanelOpen,
  );

  const { isLoaded: isUserLoaded, isSignedIn, user } = useUser();
  const { organization, isLoaded: isOrgLoaded } = useOrganization();

  const location = useLocation();
  const posthog = usePostHog();

  // Initialize filters only after we have a valid organization
  useInitializeFilters();

  // Setup analytics - moved to top level to maintain hook order
  useEffect(() => {
    if (!user?.id || !organization?.id) return;

    posthog.identify(user.id, {
      email: user.emailAddresses[0]?.emailAddress,
      name: user.fullName,
      avatar: user.imageUrl,
    });

    posthog.group("company", organization.id, {
      name: organization.name,
      memberCount: organization.membersCount,
    });
  }, [user?.id, organization?.id, posthog]);

  // Wait for everything to load
  if (!isUserLoaded || !isOrgLoaded) {
    return <Loading displayText="Signing in..." />;
  }

  // Handle auth
  if (!isSignedIn) {
    return <Navigate to="/sign-in" state={{ from: location }} replace />;
  }

  // If we have no orgs at all, show error
  if (!user.organizationMemberships?.length) {
    return (
      <Navigate
        to="/no-access"
        state={{
          reason: "You're not part of any organization",
          direction: "Please get added to one to use Kino for Enterprise.",
        }}
        replace
      />
    );
  }

  // If no organization selected, show error
  if (!organization) {
    return (
      <Navigate
        to="/no-access"
        state={{
          reason: "No organization selected",
          direction:
            "Please contact support to verify your organization settings.",
        }}
        replace
      />
    );
  }

  return (
    <ServerProvider>
      <ContextMenu>
        <div
          className={cn(
            "flex w-screen flex-col",
            clientOs == "macOS" &&
              "h-[calc(100vh_-_0px)] max-h-[calc(100vh_-_0px)]",
            clientOs == "windows" &&
              "h-[calc(100vh_-_32px)] max-h-[calc(100vh_-_32px)]",
          )}
        >
          <Navbar />
          <PanelGroupContainer
            isInspectorOpen={isInspectorOpen}
            isDebuggerPanelOpen={isDebuggerPanelOpen}
            canAccessDevFeatures={canAccessDevFeatures}
          />
        </div>
      </ContextMenu>
    </ServerProvider>
  );
};

export default KinoWrapper;
