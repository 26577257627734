import React, { useState } from "react";
import { ScrollArea } from "@kino/ui";
import ContextMenu, { MenuItem } from "../../components/ContextMenu";
import { AvatarIcon, TrashIcon } from "@radix-ui/react-icons";
import { useActiveServerStore } from "@/store/activeServerStore";
import { components } from "@/openapi-bindings/v2";
import { queryClient, useClient } from "@/hooks/useClient";
import { useSyncOrganization } from "@/store/organizationStore";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@kino/ui";
import { toast } from "sonner";

interface ReferenceFacesProps {
  selectedPerson: components["schemas"]["Person"] | null;
}

export const ReferenceFaces: React.FC<ReferenceFacesProps> = ({
  selectedPerson,
}) => {
  const { apiClient } = useClient();
  const orgStore = useSyncOrganization();
  const { buildServerUrl } = useActiveServerStore();
  const [faceToDelete, setFaceToDelete] = useState<string | null>(null);

  const deleteFaces = apiClient.useMutation(
    "delete",
    "/faces",
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["get", "/people"] });
        toast.success("Face deleted successfully");
      },
      onError: () => {
        toast.error("Failed to delete face");
      },
    },
    queryClient,
  );

  const handleDeleteFace = async () => {
    if (!faceToDelete) return;

    try {
      await deleteFaces.mutateAsync({
        body: {
          organization_id: orgStore.getEffectiveOrganizationId(),
          face_ids: [faceToDelete],
        },
      });
      setFaceToDelete(null);
    } catch (error) {
      console.error("Failed to delete face:", error);
    }
  };

  const setAvatarMutation = apiClient.useMutation(
    "put",
    "/person/{person_id}/set-avatar",
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [
            "get",
            "/people",
            {
              params: {
                query: {
                  organization_id: orgStore.getEffectiveOrganizationId(),
                },
              },
            },
          ],
        });
      },
    },
    queryClient,
  );

  const handleSetAsAvatar = (imagePath: string) => {
    setAvatarMutation.mutateAsync({
      params: {
        path: {
          person_id: selectedPerson?.id ?? "",
        },
        query: {
          organization_id: orgStore.getEffectiveOrganizationId(),
          reference_face_path: imagePath,
        },
      },
    });
  };

  if (!selectedPerson) {
    return (
      <div className="text-muted-foreground flex h-full items-center justify-center">
        Select a person to view their reference faces
      </div>
    );
  }

  return (
    <>
      <ScrollArea className="h-full">
        <div className="space-y-4 p-4">
          <h2 className="text-lg font-semibold">
            Reference Faces for {selectedPerson.name}
          </h2>
          <div className="grid grid-cols-4 gap-4">
            {selectedPerson.reference_face_paths.map((path, index) => {
              const menuItems: MenuItem[] = [
                {
                  label: "Set as Avatar",
                  icon: <AvatarIcon />,
                  onClick: () => handleSetAsAvatar(path),
                },
                {
                  label: "Delete Face",
                  icon: <TrashIcon />,
                  onClick: () => setFaceToDelete(path),
                },
              ];

              return (
                <ContextMenu key={index} customMenuItems={menuItems}>
                  <div className="border-border group relative overflow-hidden rounded-lg border">
                    <img
                      src={buildServerUrl(path)}
                      alt={`Reference face ${index + 1} for ${selectedPerson.name}`}
                      className="aspect-square w-full object-cover"
                    />
                    {path === selectedPerson.thumbnail_path && (
                      <div className="bg-accent/80 absolute bottom-2 right-2 rounded-full px-2 py-0.5 text-xs">
                        Avatar
                      </div>
                    )}
                  </div>
                </ContextMenu>
              );
            })}
          </div>
        </div>
      </ScrollArea>

      <AlertDialog
        open={!!faceToDelete}
        onOpenChange={() => setFaceToDelete(null)}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This will permanently delete this face. This action cannot be
              undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction variant="destructive" onClick={handleDeleteFace}>
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
