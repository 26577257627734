import { useOrganization } from "@clerk/clerk-react";
import { useMemo } from "react";

interface ServerConfig {
  ip: string;
  port: number;
  name: string;
  defaultDisplayMode: string;
}

interface ServerInfo {
  apiUrl: string;
  config: ServerConfig | null;
}

export const useClerkServerInfo = (): ServerInfo => {
  const { organization } = useOrganization();

  return useMemo(() => {
    const servers = organization?.publicMetadata?.servers as
      | ServerConfig[]
      | undefined;
    const server = servers?.[0];

    if (!server?.ip || !server?.port) {
      return {
        apiUrl: "http://unconfigured-kino-backend:8030",
        config: null,
      };
    }

    const isProduction = import.meta.env.PROD;
    const apiUrl = `${isProduction ? "https" : "http"}://${server.ip}:${server.port}/`;

    return {
      apiUrl,
      config: server,
    };
  }, [organization?.publicMetadata]);
};
