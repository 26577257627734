import {
  Card,
  ContextMenu,
  ContextMenuTrigger,
  ContextMenuContent,
  ContextMenuItem,
} from "@kino/ui";
import OmniSearchCardTranscript from "@components/omniSearch/cardModules/OmniSearchCardTranscript";
import OmniSearchCardRelativeTimeline from "@components/omniSearch/cardModules/OmniSearchCardRelativeTimeline";
import OmniSearchCardPath from "@components/omniSearch/cardModules/OmniSearchCardPath";
import React, { useMemo, useCallback, type HTMLAttributes } from "react";
import { components } from "@/openapi-bindings/v2";
import { cn } from "@/utils/tailwind";
import { OmniSearchCardPlayer } from "./cardModules/OmniSearchCardPlayer";
import { useSearchDisplayStore } from "@/store/search/searchDisplayStore";
import OmniSearchCardEntities from "./cardModules/OmniSearchCardEntities";
import { useSelectedMediaStore } from "@/store/selectedMediaStore";
import useGetSearchMode from "@/hooks/useGetSearchMode";
import { useLayoutStore } from "@/store/layoutStore";
import { AddToPlaylistSubContextMenu } from "../playlists/AddToPlaylistSubContextMenu";
import { CopyIcon } from "lucide-react";
import { secondsToTimestamp } from "@/utils/time";
import { useGetEntities } from "@/hooks/useGetEntities";
import { MediaItem } from "@/context/MediaItemContext/MediaItem";
import { useMediaItemTimecode } from "@/hooks/useMediaItemTimecode";

interface OmniSearchCardSectionProps extends HTMLAttributes<HTMLDivElement> {}

const OmniSearchCardSection = ({
  children,
  className,
}: OmniSearchCardSectionProps) => {
  return (
    <div className={cn("flex flex-col gap-1 p-2", className)}>{children}</div>
  );
};

interface OmniSearchCardProps {
  moment: components["schemas"]["Moment"];
  mediaItem: components["schemas"]["MediaItem"];
  isHighlighted?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  searchResponse?: components["schemas"]["SearchResponse"];
  score?: number;
  contextMenuItems?: React.ReactNode;
  playlistId?: string;
  displayTags?: boolean;
}

interface MarkerWithTooltip extends HTMLAttributes<HTMLDivElement> {
  percentage: number;
  tooltipText?: string;
}

// Memoized component for entities (people and tags)
const EntitiesSection = React.memo(
  ({
    moment,
    displayTags = true,
  }: {
    moment: components["schemas"]["Moment"];
    displayTags?: boolean;
  }) => {
    const { getPersonsByIds, getTagsByIds } = useGetEntities();
    const people = getPersonsByIds(moment.person_ids);
    const tags = displayTags ? getTagsByIds(moment.tag_ids) : undefined;

    return <OmniSearchCardEntities people={people} tags={tags} />;
  },
);

EntitiesSection.displayName = "EntitiesSection";

// Memoized component for timeline
const TimelineSection = React.memo(
  ({
    moment,
    mediaItem,
  }: {
    moment: components["schemas"]["Moment"];
    mediaItem: components["schemas"]["MediaItem"];
  }) => {
    const start = moment.start ?? 0;
    const end = moment.end ?? 5;

    const startTimecode = useMediaItemTimecode(start ?? 0);
    const endTimecode = useMediaItemTimecode(end ?? 5);

    return (
      <OmniSearchCardRelativeTimeline
        segments={[]}
        markers={[
          {
            percentage: mediaItem.ffprobe_data?.format?.duration
              ? (100 * start) / Number(mediaItem.ffprobe_data.format.duration)
              : 0,
            tooltipText: `${startTimecode.toString()} - ${endTimecode.toString()}`,
          } as MarkerWithTooltip,
        ]}
        duration={
          mediaItem.ffprobe_data?.format?.duration
            ? Number(mediaItem.ffprobe_data.format.duration)
            : undefined
        }
      />
    );
  },
);

TimelineSection.displayName = "TimelineSection";

// Memoized component for card content
const CardContent = React.memo(
  ({
    moment,
    mediaItem,
    searchResponse,
    playlistId,
    handleClick,
    copyFilePath,
    isActive,
    isHighlighted,
    onMouseEnter,
    onMouseLeave,
    displayTags,
  }: Omit<OmniSearchCardProps, "contextMenuItems" | "score"> & {
    handleClick: () => void;
    copyFilePath: () => void;
    isActive: boolean;
  }) => {
    const { propertyVisibility } = useSearchDisplayStore();

    return (
      <Card
        variant="omniSearchResult"
        className={cn(
          "group/card transition-colors hover:border-neutral-500/60",
          isActive ? "border-indigo-500" : "",
          isHighlighted ? "border-opacity-100" : "border-opacity-[0.65]",
        )}
        key={moment.id}
        onClick={handleClick}
        onMouseOver={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <OmniSearchCardPlayer moment={moment} mediaItem={mediaItem} />
        {propertyVisibility.timeline && (
          <TimelineSection moment={moment} mediaItem={mediaItem} />
        )}
        <div className="flex flex-col">
          {propertyVisibility.transcript && (
            <OmniSearchCardSection className="h-[65px]">
              <OmniSearchCardTranscript
                text={moment.relevant_transcript_text ?? ""}
                speakerId={undefined}
              />
            </OmniSearchCardSection>
          )}
          {propertyVisibility.tags && (
            <OmniSearchCardSection className="h-8 p-0">
              <EntitiesSection moment={moment} displayTags={displayTags} />
            </OmniSearchCardSection>
          )}
        </div>
        <OmniSearchCardSection>
          <OmniSearchCardPath
            path={mediaItem.ffprobe_data?.format?.filename ?? ""}
            moment={moment}
            mediaItem={mediaItem}
            playlistId={playlistId}
            searchResponse={searchResponse}
          />
        </OmniSearchCardSection>
      </Card>
    );
  },
);

CardContent.displayName = "CardContent";

const OmniSearchCard = React.memo(
  ({
    moment,
    mediaItem,
    isHighlighted,
    onMouseEnter,
    onMouseLeave,
    searchResponse,
    score,
    contextMenuItems,
    playlistId,
    displayTags = true,
  }: OmniSearchCardProps) => {
    const searchMode = useGetSearchMode();
    const { isInspectorOpen, set } = useLayoutStore((state) => ({
      isInspectorOpen: state.isInspectorOpen,
      set: state.set,
    }));
    const { setCurrentSelection, selectPlaylist, currentSelection } =
      useSelectedMediaStore((state) => ({
        setCurrentSelection: state.setCurrentSelection,
        selectPlaylist: state.selectPlaylist,
        currentSelection: state.currentSelection,
      }));

    const combinedMomentId = useMemo(
      () =>
        mediaItem && moment
          ? `${mediaItem.id}_moment_${moment.start}_${moment.end}`
          : undefined,
      [mediaItem, moment],
    );

    const handleClick = useCallback(() => {
      if (combinedMomentId && mediaItem) {
        setCurrentSelection([
          {
            mediaItem,
            activeInspectorMoment: { moment, searchMode },
            id: combinedMomentId,
          },
        ]);

        selectPlaylist(playlistId);

        if (!isInspectorOpen) {
          set("isInspectorOpen", true);
        }
      }
    }, [
      combinedMomentId,
      mediaItem,
      moment,
      searchMode,
      playlistId,
      isInspectorOpen,
      setCurrentSelection,
      selectPlaylist,
      set,
    ]);

    const copyFilePath = useCallback(async () => {
      if (!mediaItem?.ffprobe_data?.format?.filename) {
        return;
      }

      navigator.clipboard.writeText(mediaItem.ffprobe_data.format.filename);
    }, [mediaItem?.ffprobe_data?.format?.filename]);

    const isActive = useMemo(
      () =>
        currentSelection?.find(
          ({ mediaItem: activeInspectorItem, activeInspectorMoment }) =>
            activeInspectorItem.id === mediaItem?.id &&
            activeInspectorMoment?.moment.id === moment.id,
        ),
      [currentSelection, mediaItem?.id, moment.id],
    );

    if (!mediaItem) return null;

    return (
      <ContextMenu>
        <ContextMenuTrigger>
          <MediaItem mediaItemId={mediaItem.id}>
            <CardContent
              moment={moment}
              mediaItem={mediaItem}
              isHighlighted={isHighlighted}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              searchResponse={searchResponse}
              playlistId={playlistId}
              handleClick={handleClick}
              copyFilePath={copyFilePath}
              isActive={!!isActive}
              displayTags={displayTags}
            />
          </MediaItem>
        </ContextMenuTrigger>
        <ContextMenuContent>
          <AddToPlaylistSubContextMenu
            playlistMoment={{
              media_item_id: mediaItem.id,
              moment_id: moment.id,
              start_time: moment.start,
              end_time: moment.end,
              position: 0,
            }}
          />
          <ContextMenuItem onClick={copyFilePath}>
            <CopyIcon size={10} />
            Copy media path
          </ContextMenuItem>
          {contextMenuItems}
        </ContextMenuContent>
      </ContextMenu>
    );
  },
);

OmniSearchCard.displayName = "OmniSearchCard";

export default OmniSearchCard;
