import { components } from "@/openapi-bindings/v2";
import {
  createClearable,
  StoreCollectionIdentifier,
} from "@/utils/zustand/createClearable";
import { getInspectorItemIdsFromInspectorItemId } from "@/utils/stringUtils";

export type SelectedMediaMoment = {
  moment: components["schemas"]["Moment"];
  searchMode: components["schemas"]["SearchMode"];
};

export type SelectedMediaItem = {
  mediaItem: components["schemas"]["MediaItem"];
  activeInspectorMoment?: SelectedMediaMoment;
  id: string; // combined id of mediaItem and moment
};

interface SelectedMediaStoreState {
  currentSelection: SelectedMediaItem[] | undefined;
  currentSelectedPlaylistId: string | undefined;
  setCurrentSelection: (media: SelectedMediaItem[]) => void;
  selectPlaylist: (playlistId: string | undefined) => void;
  removeMediaByIds: (mediaIds: string[]) => void;
}

const useSelectedMediaStore = createClearable([
  StoreCollectionIdentifier.ORGANIZATION,
])<SelectedMediaStoreState>()((set) => ({
  currentSelection: undefined,
  currentSelectedPlaylistId: undefined,
  setCurrentSelection: (media) => set({ currentSelection: media }),
  selectPlaylist: (playlistId) =>
    set({ currentSelectedPlaylistId: playlistId }),
  removeMediaByIds: (mediaIds) =>
    set((state) => ({
      currentSelection: state.currentSelection?.filter((item) => {
        const { mediaItemId = "" } = getInspectorItemIdsFromInspectorItemId(
          item.id,
        ) ?? { mediaItemId: "" };
        return !mediaIds.includes(mediaItemId);
      }),
    })),
}));

export { useSelectedMediaStore };
