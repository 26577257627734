import { createClearable, StoreCollectionIdentifier } from "@/utils/zustand/createClearable";

export interface RouteParamsHistory {
  search: URLSearchParams;
  media: URLSearchParams;
}

interface RouteParamsHistoryStore {
  routeParamsHistory: RouteParamsHistory;
  setRouteParamsHistory: (
    route: keyof RouteParamsHistory,
    params: URLSearchParams,
  ) => void;
}

export const useRouteParamsHistoryStore = createClearable([
  StoreCollectionIdentifier.SEARCH,
])<RouteParamsHistoryStore>()((set) => ({
  routeParamsHistory: {
    search: new URLSearchParams(),
    media: new URLSearchParams(),
  },
  setRouteParamsHistory: (route, params) =>
    set((state) => ({
      routeParamsHistory: {
        ...state.routeParamsHistory,
        [route]: params,
      },
    })),
}));
