// React Query
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { ClerkProvider } from "@clerk/clerk-react";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useCommandMenuStore } from "@/store/commandMenuStore";
import { useEffect } from "react";
import { queryClient } from "@/hooks/useClient";
import { ThemeProvider } from "@/context/theme-provider";
import { dark } from "@clerk/themes";
import { Toaster } from "@kino/ui";
import { Monitoring } from "react-scan/monitoring"; // Import this first before React

posthog.init(import.meta.env.VITE_APP_PUBLIC_POSTHOG_KEY, {
  api_host: import.meta.env.VITE_APP_PUBLIC_POSTHOG_HOST,
  person_profiles: "identified_only",
  session_recording: {
    maskAllInputs: false,
    maskInputOptions: {
      password: true,
    },
  },
});

const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

export default function RootLayout() {
  const navigate = useNavigate();

  const location = useLocation();
  const { reset } = useCommandMenuStore();

  useEffect(() => {
    reset();
  }, [location]);

  useEffect(() => {
    const gaScript = document.createElement("script");
    gaScript.async = true;
    gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${import.meta.env.VITE_APP_GOOGLE_ANALYTICS}`;
    document.head.appendChild(gaScript);

    const inlineScript = document.createElement("script");
    inlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${import.meta.env.VITE_APP_GOOGLE_ANALYTICS}', {
        page_path: window.location.pathname,
      });
    `;
    document.head.appendChild(inlineScript);
  }, []);

  return (
    <ThemeProvider>
      <ClerkProvider
        routerPush={(to: any) => navigate(to)}
        routerReplace={(to: any) => navigate(to)}
        publishableKey={PUBLISHABLE_KEY}
        appearance={{
          baseTheme: dark,
        }}
      >
        <QueryClientProvider client={queryClient}>
          <Monitoring
            apiKey={import.meta.env.VITE_APP_PUBLIC_REACT_SCAN_API_KEY}
            url="https://monitoring.react-scan.com/api/v1/ingest"
            commit={import.meta.env.VITE_APP_VERCEL_GIT_COMMIT_SHA}
            branch={import.meta.env.VITE_APP_VERCEL_GIT_COMMIT_REF}
          />
          <PostHogProvider client={posthog}>
            <main>
              <Outlet />
            </main>
            <Toaster />
            <ReactQueryDevtools initialIsOpen={false} />
          </PostHogProvider>
        </QueryClientProvider>
      </ClerkProvider>
    </ThemeProvider>
  );
}
