import { Link } from "react-router-dom";
import PlaylistCover from "./PlaylistCover";
import { PlaylistDialog } from "./PlaylistDialog";
import { ClickableIcon } from "@kino/ui";
import { PlusIcon } from "@radix-ui/react-icons";
import { components } from "@/openapi-bindings/v2";
import { usePlaylists } from "@/hooks/usePlaylists";

interface PlaylistItemProps {
  playlist: components["schemas"]["Playlist"];
}

const PlaylistItem = ({ playlist }: PlaylistItemProps) => {
  return (
    <Link
      to={`/playlists/${playlist.id}`}
      className="flex cursor-pointer items-center gap-2 rounded-md p-2 text-xs text-neutral-300 transition-all hover:bg-neutral-800"
    >
      <div className="h-12 w-12">
        <PlaylistCover playlist={playlist} />
      </div>
      {playlist.title}
    </Link>
  );
};

interface PlaylistListProps {
  playlists: components["schemas"]["Playlist"][];
}

const PlaylistList = ({ playlists }: PlaylistListProps) => {
  return (
    <div className="flex flex-col gap-1">
      {playlists.map((playlist) => (
        <PlaylistItem key={playlist.id} playlist={playlist} />
      ))}
    </div>
  );
};

const EmptyPlaylistsView = () => {
  return <div className="text-xs text-neutral-300">No playlists yet</div>;
};

const PlaylistsSidebar = () => {
  const { data: playlistResponse, isLoading } = usePlaylists();
  const isEmpty = playlistResponse?.playlists.length === 0;

  if (isLoading) return null;

  return (
    <div className="relative flex h-full min-w-72 max-w-72 flex-col gap-4 py-3">
      <div>
        <div className="flex items-center justify-between px-3">
          <div className="text-xs text-neutral-500">Playlists</div>
          <PlaylistDialog
            mode="create"
            trigger={<ClickableIcon Icon={PlusIcon} name="New playlist" />}
          />
        </div>
      </div>
      <div className="px-3">
        {isEmpty && <EmptyPlaylistsView />}
        {!isEmpty && playlistResponse?.playlists && (
          <PlaylistList playlists={playlistResponse?.playlists} />
        )}
      </div>
    </div>
  );
};

export { PlaylistsSidebar };
