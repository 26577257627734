import { useAuth } from "@clerk/clerk-react";
import {
  Button,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@kino/ui";
import { useCallback, useEffect, useState } from "react";
import { Card } from "@kino/ui";
import Loading from "@/layouts/Loading";

export default function LoginSuccess() {
  const [token, setToken] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { isLoaded, isSignedIn } = useAuth();

  useEffect(() => {
    if (!isSignedIn) {
      return;
    }

    const getAndSetToken = async () => {
      try {
        const ticketResponse = await fetch("/api/ticket", {
          credentials: "include",
        });
        const { token, message } = await ticketResponse.json();

        if (!token) {
          throw message;
        }

        setToken(token);
      } catch (error) {
        console.error(error);
        setError(
          "There was an error logging into your account. Please report this to the Kino team for assistance.",
        );
      }
    };

    getAndSetToken();
  }, [isSignedIn]);

  const desktopRedirect = token
    ? `kino://login/desktop-success?ticket=${token}`
    : null;

  const openDesktop = useCallback(() => {
    if (!desktopRedirect) return;

    window.open(desktopRedirect);
  }, [desktopRedirect]);

  useEffect(() => {
    if (!desktopRedirect) {
      return;
    }

    window.open(desktopRedirect);
  }, [desktopRedirect]);

  if (!isLoaded) {
    return <Loading />;
  }

  return (
    <div className="flex min-h-screen items-center justify-center">
      <Card>
        <CardHeader>
          <CardTitle>
            {error || !isSignedIn
              ? "Unable to login"
              : "Successfully logged in"}
          </CardTitle>
          <CardDescription>
            {error || !isSignedIn ? (
              "There was a problem logging you in"
            ) : (
              <>
                If the desktop app didn't automatically open, click{" "}
                <a onClick={openDesktop} className="cursor-pointer underline">
                  here
                </a>
                . Otherwise, you can close this tab
              </>
            )}
          </CardDescription>
        </CardHeader>
        {error && <CardContent>{error}</CardContent>}
        {(error || !isSignedIn) && (
          <CardFooter>
            <Button onClick={() => window.close()}>Close</Button>
          </CardFooter>
        )}
      </Card>
    </div>
  );
}
