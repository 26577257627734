import React from "react";
import { Skeleton } from "@kino/ui";
import { useGetPlaylistThumbnails } from "@/hooks/useGetPlaylistThumbnails";
import { components } from "@/openapi-bindings/v2";

interface PlaylistCoverProps {
  playlist: components["schemas"]["Playlist"];
}

const PlaylistCoverLoading = () => {
  return (
    <div className="aspect-square w-full overflow-hidden rounded border">
      <Skeleton className="h-full w-full bg-neutral-800/50" />
    </div>
  );
};

const PlaylistGridLoading = () => {
  return (
    <div className="grid aspect-square w-full grid-cols-2 gap-1 overflow-hidden rounded border">
      {[0, 1, 2, 3].map((index) => (
        <div key={index} className="aspect-square w-full overflow-hidden">
          <Skeleton className="h-full w-full" />
        </div>
      ))}
    </div>
  );
};

const PlaylistCover: React.FC<PlaylistCoverProps> = ({ playlist }) => {
  const { data: thumbnails, isLoading } = useGetPlaylistThumbnails(playlist);
  const displayThumbnails = thumbnails.slice(0, 4);

  if (isLoading) {
    return displayThumbnails.length > 2 ? (
      <PlaylistGridLoading />
    ) : (
      <PlaylistCoverLoading />
    );
  }

  // For empty playlist
  if (displayThumbnails.length === 0) {
    return (
      <div className="aspect-square w-full overflow-hidden rounded">
        <img
          src="/Empty Playlist Cover.svg"
          alt="Empty playlist"
          className="h-full w-full object-cover"
        />
      </div>
    );
  }

  // For 1-2 thumbnails, show only the first one
  if (displayThumbnails.length <= 2) {
    return (
      <div className="aspect-square w-full overflow-hidden rounded border">
        <img
          src={displayThumbnails[0]}
          alt="Playlist cover"
          className="h-full w-full object-cover"
        />
      </div>
    );
  }

  // For 3-4 thumbnails, show in 2x2 grid
  return (
    <div className="grid aspect-square w-full grid-cols-2 gap-1 overflow-hidden rounded border">
      {[0, 1, 2, 3].map((index) => (
        <div key={index} className="aspect-square w-full overflow-hidden">
          {displayThumbnails[index] ? (
            <img
              src={displayThumbnails[index]}
              alt={`Thumbnail ${index + 1}`}
              className="h-full w-full object-cover"
            />
          ) : (
            // Empty square for the missing fourth thumbnail when only 3 images
            <div className="h-full w-full bg-neutral-900" />
          )}
        </div>
      ))}
    </div>
  );
};

export default PlaylistCover;
