import React from "react";
import { cn } from "../utils/tailwind";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@kino/ui";
import colors from "tailwindcss/colors";

const excludedColors = [
  "inherit",
  "current",
  "transparent",
  "neutral",
  "gray",
  "zinc",
  "stone",
  "slate",
  "black",
  "white",
  "warmGray",
  "trueGray",
  "blueGray",
  "coolGray",
  "sky",
  "lightBlue",
];

const getBreakdownColors = () => {
  const breakdownColors = { ...colors };
  for (const color in colors) {
    if (excludedColors.includes(color)) {
      delete breakdownColors[color as keyof typeof colors];
    }
  }

  return breakdownColors;
};

const colorStrengthOrder = ["500", "600", "700", "800", "300", "400"];
const getBreakdownColor = (index: number) => {
  const colors = getBreakdownColors();
  const colorKeys = Object.keys(colors);
  const colorIndex = index % colorKeys.length;

  const colorName = colorKeys[colorIndex] as keyof typeof colors | undefined;

  if (!colorName) {
    throw new Error("No color found for index");
  }

  const colorStrength = colorStrengthOrder[
    Math.floor(index / colorKeys.length)
  ] as keyof (typeof colors)[typeof colorName] | undefined;

  if (!colorStrength) {
    throw new Error("No color strength found for index");
  }

  return colors[colorName][colorStrength];
};

type BreakdownProps = {
  total: number;
  data: Array<{
    name: string;
    label: string;
    value: number;
  }>;
  format?: (value: number) => string;
};

const getPercent = (value: number, total: number) =>
  `${Math.ceil((value / total) * 100)}%`;

const BreakdownInfo: React.FC<{
  value: number;
  index: number;
  name: string;
  label: string;
  format?: (value: number) => string;
}> = ({ value, index, name, label, format }) => {
  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-1 text-xs">
        <div
          className="h-1.5 w-1.5 rounded-full border border-neutral-800"
          style={{
            backgroundColor: getBreakdownColor(index),
          }}
        ></div>
        <div className="text-xs text-neutral-50">{name}</div>
        <div className="font-light capitalize text-zinc-200">{label}</div>
      </div>
      <div className="px-2.5 text-xs font-medium text-neutral-500">
        {format ? format(value) : value}
      </div>
    </div>
  );
};

const Breakdown: React.FC<BreakdownProps> = ({ data, format, total }) => {
  return (
    <div className="h-[88px] w-full overflow-hidden rounded border border-solid border-neutral-800 bg-neutral-800/30 p-3">
      <div className="flex h-full w-full flex-col gap-2">
        <div className="flex h-full w-full items-center gap-0.5">
          {data
            .sort((a, b) => b.value - a.value)
            .map(({ name, label, value }, i) => (
              <TooltipProvider>
                <Tooltip key={i} delayDuration={200}>
                  <TooltipTrigger asChild>
                    <div
                      className={cn(
                        "h-full cursor-default shadow-[inset_0px_2px_4px_0px_rgba(0,0,0,0.25)] first:rounded-l-sm last:rounded-r-sm",
                      )}
                      style={{
                        width: getPercent(value, total),
                        minWidth: "1px",
                        backgroundColor: getBreakdownColor(i),
                      }}
                    >
                      &nbsp;
                    </div>
                  </TooltipTrigger>
                  <TooltipContent className="z-50 text-neutral-50">
                    <BreakdownInfo
                      value={value}
                      index={i}
                      name={name}
                      label={label}
                      format={format}
                    />
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ))}
        </div>
        <div
          className={cn("text-xsm flex flex-wrap gap-4 whitespace-nowrap")}
          style={{ height: "2.25rem" }}
        >
          {data.map(({ name, label, value }, i) => (
            <BreakdownInfo
              value={value}
              index={i}
              name={name}
              label={label}
              format={format}
              key={i}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export { Breakdown };
