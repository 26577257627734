import { useDebuggerStore } from "@/store/debuggerStore";
import {
  ContextMenuSub,
  ContextMenuSubTrigger,
  ContextMenuSubContent,
  ContextMenuCheckboxItem,
  ContextMenuGroup,
  ContextMenuItem,
  ContextMenuSeparator,
} from "@kino/ui";
import { PlaylistDialog } from "./PlaylistDialog";
import React from "react";
import { usePlaylists } from "@/hooks/usePlaylists";
import { usePlaylistMoments } from "@/hooks/usePlaylists";
import { components } from "@/openapi-bindings/v2";

interface AddToPlaylistSubContextMenuProps {
  playlistMoment: components["schemas"]["PlaylistMoment"];
  label?: React.ReactNode;
  disabled?: boolean;
}

export const AddToPlaylistSubContextMenu = ({
  playlistMoment,
  label = "Add to playlist",
  disabled = false,
}: AddToPlaylistSubContextMenuProps) => {
  const showPlaylists = useDebuggerStore((state) => state.showPlaylists);
  const { data: playlistResponse } = usePlaylists();
  const { addMoments, removeMoments } = usePlaylistMoments();
  const playlists = playlistResponse?.playlists;

  if (!playlists || !showPlaylists) {
    return null;
  }

  const handlePlaylistToggle = (checked: boolean, playlistId: string) => {
    if (checked) {
      addMoments({
        playlist_id: playlistId,
        moments: [
          {
            media_item_id: playlistMoment.media_item_id,
            start_time: playlistMoment.start_time,
            end_time: playlistMoment.end_time,
            position: 0,
          },
        ],
      });
    } else {
      removeMoments({
        playlist_id: playlistId,
        moment_ids: [playlistMoment.moment_id],
      });
    }
  };

  return (
    <ContextMenuSub>
      <ContextMenuSubTrigger disabled={disabled}>{label}</ContextMenuSubTrigger>
      <ContextMenuSubContent>
        <PlaylistDialog
          mode="create"
          trigger={
            <ContextMenuItem onSelect={(e) => e.preventDefault()} inset>
              New Playlist
            </ContextMenuItem>
          }
          initialMoments={[playlistMoment]}
        />
        <ContextMenuSeparator />
        <ContextMenuGroup>
          {playlists.map(({ title, id, moments }) => (
            <ContextMenuCheckboxItem
              key={id}
              checked={moments?.some(
                (m) => m.moment_id === playlistMoment.moment_id,
              )}
              onCheckedChange={(checked) => handlePlaylistToggle(checked, id)}
            >
              {title}
            </ContextMenuCheckboxItem>
          ))}
        </ContextMenuGroup>
      </ContextMenuSubContent>
    </ContextMenuSub>
  );
};
