import * as React from "react";
import {
  ClickableIcon,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@kino/ui";
import AVBImport from "../AVBImport";

// Memoize the static AVB icon
const AVBIcon = React.memo(() => (
  <div className="flex h-5 w-6 items-center justify-center rounded border-[1.5px] border-neutral-500/50 text-[8px] font-semibold text-neutral-500">
    AVB
  </div>
));

AVBIcon.displayName = "AVBIcon";

// Memoize the popover content
const MemoizedAVBImport = React.memo(AVBImport);
MemoizedAVBImport.displayName = "MemoizedAVBImport";

const AVBImportPopover = React.memo(() => {
  return (
    <Popover modal={true}>
      <PopoverTrigger>
        <ClickableIcon
          Icon={AVBIcon}
          name="avb-import"
          tooltip="AVB Imports"
          className="rounded-md p-1 text-neutral-500 hover:bg-neutral-800"
        />
      </PopoverTrigger>
      <PopoverContent className="h-96 w-96 overflow-hidden p-0">
        <MemoizedAVBImport />
      </PopoverContent>
    </Popover>
  );
});

AVBImportPopover.displayName = "AVBImportPopover";

export default AVBImportPopover;
