import {
  Camera,
  UserCircle,
  Flag,
  Move3D,
  Target,
  MapPin,
  Activity,
  Package,
  Home,
  MessageSquare,
  Heart,
  PlayCircle,
  Users,
  Trophy,
  Forward,
  Music,
  Speaker,
  Clock,
  Building2,
  Cloud,
  Bird,
  Trees,
  Users2,
  TentTree,
  Image,
  User,
  Videotape,
  Film,
  Scissors,
  RotateCcw,
  Circle,
  Sparkles,
  ArrowRight,
  LucideCamera,
  Palette,
  Sun,
  Award,
  Medal,
  FlagIcon,
  Flame,
  Shirt,
  Key,
  Shell,
  UtensilsCrossed,
  Tent,
  BriefcaseMedical,
  MessageCircle,
  PersonStanding,
  Timer,
  Gift,
  TagIcon,
  Clapperboard,
  Play,
  Calendar,
  Megaphone,
  CircleUser,
  AlignJustify,
  Video,
  Aperture,
  Pencil,
  Mic,
  LibraryBig,
  Focus,
  Folder,
} from "lucide-react";
import { DefaultColors } from "tailwindcss/types/generated/colors";

export interface TagTypeDefinition {
  icon: React.ComponentType;
  baseColor: keyof DefaultColors;
  hidden?: boolean;
}

export const COMMON_TAG_DEFINITIONS: Record<string, TagTypeDefinition> = {
  USER_DEFINED: {
    icon: TagIcon,
    baseColor: "indigo",
  },
};

export const S48_TAG_DEFINITIONS: Record<string, TagTypeDefinition> = {
  CREW: {
    icon: User,
    baseColor: "blue",
  },
  DAY: {
    icon: Calendar,
    baseColor: "blue",
  },
  CAMERA_TYPE: {
    icon: Camera,
    baseColor: "slate",
  },
  ANIMAL: {
    icon: Bird,
    baseColor: "orange",
  },
  ISO: {
    icon: Circle,
    baseColor: "blue",
  },
  INTERVIEW: {
    icon: Mic,
    baseColor: "red",
  },
  SEASON: {
    icon: LibraryBig,
    baseColor: "blue",
  },
  LOAD: {
    icon: Videotape,
    baseColor: "purple",
  },
  CAMERA_LETTER: {
    icon: Camera,
    baseColor: "slate",
  },
  CONTESTANT_INITIALS: {
    icon: UserCircle,
    baseColor: "purple",
  },
  TRIBE_NAME: {
    icon: Flag,
    baseColor: "red",
  },
  SHOT_TYPE: {
    icon: Focus,
    baseColor: "indigo",
  },
  CAMERA_MOVEMENT: {
    icon: Move3D,
    baseColor: "cyan",
  },
  FOCUS_INDICATOR: {
    icon: Target,
    baseColor: "emerald",
  },
  SPECIAL_FOOTAGE_TYPE: {
    icon: LucideCamera,
    baseColor: "violet",
  },
  LOCATION_DESCRIPTOR: {
    icon: MapPin,
    baseColor: "amber",
  },
  ACTIVITY_DESCRIPTOR: {
    icon: Activity,
    baseColor: "orange",
  },
  OBJECT_DESCRIPTOR: {
    icon: Package,
    baseColor: "zinc",
  },
  DRONE_INDICATOR: {
    icon: Bird,
    baseColor: "sky",
  },
  PONDEROSA_INDICATOR: {
    icon: Home,
    baseColor: "lime",
  },
  INTERVIEW_TYPE: {
    icon: MessageSquare,
    baseColor: "teal",
  },
  REWARD_INDICATOR: {
    icon: Gift,
    baseColor: "yellow",
  },
  MEDICAL_INDICATOR: {
    icon: Heart,
    baseColor: "rose",
  },
  OPEN_INDICATOR: {
    icon: PlayCircle,
    baseColor: "green",
  },
  SECOND_UNIT_INDICATOR: {
    icon: Users,
    baseColor: "blue",
  },
  CHALLENGE_INDICATOR: {
    icon: Trophy,
    baseColor: "amber",
  },
  WALK_INDICATOR: {
    icon: Forward,
    baseColor: "purple",
  },
  ROOM_TONE_INDICATOR: {
    icon: Music,
    baseColor: "indigo",
  },
  AUDIO_INDICATOR: {
    icon: Speaker,
    baseColor: "slate",
  },
  TIME_CODE: {
    icon: Clock,
    baseColor: "zinc",
  },
  SPONSOR_NAME: {
    icon: Building2,
    baseColor: "emerald",
  },
  WEATHER_DESCRIPTOR: {
    icon: Cloud,
    baseColor: "sky",
  },
  ANIMAL_DESCRIPTOR: {
    icon: Bird,
    baseColor: "orange",
  },
  PLANT_DESCRIPTOR: {
    icon: Trees,
    baseColor: "green",
  },
  GAME_ELEMENT_DESCRIPTOR: {
    icon: Users2,
    baseColor: "violet",
  },
  EMPTY_CAMP_INDICATOR: {
    icon: TentTree,
    baseColor: "amber",
  },
  PORTRAITS_INDICATOR: {
    icon: Image,
    baseColor: "rose",
  },
  PROBST_INDICATOR: {
    icon: User,
    baseColor: "slate",
  },
  TAPE_IDENTIFIER: {
    icon: Videotape,
    baseColor: "zinc",
  },
  FOOTAGE_TYPE: {
    icon: Film,
    baseColor: "purple",
  },
  JUMP_CUT_INDICATOR: {
    icon: Scissors,
    baseColor: "red",
  },
  REPEAT_SHOT_INDICATOR: {
    icon: RotateCcw,
    baseColor: "cyan",
  },
  CIRCLE_INDICATOR: {
    icon: Circle,
    baseColor: "blue",
  },
  REVEAL_INDICATOR: {
    icon: Sparkles,
    baseColor: "yellow",
  },
  PASS_INDICATOR: {
    icon: ArrowRight,
    baseColor: "green",
  },
  VIEWFINDER_INDICATOR: {
    icon: LucideCamera,
    baseColor: "indigo",
  },
  COLOR_DESCRIPTOR: {
    icon: Palette,
    baseColor: "violet",
  },
  TIME_DESCRIPTOR: {
    icon: Sun,
    baseColor: "amber",
  },
  CHALLENGE_NAME_DESCRIPTOR: {
    icon: Award,
    baseColor: "orange",
  },
  MEDALLION_INDICATOR: {
    icon: Medal,
    baseColor: "yellow",
  },
  FLAG_INDICATOR: {
    icon: FlagIcon,
    baseColor: "red",
  },
  TORCH_INDICATOR: {
    icon: Flame,
    baseColor: "orange",
  },
  BUFF_INDICATOR: {
    icon: Shirt,
    baseColor: "purple",
  },
  HIDDEN_IMMUNITY_IDOL_INDICATOR: {
    icon: Key,
    baseColor: "amber",
  },
  IMMUNITY_NECKLACE_INDICATOR: {
    icon: Shell,
    baseColor: "yellow",
  },
  REWARD_DESCRIPTOR: {
    icon: UtensilsCrossed,
    baseColor: "emerald",
  },
  TRIBAL_COUNCIL_INDICATOR: {
    icon: Tent,
    baseColor: "red",
  },
  MEDIVAC_INDICATOR: {
    icon: BriefcaseMedical,
    baseColor: "rose",
  },
  MONOLOGUE_INDICATOR: {
    icon: MessageCircle,
    baseColor: "blue",
  },
  SCOTT_DUNCAN_INDICATOR: {
    icon: PersonStanding,
    baseColor: "slate",
  },
  INTERVELOMETER_INDICATOR: {
    icon: Timer,
    baseColor: "zinc",
  },
  PROJECT: {
    icon: Folder,
    baseColor: "white",
    hidden: true,
  },
};

export const TMZ_TAG_DEFINITIONS: Record<string, TagTypeDefinition> = {
  CELEBRITY: {
    icon: User,
    baseColor: "orange",
  },
  CELEBRITY_NOT_IN_VIDEO: {
    icon: User,
    baseColor: "indigo",
  },
  ASSET_TYPE: {
    icon: Image,
    baseColor: "purple",
  },
};

export const DAILIES_TAG_DEFINITIONS: Record<string, TagTypeDefinition> = {
  ROLL: {
    icon: Film,
    baseColor: "blue",
  },
  SHOT: {
    icon: Video,
    baseColor: "violet",
  },
  TAKE: {
    icon: Play,
    baseColor: "green",
  },
  SCENE: {
    icon: Clapperboard,
    baseColor: "orange",
  },
  DIRECTOR: {
    icon: Megaphone,
    baseColor: "red",
  },
  DATE_SHOT: {
    icon: Calendar,
    baseColor: "cyan",
  },
  CAMERA_OPERATOR: {
    icon: CircleUser,
    baseColor: "purple",
  },
  CAMERA_IDENTIFIER: {
    icon: Camera,
    baseColor: "slate",
  },
  CAMERA_TYPE: {
    icon: Camera,
    baseColor: "indigo",
  },
  CAMERA_NOTES: {
    icon: Pencil,
    baseColor: "zinc",
  },
  CAMERA_MOVEMENT: {
    icon: Camera,
    baseColor: "amber",
  },
  CAMERA_SETTINGS: {
    icon: Aperture,
    baseColor: "emerald",
  },
  SCRIPT_NOTES: {
    icon: AlignJustify,
    baseColor: "teal",
  },
};

export const TAG_DEFINITIONS = {
  S48: S48_TAG_DEFINITIONS,
  COMMON: COMMON_TAG_DEFINITIONS,
  TMZ: TMZ_TAG_DEFINITIONS,
  DAILIES: DAILIES_TAG_DEFINITIONS,
};

export const DEFAULT_TAG_DEFINITION: TagTypeDefinition = {
  icon: TagIcon,
  baseColor: "neutral",
};

export const formatTagTypeLabel = (tagType: string): string => {
  // For S48 tags, remove the prefix and format
  if (tagType.startsWith("S48")) {
    return tagType
      .replace("S48", "")
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  // For regular tags, just format normally
  return tagType
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};
