import {
  createClearable,
  StoreCollectionIdentifier,
} from "@/utils/zustand/createClearable";

interface ActiveBucketState {
  activeSegmentId: string | null;
  activeMarkerId: string | null;
  setActiveSegmentId: (id: string | null) => void;
  setActiveMarkerId: (id: string | null) => void;
}

export const useActiveBucketStore = createClearable([
  StoreCollectionIdentifier.SEARCH,
])<ActiveBucketState>()((set) => ({
  activeSegmentId: null,
  activeMarkerId: null,
  setActiveSegmentId: (id) => set({ activeSegmentId: id }),
  setActiveMarkerId: (id) => set({ activeMarkerId: id }),
}));
