import React, { useCallback, useMemo } from "react";
import OmniSearchResultTypeTabs from "@components/omniSearch/OmniSearchResultTypeTabs";
import { useUser } from "@clerk/clerk-react";
import { SearchInput } from "@/components/omniSearch/SearchInput";
import {
  CommandMenuFocusType,
  useCommandMenuFocus,
} from "@/store/commandMenuStore";
import SearchDefaultView from "@/components/omniSearch/searchViews/SearchDefaultView";
import SearchNoResultsView from "@/components/omniSearch/searchViews/SearchNoResultsView";
import SearchLoadingView from "@/components/omniSearch/searchViews/SearchLoadingView";
import SearchResultsView from "@/components/omniSearch/searchViews/SearchResultsView";
import Loading from "@/layouts/Loading";

import { Separator } from "@kino/ui";
import FilterPanel from "@/components/filter/filterPanel/FilterPanel";
import { useLayoutStore } from "@/store/layoutStore";
import { FilterButton } from "@/components/filter/FilterButton";
import { useOmniSearch } from "@/hooks/useOmniSearch";
import { useSearchParams } from "react-router-dom";
import { DisplayPopover } from "@/components/display/DisplayPopover";

// Memoize the content section to prevent re-renders
const SearchContent = React.memo(
  ({
    hasSearchParams,
    isLoading,
    searchResponse,
  }: {
    hasSearchParams: boolean;
    isLoading: boolean;
    searchResponse: any;
  }) => {
    if (!hasSearchParams) {
      return <SearchDefaultView />;
    }

    if (isLoading) {
      return <SearchLoadingView />;
    }

    if (!searchResponse) {
      return <SearchNoResultsView />;
    }

    return (
      <>
        <OmniSearchResultTypeTabs />
        <SearchResultsView />
      </>
    );
  },
);

// Separate filter button to prevent re-renders
const FilterButtonContainer = React.memo(() => {
  const isFilterPanelOpen = useLayoutStore((state) => state.isFilterPanelOpen);
  const set = useLayoutStore((state) => state.set);

  const handleFilterClick = useCallback(() => {
    set("isFilterPanelOpen", true);
  }, [set]);

  if (isFilterPanelOpen) return null;

  return <FilterButton onClick={handleFilterClick} />;
});

// Separate search input to prevent re-renders
const SearchInputContainer = React.memo(() => {
  const [focused, setFocused] = useCommandMenuFocus(CommandMenuFocusType.Page);

  return (
    <SearchInput
      focused={focused}
      setFocused={setFocused}
      focusByDefault={false}
    />
  );
});

// Memoize the header section
const SearchHeader = React.memo(() => (
  <div className="relative flex h-11 max-h-11 min-h-11 w-full items-center justify-between gap-2 p-2">
    <FilterButtonContainer />
    <SearchInputContainer />
    <DisplayPopover variant="search" />
  </div>
));

// Separate the main content to prevent re-renders
const MainContent = React.memo(() => {
  const { searchResponse, isLoading } = useOmniSearch();
  const [searchParams] = useSearchParams();
  const hasSearchParams = useMemo(
    () => searchParams.toString().length > 0,
    [searchParams],
  );

  return (
    <div className="flex h-full w-full flex-col divide-y overflow-hidden">
      <SearchHeader />
      <SearchContent
        hasSearchParams={hasSearchParams}
        isLoading={isLoading}
        searchResponse={searchResponse}
      />
    </div>
  );
});

// Memoize the filter panel to prevent re-renders
const MemoizedFilterPanel = React.memo(() => <FilterPanel />);

const OmniSearch = React.memo(() => {
  const { isLoaded: isUserLoaded } = useUser();

  if (!isUserLoaded) {
    return <Loading />;
  }

  return (
    <div className="flex h-full w-full overflow-hidden">
      <MemoizedFilterPanel />
      <Separator orientation="vertical" />
      <MainContent />
    </div>
  );
});

export default OmniSearch;
