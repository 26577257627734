import { useClipMoment } from "../../context/ClipMomentsContext";
import { cn } from "../../utils/cn";
import { useMediaStore } from "@vidstack/react";
import { useState } from "react";
import { useFormattedTime } from "../../context/FormatTimeContext";

export const TimeWithClip = () => {
  const [showClipTime, setShowClipTime] = useState<boolean>(true);
  const { isClipMomentActive } = useClipMoment();
  const { realCurrentTime, clipEndTime, clipStartTime, intrinsicDuration } =
    useMediaStore();

  const useClipTime = showClipTime && isClipMomentActive;

  const currentTimeTimecode = useFormattedTime(
    useClipTime ? realCurrentTime - clipStartTime : realCurrentTime,
  );
  const durationTimecode = useFormattedTime(
    useClipTime ? clipEndTime - clipStartTime : intrinsicDuration,
  );

  return (
    <span
      onClick={
        isClipMomentActive ? () => setShowClipTime((prev) => !prev) : undefined
      }
      className={cn(
        "inline-flex h-6 items-center gap-1 px-2 text-xs text-neutral-500 antialiased transition-colors",
        isClipMomentActive && "cursor-pointer hover:bg-neutral-500/20",
        useClipTime && "text-blue-400/70",
      )}
    >
      <span className={cn("text-grey-300", useClipTime && "text-blue-300/70")}>
        {currentTimeTimecode}
      </span>
      <span>{"/"}</span>
      <span className="font-medium ">{durationTimecode}</span>
    </span>
  );
};

export const Times = () => {
  const { realCurrentTime, intrinsicDuration } = useMediaStore();

  const currentTimeTimecode = useFormattedTime(realCurrentTime);
  const durationTimecode = useFormattedTime(intrinsicDuration);

  return (
    <span className="inline-flex gap-1 text-xs tabular-nums text-neutral-500 antialiased">
      <span className="text-neutral-400">{currentTimeTimecode}</span>
      <span>{"/"}</span>
      <span>{durationTimecode}</span>
    </span>
  );
};
