import React, { useRef, useState } from "react";
import { AvatarFallback, AvatarImage, Avatar, ScrollArea } from "@kino/ui";
import { Badge } from "@kino/ui";
import { TrashIcon } from "@radix-ui/react-icons";
import { Plus } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipProvider,
} from "@kino/ui";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@kino/ui";
import ContextMenu, { MenuItem } from "../ContextMenu";
import { toast } from "sonner";

import { AddPersonDialog } from "./add-person-dialog";
import { useActiveServerStore } from "@/store/activeServerStore";
import { components } from "@/openapi-bindings/v2";
import { useGetPeopleQuery } from "@/hooks/useGetPeopleQuery";
import { useClient, queryClient } from "@/hooks/useClient";
import { useSyncOrganization } from "@/store/organizationStore";

interface PersonListProps {
  selectedPersonId?: string;
  onSelectPerson: (person: components["schemas"]["Person"]) => void;
  onAddPerson?: (name: string, nickname: string) => void;
}

export const PersonList: React.FC<PersonListProps> = ({
  selectedPersonId,
  onSelectPerson,
  onAddPerson,
}) => {
  const dialogRef = useRef<{ setOpen: (open: boolean) => void }>(null);
  const { buildServerUrl } = useActiveServerStore();
  const { data: persons = [] } = useGetPeopleQuery();
  const { apiClient } = useClient();
  const orgStore = useSyncOrganization();
  const [personToDelete, setPersonToDelete] = useState<
    components["schemas"]["Person"] | null
  >(null);

  const deletePeople = apiClient.useMutation(
    "delete",
    "/people",
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["get", "/people"] });
        toast.success("Person deleted successfully");
      },
      onError: () => {
        toast.error("Failed to delete person");
      },
    },
    queryClient,
  );

  const handleDeletePerson = async () => {
    if (!personToDelete) return;

    try {
      await deletePeople.mutateAsync({
        body: {
          organization_id: orgStore.getEffectiveOrganizationId(),
          person_ids: [personToDelete.id],
        },
      });
      setPersonToDelete(null);
    } catch (error) {
      console.error("Failed to delete person:", error);
    }
  };

  // Sort persons alphabetically by name
  const sortedPersons = [...persons].sort((a, b) =>
    a.name.localeCompare(b.name, undefined, { sensitivity: "base" }),
  );

  return (
    <div className="border-border flex min-h-0 w-64 flex-col border-r">
      <div className="flex-none p-4">
        <div className="flex items-center justify-between">
          <h2 className="text-lg font-semibold">People</h2>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <button
                  onClick={() => dialogRef.current?.setOpen(true)}
                  className="text-muted-foreground hover:text-foreground"
                >
                  <Plus className="h-5 w-5" />
                </button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Add person (press 'c')</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        {onAddPerson && (
          <AddPersonDialog ref={dialogRef} onAddPerson={onAddPerson} />
        )}
      </div>

      <ScrollArea className="min-h-0 flex-1">
        <div className="space-y-2 p-4 pt-0">
          {sortedPersons.map((person) => {
            const menuItems: MenuItem[] = [
              {
                label: "Delete Person",
                icon: <TrashIcon />,
                onClick: () => setPersonToDelete(person),
              },
            ];

            return (
              <ContextMenu key={person.id} customMenuItems={menuItems}>
                <div
                  className={`hover:bg-accent/50 group flex cursor-pointer items-center justify-between rounded-lg p-2 ${
                    selectedPersonId === person.id
                      ? "bg-accent/90 ring-primary ring-2"
                      : ""
                  }`}
                  onClick={() =>
                    onSelectPerson(person as components["schemas"]["Person"])
                  }
                >
                  <div className="flex items-center space-x-3">
                    <Avatar>
                      <AvatarImage
                        src={buildServerUrl(person?.thumbnail_path ?? "")}
                        alt={person.name}
                      />
                      <AvatarFallback>{person.name[0]}</AvatarFallback>
                    </Avatar>
                    <div>
                      <p className="font-medium">{person.name}</p>
                      <p className="text-muted-foreground text-sm">
                        {person.nickname}
                      </p>
                    </div>
                  </div>
                  {person.reference_face_paths.length > 0 && (
                    <Badge
                      variant="outline"
                      className="bg-background/10 hover:bg-accent border-border/40 ml-2 min-w-[28px] justify-center border font-mono text-xs tracking-wider transition-colors"
                    >
                      {person.reference_face_paths.length}
                    </Badge>
                  )}
                </div>
              </ContextMenu>
            );
          })}
        </div>
      </ScrollArea>

      <AlertDialog
        open={!!personToDelete}
        onOpenChange={() => setPersonToDelete(null)}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This will permanently delete {personToDelete?.name} and all their
              reference faces. This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              variant="destructive"
              onClick={handleDeletePerson}
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default PersonList;
