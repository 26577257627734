import { forwardRef } from "react";

export const WindowHandleRightIcon = forwardRef<
  SVGSVGElement,
  React.SVGAttributes<SVGElement>
>((props, forwardedRef) => (
  <svg viewBox="0 0 4 10" fill="none" {...props} ref={forwardedRef}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.04 0H1.04V3.7L2.5 5L1.04 6.3V10H0.04V6.1L1.2 5L0.04 3.9V0Z"
      fill="currentColor"
    />
    <path d="M1.2 5L0.04 3.9V6.1L1.2 5Z" />
  </svg>
));

export default WindowHandleRightIcon;
