import * as React from "react";
import {
  ClickableIcon,
  cn,
  Gauge,
  Spinner,
  Popover,
  PopoverContent,
  PopoverTrigger,
  TooltipProvider,
} from "@kino/ui";
import { SeasonStats } from "../analytics/SeasonStats";
import { useState, useMemo, useCallback } from "react";
import { BarChartHorizontalIcon } from "lucide-react";
import { getGaugeColorClass } from "@/utils/tailwind";
import { useSeasonStats } from "@/hooks/useSeasonStats";

// Memoize the SeasonStats component
const MemoizedSeasonStats = React.memo(SeasonStats);
MemoizedSeasonStats.displayName = "MemoizedSeasonStats";

// Memoize the loading spinner
const LoadingSpinner = React.memo(() => <Spinner className="h-4 w-4" />);
LoadingSpinner.displayName = "LoadingSpinner";

// Memoize the chart icon
const ChartIcon = React.memo(() => <BarChartHorizontalIcon size={16} />);
ChartIcon.displayName = "ChartIcon";

// Memoize the stats gauge
const StatsGauge = React.memo(
  ({
    value,
    max,
    colorClass,
  }: {
    value: number;
    max: number;
    colorClass: string;
  }) => (
    <div className="flex items-center rounded-sm border-[1.2px] border-neutral-500/50 p-1">
      <Gauge
        value={value}
        max={max}
        size="xxsmall"
        showValue={false}
        classNameProgress={colorClass}
      />
      <span className="text-xsm ml-1 text-neutral-500">
        {Math.round((value / max) * 100)}%
      </span>
    </div>
  ),
);

StatsGauge.displayName = "StatsGauge";

// Separate component for store subscription
const StatsContent = React.memo(() => {
  const { seasonStats, isLoading } = useSeasonStats();
  const { totalProcessedVideos, totalNumVideos, isProcessing, colorClass } =
    useMemo(() => {
      const totalProcessedVideos = Object.values(seasonStats || {}).reduce(
        (total, stats) => total + stats.processed_videos,
        0,
      );
      const totalNumVideos = Object.values(seasonStats || {}).reduce(
        (total, stats) => total + stats.num_videos,
        0,
      );
      const isProcessing =
        totalProcessedVideos < totalNumVideos && totalNumVideos > 0;
      const colorClass = getGaugeColorClass(
        totalNumVideos > 0
          ? Math.round((totalProcessedVideos / totalNumVideos) * 100)
          : 0,
      );

      return {
        totalProcessedVideos,
        totalNumVideos,
        isProcessing,
        colorClass,
      };
    }, [seasonStats]);

  if (isLoading) return <LoadingSpinner />;
  if (isProcessing) {
    return (
      <StatsGauge
        value={totalProcessedVideos}
        max={totalNumVideos}
        colorClass={colorClass}
      />
    );
  }
  return <ChartIcon />;
});

StatsContent.displayName = "StatsContent";

const SeasonStatsPopover = React.memo(() => {
  const [isOpen, setIsOpen] = useState(false);
  const { seasonStats, isLoading } = useSeasonStats();

  const handleOpenChange = useCallback((open: boolean) => {
    setIsOpen(open);
  }, []);

  return (
    <TooltipProvider>
      <Popover modal={true} open={isOpen} onOpenChange={handleOpenChange}>
        <PopoverTrigger>
          <ClickableIcon
            Icon={StatsContent}
            name="season-stats"
            tooltip="Season Stats"
            className={cn(
              "rounded-md p-1 text-neutral-500 hover:bg-neutral-800",
              isOpen ? "bg-neutral-800" : "",
            )}
          />
        </PopoverTrigger>
        <PopoverContent
          className="h-96 w-96 overflow-hidden p-0"
          onOpenAutoFocus={(event) => {
            event.preventDefault();
            if (event.target) {
              (event.target as HTMLElement).focus();
            }
          }}
        >
          {/* TODO: Handling season stats being empty */}
          {seasonStats && (
            <MemoizedSeasonStats
              seasonStats={seasonStats}
              isLoading={isLoading}
            />
          )}
        </PopoverContent>
      </Popover>
    </TooltipProvider>
  );
});

SeasonStatsPopover.displayName = "SeasonStatsPopover";

export default SeasonStatsPopover;
